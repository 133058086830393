import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Container } from '@mui/material';
import background from '../asset/image/bg.jpg';
import logo from '../asset/image/logo-header.png'

class LoginLayout extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    document.body.style.backgroundSize = 'cover';
    document.body.style.background = `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${background}) no-repeat center center fixed`;
  }

  renderHeader = () => {

    if (this.props.hideHeader)
      return null;

    return (
      <AppBar position="fixed">
        <Toolbar style={{minHeight: 50, backgroundColor: '#fff'}}>
          <Container maxWidth="sm" disableGutters={true}> 
            <div style={{display:'flex', height:50, color:'#000', justifyContent:'center', alignItems:'center'}}>
              <img width={165} src={logo} />
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    )

  }

  render() {
		return (
      <Container component="main" maxWidth="xs" style={{paddingBottom:100}}>
        { this.renderHeader() }
        { this.props.body }
      </Container>
    );
	}
}

export default LoginLayout
