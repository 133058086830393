import { Component } from 'react'
import { SwipeableDrawer } from '@mui/material';

class LoginBody extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      showRules: false
    };
  }

  render = () => {

    const rulesStyle = {letterSpacing:-0.75, lineHeight:1, textAlign:'center', fontFamily:'Poppins-Bold', fontSize:18, marginTop:15}

    const GameRules = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.state.showRules }
        onClose={ () => { this.setState({showRules: false}) } }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ () => { this.setState({showRules: false}) } } style={{padding:90}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:18, letterSpacing:-0.75, textAlign:'center', lineHeight:1, marginTop:15}}>plusieurs jeux sont proposés et permettent de gagnez des points ($) pour acheter les cadeaux de la boutique Tennis Empire</div>
          <div style={{textTransform:'uppercase', letterSpacing:-1.5, textAlign:'center', fontFamily:'Poppins-Bold', fontSize:28, marginTop:20}}>jeu de trading</div>
          <div style={rulesStyle}>chaque participant commence avec $100</div>
          <div style={rulesStyle}>achetez et vendez les joueurs aux meilleurs moments (1 point ATP = $0.01)</div>
          <div style={rulesStyle}>les achats et ventes sont autorisés pendant 36h après la mise à jour du classement ATP</div>
          <div style={rulesStyle}>vous pouvez piocher chaque jour un joueur qui sera ajouté à votre collection</div>
          <div style={rulesStyle}>les comptes sont remis à zéro à chaque nouvelle saison</div>
          <div style={{textTransform:'uppercase', letterSpacing:-1.5, textAlign:'center', fontFamily:'Poppins-Bold', lineHeight:1, fontSize:28, marginTop:20}}>concours de pronostics</div>
          <div style={rulesStyle}>Pendant les GC et Masters 1000, des matchs sont proposés chaque jour sur la compétition concernée</div>
          <div style={rulesStyle}>Vous devez pronostiquer le vainqueur du match en sélectionnant un des deux joueurs</div>
          <div style={rulesStyle}>Si votre choix est juste vous gagnez les points associés</div>
          <div style={rulesStyle}>Si votre choix est incorrect vous perdez 50 points</div>
          <div style={rulesStyle}>Le pronostic est annulé si un des deux joueurs abandonne</div>
          <div style={rulesStyle}>Vos points déterminent votre position au classement du concours</div>
          <div style={rulesStyle}>A la fin de la compétition, vous remportez la récompense associée à votre classement</div>
          <div style={{textTransform:'uppercase', letterSpacing:-1.5, textAlign:'center', fontFamily:'Poppins-Bold', lineHeight:1, fontSize:28, marginTop:20}}>cadeaux</div>
          <div style={rulesStyle}>Le cumul de vos points ($) débloque les articles de la boutique Tennis Empire</div>
          <div style={rulesStyle}>Dépensez vos points ($) pour acheter les récompenses débloquées</div>
        </div>
      </SwipeableDrawer>
    )

    return (
      <>
        <div style={{ display:'flex', flexDirection:'column' }}>
          <div>
            <div style={{ fontFamily:"Poppins-Bold", lineHeight:1, padding:10, marginTop:70, color:'#FFF', fontSize:26, textTransform:'uppercase', textAlign:'center'}}>le daily game des fans de tennis façon jeu de société</div>
            
            <div style={{ fontFamily:'Poppins-Bold', color:'#fff', fontSize:16, textTransform:'uppercase', textAlign:'center', lineHeight:1, marginBottom:25 }}>
              <span onClick={() => { this.setState({showRules: true}) }} style={{ borderBottom:'dotted 2px #999'}}>voir les règles du jeu</span>
            </div>
            
            <div onClick={ () => window.location.replace(`https://discord.com/api/oauth2/authorize?client_id=1092734202291683329&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_CALLBACK_URL)}&response_type=token&scope=identify%20email`) } style={{ fontFamily:"Poppins-Bold", textAlign:'center', fontSize:17, backgroundColor:'#7289da', margin:20, padding:10, borderRadius:15, color:'#fff' }}>continuer avec discord</div>
            <div onClick={ () => window.location.replace(`https://www.facebook.com/v16.0/dialog/oauth?client_id=204388249052471&redirect_uri=${encodeURIComponent(process.env.REACT_APP_FACEBOOK_CALLBACK_URL)}&scope=email%2Cpublic_profile`) } style={{ fontFamily:"Poppins-Bold", textAlign:'center', fontSize:17, backgroundColor:'#3b5998', margin:20, padding:10, borderRadius:15, color:'#fff' }}>continuer avec facebook</div>
            
            <div style={{ fontFamily:'Poppins-Bold', color:'#fff', fontSize:14, textTransform:'uppercase', textAlign:'center', lineHeight:1.3, marginBottom:25 }}>
              en continuant j'accepte les <span onClick={() => { window.location.replace('/privacy-policy') }} style={{ borderBottom:'dotted 2px #999'}}>conditions d'utilisation</span>
            </div>

          </div>
          <div style={{ fontFamily:"Poppins-Bold", lineHeight:1.2, paddingTop:20, color:'#BBB'}}>
            <div style={{ textAlign:'center' }}>cette application est une webapp est peut être installée sur votre téléphone ou ordinateur</div>
          </div>
        </div>

        { GameRules }
        
      </>
    )

  }

}

export default LoginBody
