import React, { Component } from 'react'
import { fetchGetURLRaw } from '../../utils/fetch'
import Login from '../../layout/login'
import Loading from '../../screen/loading'

class LoginWithDiscord extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    document.title = "Tennis Empire";
  }

  loadUserData = async () => {

    const queryParameters = new URLSearchParams(window.location.hash)
    const accessToken = queryParameters.get("access_token")

    const userData = await fetchGetURLRaw(`${process.env.REACT_APP_API_BASE_URL}/auth`, { provider: 'discord', token: accessToken })

    if (userData.data.email) {

      localStorage.setItem('token', `${userData.data.id}.${userData.data.token}`)
      document.location.href = '/'

    }

  }

  render = () => {

    this.loadUserData()

    return (
      <Login hideHeader={true} body={<Loading />} />
    )

  }

}

export default LoginWithDiscord;
