import { Component } from 'react'
import AppContext from '../context/app'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { Container } from '@mui/material'
import defaultAvatar from '../asset/image/default.jpeg'
import { fullTextDate } from '../utils/common'
import CircularProgress from '@mui/material/CircularProgress'

class Player extends Component {

  constructor (props) {
    super(props);
    this.state = {
      buyMode: false,
      buyQuantity: 0,
      sellMode: false,
      sellQuantity: 0
    };
  }

  toggleBuyMode = () => {

    this.setState({ buyQuantity: 0 })

    if (this.props.context.player.buy_mode)
      this.props.context.player.switch_mode(null)
    else
      this.props.context.player.switch_mode('buy')

  }

  toggleSellMode = () => {

    this.setState({ sellQuantity: 0 })

    if (this.props.context.player.sell_mode)
      this.props.context.player.switch_mode(null)
    else
      this.props.context.player.switch_mode('sell')

  }

  updateBuyQuantity = (action, player) => {

    if (action == 'add' && ( this.props.context.user.cash - ( player.value * (this.state.buyQuantity + 1) ) ) >= 0 )
      this.setState({ buyQuantity: this.state.buyQuantity + 1 })
    else if (action == 'remove' && this.state.buyQuantity > 0)
      this.setState({ buyQuantity: this.state.buyQuantity - 1 })

  }

  updateSellQuantity = (action, player) => {

    if (action == 'add' && this.state.sellQuantity < player.quantity)
      this.setState({ sellQuantity: this.state.sellQuantity + 1 })
    else if (action == 'remove' && this.state.sellQuantity > 0)
      this.setState({ sellQuantity: this.state.sellQuantity - 1 })

  }

  render = () => {

    if (this.props.context.player.loading)
      return <Container component="main" maxWidth="xs" style={{padding:0}}>
        <div style={{textAlign:'center', marginTop:100, marginBottom:100}}><CircularProgress style={{color:'#666'}} size={64} /></div>
      </Container>

    const ColorButton = styled(Button)(({ theme, backgroundColor }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor: `${backgroundColor}!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      width:150
    }))

    const player = this.props.context.players.list.find(player => player.id === this.props.context.player.id)

    if (!player)
      return null

    // TODO: show error message if player not found

    const usd = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(player.value)

    let buyForm
    let sellForm

    const marketIsClosed = (
      <div style={{backgroundColor:'#fff', textAlign:'center', fontFamily:'Poppins-Bold', border:'solid 1px #CCC', padding:15, marginTop:20, borderRadius:10, lineHeight:1.2}}>
        le marché est actuellement fermé, rendez-vous lundi prochain pour la prochaine mise à jour du classement
      </div>
    )

    if (this.props.context.players.market_is_open) {

      buyForm = (
        <div style={{backgroundColor:'#fff', border:'solid 2px #CCC', padding:15, marginTop:20, borderRadius:10}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly'}}>
            <div><RemoveCircle onClick={() => this.updateBuyQuantity('remove', player)} style={{fontSize:34}} /></div>
            <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, textAlign:'center', fontSize:32 }}>{ this.state.buyQuantity }</div>
            <div><AddCircle onClick={() => this.updateBuyQuantity('add', player)} style={{fontSize:34}} /></div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, letterSpacing:-0.4, textTransform:'uppercase', textAlign:'center', fontSize:14 }}>
            total: { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( player.value * this.state.buyQuantity ) }
          </div>
          <div style={{ fontFamily:'Poppins-Bold', color:'#666', marginTop:4, lineHeight:1, letterSpacing:-0.4, textTransform:'uppercase', textAlign:'center', fontSize:12 }}>
            cash disponible: { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( this.props.context.user.cash - ( player.value * this.state.buyQuantity ) ) }
          </div>
          <div style={{display:'flex', justifyContent:'center', marginTop:10}}>
            { this.props.context.player.orderInProgress
              ? <CircularProgress style={{color:'#666'}} size={22} />
              : <ColorButton disabled={ this.state.buyQuantity === 0 } backgroundColor={'#f0bc3d'} onClick={() => { this.props.context.player.buy(player.id, this.state.buyQuantity) }} variant="contained">valider l'achat</ColorButton>
            }
          </div>
        </div>
      )

      sellForm = (
        <div style={{backgroundColor:'#fff', border:'solid 2px #CCC', padding:15, marginTop:20, borderRadius:10}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly'}}>
            <div><RemoveCircle onClick={() => this.updateSellQuantity('remove', player)} style={{fontSize:34}} /></div>
            <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, textAlign:'center', fontSize:32 }}>{ this.state.sellQuantity }</div>
            <div><AddCircle onClick={() => this.updateSellQuantity('add', player)} style={{fontSize:34}} /></div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, letterSpacing:-0.4, textTransform:'uppercase', textAlign:'center', fontSize:14 }}>
            total: { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( player.value * this.state.sellQuantity ) }
          </div>
          <div style={{ fontFamily:'Poppins-Bold', color:'#666', marginTop:4, lineHeight:1, letterSpacing:-0.4, textTransform:'uppercase', textAlign:'center', fontSize:12 }}>
            cash disponible: { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( this.props.context.user.cash + ( player.value * this.state.sellQuantity ) ) }
          </div>
          <div style={{display:'flex', justifyContent:'center', marginTop:10}}>
            { this.props.context.player.orderInProgress
              ? <CircularProgress style={{color:'#666'}} size={22} />
              : <ColorButton disabled={ this.props.context.player.orderInProgress || this.state.sellQuantity === 0 } backgroundColor={'#f0bc3d'} onClick={() => { this.props.context.player.sell(player.id, this.state.sellQuantity) }} variant="contained">valider la vente</ColorButton>
            }
          </div>
        </div>
      )

    } else {

      buyForm = marketIsClosed
      sellForm = marketIsClosed

    }

    const history = []

    this.props.context.player.history.map((item) => {

      let order

      if (item.type == 'buy')
        order = 'achat'
      else if (item.type == 'spin')
        order = 'pioche'
      else
        order = 'vente'

      history.push(
        <div key={item.id} style={{ borderRadius:10, backgroundColor:'#FFF', padding:10, marginTop:10, paddingBottom:0, display:'flex', border:'solid 1px #CCC', flexDirection:'column' }}>
          <div style={{ display:'flex', color:'#666', justifyContent:'space-between', borderBottom:'solid 2px #DDD', paddingBottom:3}}>
            <div style={{ fontFamily:'Poppins-Bold', fontSize:14, lineHeight:1 }}>{ order }</div>
            <div style={{ fontFamily:'Poppins-Bold', fontSize:14, lineHeight:1 }}>{ fullTextDate(item.ts.split('T')[0]) }</div>
          </div>
          <div style={{ display:'flex', justifyContent:'center', padding:5, backgroundColor:'#fff', textAlign:'center'}}>
            <div style={{ lineHeight:1, flexBasis:1, flexGrow:1, padding:5}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18 }}>{ item.metadata.quantity }</div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:12, textTransform:'uppercase', color:'#888', paddingTop:3 }}>quantité</div>
            </div>
            <div style={{ lineHeight:1, flexBasis:1, flexGrow:1, padding:5, borderLeft:'solid 2px #DDD', borderRight:'solid 2px #DDD'}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18 }}>{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.metadata.value) }</div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:12, textTransform:'uppercase', color:'#888', paddingTop:3 }}>valeur</div>
            </div>
            <div style={{ lineHeight:1, flexBasis:1, flexGrow:1, padding:5}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18 }}>{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((item.metadata.quantity * item.metadata.value)) }</div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:12, textTransform:'uppercase', color:'#888', paddingTop:3 }}>total</div>
            </div>
          </div>
        </div>
      )
    })

    const body = (
      <Container id="player" component="main" maxWidth="xs" style={{padding:0}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly'}}>
          <div style={{border:'solid 3px #CCC', backgroundColor:'#fff', borderRadius:10, width:250, paddingTop:25, paddingBottom:15, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
            <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, textTransform:'uppercase', textAlign:'center', fontSize:30, letterSpacing:-1.8 }}>{ player.name }</div>
            <div style={{ textAlign:'center', marginTop:15 }}>
              <img style={{width:225}} src={ player.avatar ? player.avatar : defaultAvatar } />
            </div>
            <div style={{ display:'flex', marginTop:3, justifyContent: 'space-evenly' }}>
              <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, marginTop:5, textTransform:'uppercase', textAlign:'center', fontSize:22 }}>{ usd }</div>
              
              { player.evolution > 0 && <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, marginTop:5, textTransform:'uppercase', textAlign:'center', fontSize:22, color:'#00df00' }}>+{ player.evolution }%</div> }
              { player.evolution < 0 && <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, marginTop:5, textTransform:'uppercase', textAlign:'center', fontSize:22, color:'#f03557' }}>{ player.evolution }%</div> }

            </div>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-evenly', marginTop:25}}>
          <div>
            <ColorButton backgroundColor={ this.props.context.player.buy_mode ? '#f0bc3d' : '#fff' } onClick={this.toggleBuyMode} variant="contained">acheter</ColorButton>
          </div>
          { player.quantity > 0 && <div>
            <ColorButton backgroundColor={ this.props.context.player.sell_mode ? '#f0bc3d' : '#fff' } onClick={this.toggleSellMode} variant="contained">vendre</ColorButton>
          </div>}
        </div>
        
        { this.props.context.player.buy_mode && buyForm }
        { this.props.context.player.sell_mode && sellForm }

        { player.quantity > 0 && 

          <div style={{ borderRadius:10, marginTop:30, border:'solid 1px #CCC', display:'flex', justifyContent:'center', padding:5, backgroundColor:'#fff', textAlign:'center'}}>
            <div style={{ lineHeight:1, flexBasis:1, flexGrow:1, padding:5, borderRight:'solid 1px #DDD'}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18 }}>{ player.quantity }</div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:12, textTransform:'uppercase', color:'#888', paddingTop:3 }}>acquis</div>
            </div>
            <div style={{ lineHeight:1, flexBasis:1, flexGrow:1, padding:5, borderLeft:'solid 1px #DDD'}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18 }}>{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( ( player.quantity * player.value ) ) }</div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:12, textTransform:'uppercase', color:'#888', paddingTop:3 }}>valeur totale</div>
            </div>
          </div>

        }

        <div style={{marginTop:30}}>

          { history.length > 0 && <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', marginTop:50, paddingBottom:3, marginBottom:10, borderBottom:'solid 3px #BBB', textAlign:'center' }}>historique</div> }
          { history }

        </div>

      </Container>
    );

    return body

  }

}

const App = ( props ) => 
  <AppContext.Consumer>
    {(appContext) => (
      <Player {...props} context={appContext} />
    )}
  </AppContext.Consumer>

export default App
