import { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppContext from '../context/app';
import { Container } from '@mui/material'
import defaultAvatar from '../asset/image/default.jpeg'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
});

class Wallet extends Component {

  constructor (props) {

    super(props);

    this.state = {
      search: ''
    };

  }

  render = () => {

    const players = []
    
    let playerList = this.props.context.players.list

    if (this.state.search !== '')
      playerList = playerList.filter(player => player.name.toLowerCase().includes(this.state.search.toLowerCase()))

    playerList.map(async (player, index) => {

      const usd = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(player.value)

      const tops = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150]

      if (this.state.search == '' && this.props.context.wallet.selectedTab === 0 && tops.includes(index))
        players.push(
          <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', lineHeight:1, fontSize:'32px', color:'#999', marginTop:25, marginBottom:25, paddingTop:3, borderTop:'solid 3px #BBB' }}>{ index }</div>
        )

      if (!player.evolution)
        player.evolution = 0

      if ( this.props.context.wallet.selectedTab === 0 || ( this.props.context.wallet.selectedTab === 1 && player.quantity > 0 ) ) {

        players.push(
          <div ref={player.id} onClick={() => { this.props.context.player.select(player.id) }} style={{ position:'relative', borderRadius:10, backgroundColor:'#FFF', padding:10, marginTop:10, display:'flex', justifyContent:'space-between', border: 'solid 2px #CCC' }}>
            { player.quantity > 0 && <div style={{ position:'absolute', fontFamily:'Poppins-Bold', top:-10, right:-10, backgroundColor:'#CCC', width:'25px', lineHeight:'25px', textAlign:'center', borderRadius:'50%' }}>{ player.quantity }</div> }
            <div style={{display:'flex', alignItems:'left', flexDirection:'row'}}>
              <img style={{ width:36, height:36, borderRadius:'50%', objectFit:'cover', marginRight: 10 }} src={ player.avatar ? player.avatar : defaultAvatar } />
              <div style={{display:'flex', alignItems:'left', flexDirection:'column'}}>
                <div style={{ fontFamily:'Poppins-Bold', fontSize:20, lineHeight:1 }}>{ player.name }</div>
                <div style={{ fontFamily:'Poppins-Bold', fontSize:13, lineHeight:1, color:'#888', marginTop:3 }}>{ player.country }</div>
              </div>
            </div>
            <div>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:20, lineHeight:1 }}>{usd}</div>
              { ( player.evolution > 0 ) && <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:13, color:'#00df00', lineHeight:1, marginTop:3, textAlign:'right' }}>+{ player.evolution }%</div> }
              { ( player.evolution < 0 ) && <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:13, color:'#f03557', lineHeight:1, marginTop:3, textAlign:'right' }}>{ player.evolution }%</div> }
            </div>
          </div>
        )

      }

    })

    const body = (
      <div>

        <Tabs value={this.props.context.wallet.selectedTab} onChange={this.props.context.wallet.switchTab} centered>
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:18 }} label="top 200" />
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:18 }} label="mes joueurs" />
        </Tabs>

        <Container component="main" maxWidth="xs" style={{marginTop:25, padding:0}}>

          { this.props.context.players.market_is_open
            ? <div style={{fontFamily:'Poppins-Bold', textAlign:'center', backgroundColor:"#40ab40", color:'#fff', fontSize:'min(4.800vw, 20.64px)', marginBottom:20, padding:3, borderRadius:10}}>le marché est ouvert</div>
            : <div>
                <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', backgroundColor:'#f03557', color:'#fff', fontSize:'min(4.800vw, 20.64px)', padding:3, borderRadius:10}}>le marché est fermé</div>
                <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, marginTop:10, color:'#888', marginBottom:20, textAlign:'center' }}>l'achat/vente de joueurs est disponible pendant 36h après la mise à jour du classement ATP</div>
              </div>
          }

          <TextField onChange={(event) => {this.setState({search: event.target.value})}} id="outlined-basic" label="rechercher un joueur" variant="outlined" fullWidth />
        
          <div style={{marginTop:25}}>
            { players }
          </div>
        
        </Container>

      </div>
    );

    return body

  }

}

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <AppContext.Consumer>
      {(appContext) => (
        <Wallet {...props} context={appContext} />
      )}
    </AppContext.Consumer>
  </ThemeProvider>;

export default App;
