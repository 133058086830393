import { Component } from 'react'

class Ranks extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => {

    const ranks = []

    this.props.ranks.map((rank, index) => {

      ranks.push(
        <div style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, color:'#666', fontSize:14, textTransform:'uppercase', textAlign:'center', lineHeight:1.2, marginBottom:20, marginTop:25 }}>{ rank.name } - { rank.remaining } jours restants</div>
      )

    })

    return (
      <>
        { ranks }
      </>
    )

  }

}

export default Ranks
