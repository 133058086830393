import { Component } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Player from '../component/player.js'
import ThemedButton from '../component/button'

class SpinnerModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      styleName: 'normal',
      showWinner: false,
      token: null,
    };
  }

  onClose = () => {
    this.setState( { styleName:'normal' } )
    this.props.onClose()
  }

  runSpin = async () => {

    this.setState( { loading: true } )

    const spinIsReady = await this.props.user.spin()

    console.log(spinIsReady)

    if (spinIsReady) {

      this.setState( { styleName: 'transition', token: spinIsReady.token || null } )

      setTimeout(() => {
        this.setState( { showWinner:true, loading: false } )
        this.props.user.refresh()
        this.props.players.refresh(true)
      }, 5500)

    }

  }

  sellSpin = async (winner) => {

    this.setState( { loading: true } )

    const sold = await this.props.user.sellSpin(winner.id, 1, this.state.token)

    if (sold)
      this.props.onClose()

    this.setState( { loading: false } )

  }

  render = () => {

    const style = {
      normal: {
        width:'150px',
        borderRadius:10,
        backgroundColor:'#FFF',
        padding:5,
        margin:5,
        paddingBottom:20,
        marginLeft: this.state.offset*0.8,
        boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)',
        paddingTop:20
      },
      transition: {
        width:'150px',
        borderRadius:10,
        backgroundColor:'#FFF',
        padding:5,
        margin:5,
        paddingBottom:20,
        boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)',
        marginLeft: -5220-15+this.state.offset*0.5,
        transition: 'margin-left 5s ease 0s',
        paddingTop:20
      }
    }

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      paddingRight:15,
      paddingLeft:15
    }));

    const randomPlayers = this.props.players.list.slice(0,99)

    let winner

    if (this.props.user && this.props.user.spinnedPlayer)
      winner = this.props.players.list.find(player => player.id === this.props.user.spinnedPlayer)

    let dialogContent

    if (this.state.showWinner) {

      dialogContent = (
        <DialogContent style={{padding:0, margin:0, paddingTop:50, paddingBottom:50}}>
          { winner &&
            <>
              <div style={{ display:'flex', justifyContent: 'center' }}>
                <Player player={ winner } /> 
              </div>
              <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', marginTop:25, lineHeight:1, fontSize:18 }}>{winner.name} a été ajouté à<br/>votre collection</div>
              <div style={{display:'flex', paddingLeft:20, paddingRight:20, flexDirection:'column', justifyContent:'center', marginTop:25}}>
                <ThemedButton disabled={this.state.loading} style={{margin:5}} onClick={ this.onClose } variant="contained">Garder</ThemedButton>
                <ThemedButton disabled={this.state.loading} style={{margin:5}} onClick={ () => this.sellSpin(winner) } variant="contained">Revendre immédiatement</ThemedButton>
              </div>
            </>
          }
        </DialogContent>
      )

    } else {

      dialogContent = (
        <DialogContent style={{padding:0, margin:0, paddingTop:50, paddingBottom:50}}>
          <div ref={el => { if (el && el.offsetWidth && this.state.offset !== el.offsetWidth) {this.setState({offset: el.offsetWidth})} }} style={{width:'100%', overflow:'hidden', position:'relative'}}>

            <div style={{width:'6500px', display:'flex'}}>

              <Player
                style={style[this.state.styleName]}
                player={ randomPlayers[ 8 ] }
              />

              {[...Array(29)].map(() => {
                const randNum = Math.floor(Math.random() * 99)
                return (<Player player={ randomPlayers[randNum] } />)
              })}

              { winner && <Player player={ winner } /> }
              
              {[...Array(3)].map(() => {
                const randNum =  Math.floor(Math.random() * 99)
                return (<Player player={ randomPlayers[randNum] } />)
              })}

            </div>

            <div style={{position:'absolute', left:'50%', top:-15, height:260, width:5, backgroundColor:'#EDBE41'}}></div>

          </div>
          <div style={{display:'flex', justifyContent:'center', marginTop:25}}>
            <ColorButton disabled={this.state.loading} onClick={this.runSpin} variant="contained">SPIN</ColorButton>
          </div>
        </DialogContent>
      )

    }

    return (
      <Dialog
        open={this.props.show}
        onClose={this.onClose}
        fullWidth={true}
      >
        { dialogContent }
      </Dialog>
    )

  }

}

export default SpinnerModal;
