export const isIos = (userAgent) => {

  if (!userAgent)
    userAgent = navigator.userAgent

  if ( userAgent.includes(`iPhone`) || userAgent.includes(`iPad`) )
    return true;

  return false;

}

export const isAndroid = () => {

  const userAgent = navigator.userAgent

  if ( userAgent.includes(`Android`) )
    return true

  return false

}

export const appIsInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches

export const localDatetime = (datetime) => {

  // from 2021-10-10T10:10:10.000Z UTC to 2021-10-10 12:10:10 UTC+2

  const newDate = new Date(datetime)

  const localString = newDate.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })

  const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'novembre', 'décembre']

  const date = localString.split(' ')[0]
  const time = localString.split(' ')[1]

  const day = date.split('/')[0]
  const month = parseInt(date.split('/')[1], 10) -1
  const year = date.split('/')[2]

  const hour = time.split(':')[0]
  const minute = time.split(':')[1]

  return `${day} ${months[month]} à ${hour}:${minute}`

}

export const fullTextDate = (datetime) => {

  // from 2023-05-23 to thesday 23 may 2023

  const date = new Date(datetime)
  const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
  const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'novembre', 'décembre']

  return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`

}

export const awaitSetState = (state, context) => {

  return new Promise((resolve) => {
    context.setState(state, resolve)
  })

}