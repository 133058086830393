import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screen/home';
import LoginWithDiscord from './screen/loginWith/discord';
import LoginWithFacebook from './screen/loginWith/facebook';
import PrivacyPolicy from './screen/privacy-policy';
import { AppContextProvider } from './context/app';

function App() {
  return (
    <Router>
      <div>
        <AppContextProvider>
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/auth/discord" element={<LoginWithDiscord/>}/>
            <Route exact path="/auth/facebook" element={<LoginWithFacebook/>}/>
            <Route exact path="/home" element={<Home/>}/>
            <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
          </Routes>
        </AppContextProvider>
      </div>
    </Router>
  );
}

export default App;