import { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Tabs from '@mui/material/Tabs'
import { Container } from '@mui/material'
import Tab from '@mui/material/Tab'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import ShopCard from '../component/shopCard'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
})

class Catalog extends Component {

  constructor (props) {
    super(props);
    this.state = {
      tabs: [
        { label: "Balles", tags: ['ball', 'tennis'] },
        { label: "Cordages", tags: ['string'] },
        { label: "Grip", tags: ['grip,surgrip'] },
        { label: "Textile", tags: ['clothing', 'polo,short', 'men,women'] },
        { label: "Chaussures", tags: ['shoes', 'tennis', 'men'] },
        { label: "Raquettes", tags: ['racket', 'tennis', 'men'] },
        { label: "Sacs", tags: ['bag', 'tennis'] },
        { label: "Accessoires", tags: ['accessory'] },
        { label: "Padel", tags: ['padel', 'racket,ball'] },
        { label: "Nutrition", tags: ['nutrition'] },
      ]
    }
  }

  componentDidMount = async () => {
    this.props.catalog.refreshTag(this.state.tabs[ this.props.catalog.selectedTab ].tags.join('+'))
  }

  componentWillUnmount = async () => {
    this.props.catalog.switchTab(0)
  }

  switchTab = async (event, newValue) => {
    await this.props.catalog.switchTab(newValue)
    await this.props.catalog.refreshTag(this.state.tabs[newValue].tags.join('+'))
  }

  render = () => {

    const catalog_products = []

    for (const product of this.props.catalog.list)
      catalog_products.push( <ShopCard load={this.props.loadProduct} product={product} deals={this.props.deals} /> )

    const tabs = []

    for (const tab of this.state.tabs)
      tabs.push(
        <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={tab.label} />
      )

    return (
      <Container component="main" maxWidth="xs" style={{padding:0}}>
        
        <Tabs value={this.props.catalog.selectedTab} variant="scrollable" scrollButtons allowScrollButtonsMobile onChange={this.switchTab}>
          {tabs}
        </Tabs>

        { this.props.catalog.loading
        
          ? <div style={{textAlign:'center'}}><CircularProgress style={{marginTop:100, marginBottom:400, color:'#666'}} size={64} /></div>
          : <div style={{ marginTop:25 }}>

              <div style={{ display:'flex', flexWrap:'wrap', justifyContent:'flex-start', alignContent:'flex-start' }}>
                { catalog_products }
              </div>

            </div>

        }
        
      </Container>
    )

  }

}

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <Catalog {...props} />
  </ThemeProvider>

export default App
