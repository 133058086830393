import { Component } from 'react'
import { SwipeableDrawer } from '@mui/material'
import { localDatetime, fullTextDate } from '../utils/common'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Button from '../component/button'
import TextField from '@mui/material/TextField'
import { Favorite, ChatBubble } from '@mui/icons-material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
})

class NotificationScreen extends Component {

  constructor (props) {
    super(props);
    this.state = {
      selectedTab: props.notifications.tab,
      selectedFriendTab: 0,
      addNewFriend: false,
      editFriendCode: false,
      friendCode: props.user.friend_code,
      friendCodeAvailable: null,
      friendCodeToBeAdded: '',
      loading: false
    };
  }

  selectTab = (event, newValue) => {
    this.setState({ selectedTab: newValue })
  }

  showForumThread = async (threadId, messageId) => {

    await this.props.forum.toggle()
    await this.props.forum.selectMessage(threadId)

    if (messageId)
      await this.props.forum.selectMessageDiscussionMode(messageId)

  }

  renderForumNotificationsTab = () => {

    const notifications = this.props.user.forum_notifications
    const output = []

    const notificationStyleOrigin = {
      fontFamily:"Poppins-Bold",
      letterSpacing:-0.8,
      lineHeight:'20px',
      fontSize:18,
      backgroundColor:'#fff',
      padding:15,
      paddingTop: 20,
      paddingBottom:5,
      borderRadius:10,
      color:'#333',
      border:'solid 1px #ccc',
      marginTop:20,
      marginBottom:15,
      textAlign:'center',
      boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-between',
    }

    const datetimeStyle = {
      lineHeight:'22px',
      fontSize:14,
      color:'#999',
      textAlign:'left',
      letterSpacing:-1
    }

    let notificationTitleDate = ''

    for (const notification of notifications) {

      if (notification.date.split('T')[0] !== notificationTitleDate) {

        output.push(
          <div style={{fontFamily:"Poppins-Bold", textAlign:'center', marginTop:35, marginBottom:10, letterSpacing:-1, lineHeight:'20px', fontSize:20}}>{fullTextDate(notification.date.split('T')[0])}</div>
        )

        notificationTitleDate = notification.date.split('T')[0]

      }

      const topicTags = []

      for (const tag of notification.metadata.tags)
        topicTags.push(
          <div style={{ fontFamily:'Poppins-Bold', marginRight:5, fontSize:14, padding:2, paddingLeft:7, paddingRight:7, borderRadius:5, backgroundColor:'#CCC' }}>{ tag }</div>
        )

      if (notification.type === 'forum_response') {

        output.push(
          <div onClick={ () => this.showForumThread(notification.metadata.thread, notification.metadata.message) } style={notificationStyleOrigin}>
            <div style={{flex:1}}>
              <div style={{ paddingLeft:35, paddingRight:35 }}>{ notification.metadata.from } a répondu à un fil que vous avez lancé</div>
              <div style={{ display:'flex', marginTop:20, marginBottom:5, justifyContent:'center' }}>{ topicTags }</div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between', marginTop:10, marginTop:15, paddingTop:7, borderTop:'solid 2px #eee' }}>
              <div style={{ display:'flex' }}>
                <div style={{ color:'#1d9bf0', marginRight:7 }}> <ChatBubble /> </div>
                <div style={ datetimeStyle }>{ localDatetime(notification.date) }</div>
              </div>
              <div style={{ display:'flex', fontSize:14, textAlign:'right' }}>
                voir la réponse
              </div>
            </div>
          </div>
        )

      } else if (notification.type === 'forum_quoted_response') {

        output.push(
          <div onClick={ () => this.showForumThread(notification.metadata.thread, notification.metadata.message) } style={notificationStyleOrigin}>
            <div style={{flex:1}}>
              <div style={{ paddingLeft:35, paddingRight:35 }}>{ notification.metadata.from } a répondu un de vos messages dans un fil</div>
              <div style={{ display:'flex', marginTop:20, marginBottom:5, justifyContent:'center' }}>{ topicTags }</div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between', marginTop:10, marginTop:15, paddingTop:7, borderTop:'solid 2px #eee' }}>
              <div style={{ display:'flex' }}>
                <div style={{ color:'#1d9bf0', marginRight:7 }}> <ChatBubble /> </div>
                <div style={ datetimeStyle }>{ localDatetime(notification.date) }</div>
              </div>
              <div style={{ display:'flex', fontSize:14, textAlign:'right' }}>
                voir la discussion
              </div>
            </div>
          </div>
        )

      } else if (notification.type === 'forum_thread_new_like') {

        output.push(
          <div onClick={ () => this.showForumThread(notification.metadata.thread) } style={notificationStyleOrigin}>
            <div style={{flex:1}}>
              <div style={{ paddingLeft:35, paddingRight:35 }}>{ notification.metadata.from } a aimé un fil que vous avez lancé</div>
              <div style={{ display:'flex', marginTop:20, marginBottom:5, justifyContent:'center' }}>{ topicTags }</div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between', marginTop:10, marginTop:15, paddingTop:7, borderTop:'solid 2px #eee' }}>
              <div style={{ display:'flex' }}>
                <div style={{ color:'#f91880', marginRight:7 }}> <Favorite /> </div>
                <div style={ datetimeStyle }>{ localDatetime(notification.date) }</div>
              </div>
              <div style={{ display:'flex', fontSize:14, textAlign:'right' }}>
                voir le fil
              </div>
            </div>
          </div>
        )

      } else if (notification.type === 'forum_response_new_like') {

        output.push(
          <div onClick={ () => this.showForumThread(notification.metadata.thread, notification.metadata.message) } style={notificationStyleOrigin}>
            <div style={{flex:1}}>
              <div style={{ paddingLeft:35, paddingRight:35 }}>{ notification.metadata.from } a aimé un de vos messages dans un fil</div>
              <div style={{ display:'flex', marginTop:20, marginBottom:5, justifyContent:'center' }}>{ topicTags }</div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between', marginTop:10, marginTop:15, paddingTop:7, borderTop:'solid 2px #eee' }}>
              <div style={{ display:'flex' }}>
                <div style={{ color:'#f91880', marginRight:7 }}> <Favorite /> </div>
                <div style={ datetimeStyle }>{ localDatetime(notification.date) }</div>
              </div>
              <div style={{ display:'flex', fontSize:14, textAlign:'right' }}>
                voir le message
              </div>
            </div>
          </div>
        )

      }

    }

    if (output.length > 0)
      return output
    
    return (<div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:35, textTransform:'uppercase', marginBottom:10, letterSpacing:-1, lineHeight:'20px', fontSize:20}}>Aucune notification</div>)

  }

  renderNotificationsTab = () => {

    const notificationStyleOrigin = {
      fontFamily:"Poppins-Bold",
      letterSpacing:-0.8,
      lineHeight:'20px',
      fontSize:17,
      backgroundColor:'#fff',
      padding:15,
      borderRadius:10,
      color:'#555',
      border:'solid 1px #ccc',
      marginTop:10,
      marginBottom:10,
      textAlign:'center',
      boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'
    }

    const titleStyle = {
      color:'#000',
      fontSize:20,
      marginBottom:7,
      letterSpacing:-1.5
    }

    const datetimeStyle = {
      lineHeight:'16px',
      fontSize:14,
      color:'#999',
      marginTop:15,
      paddingTop:7,
      borderTop:'solid 2px #eee',
      textAlign:'left',
      letterSpacing:-1
    }

    const notifications = []
    const notificationSorted = this.props.user.notifications.sort((b, a) => new Date(a.date) - new Date(b.date)).slice(0, 50)

    let notificationTitleDate = ''

    for (const notification of notificationSorted) {

      const randomFloat = (Math.random() - 0.5) * 2.5;

      if (notification.date.split('T')[0] !== notificationTitleDate) {

        notifications.push(
          <div style={{fontFamily:"Poppins-Bold", textAlign:'center', marginTop:35, marginBottom:10, letterSpacing:-1, lineHeight:'20px', fontSize:20}}>{fullTextDate(notification.date.split('T')[0])}</div>
        )

        notificationTitleDate = notification.date.split('T')[0]

      }

      let notificationStyle = notificationStyleOrigin

      if (this.props.user.last_notification_check && notification.date > this.props.user.last_notification_check)
        notificationStyle = {...notificationStyleOrigin, borderTop:'solid 4px #f03557'}

      notificationStyle = {...notificationStyle, transform: `rotate(${randomFloat}deg)`}

      if (notification.type === 'wallet_evolution') {

        if (notification.metadata.evolution > 0) {

          notifications.push(
            <div style={notificationStyle}>
              <div style={titleStyle}>nouveau classement</div>
              <div>le classement vient d'être mis à jour, votre collection gagne <span style={{color:'#00df00'}}>+{notification.metadata.evolution}%</span> par rapport au classement précédent soit <span style={{color:'#000'}}>+{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( notification.metadata.after_value - notification.metadata.before_value )}</span></div>
              <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
            </div>
          )      

        } else if (notification.metadata.evolution < 0) {

          notifications.push(
            <div style={notificationStyle}>
              <div style={titleStyle}>nouveau classement</div>
              <div>le classement vient d'être mis à jour, votre collection perd <span style={{color:'#000'}}>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( notification.metadata.before_value - notification.metadata.after_value )}</span> <span style={{color:'#f03557'}}>{notification.metadata.evolution}%</span> par rapport au classement précédent.</div>
              <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
            </div>
          )

        } else {

          notifications.push(
            <div style={notificationStyle}>
              <div style={titleStyle}>nouveau classement</div>
              <div>le classement vient d'être mis à jour, votre collection reste stable par rapport au classement précédent.</div>
              <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
            </div>
          )

        }

      } else if (notification.type === 'bet_won') {

        notifications.push(
          <div style={{...notificationStyle, color:'#000', textAlign:'center'}}>
            <div>
              <div style={{padding:7, backgroundColor:'#EEE', border:'solid 1px #CCC', margin:10, borderRadius:7}}>{notification.metadata.match}</div>
            </div>
            <div>pronostic gagnant : <span style={{color:'#00df00'}}>+{notification.metadata.pts} pts</span></div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'bet_lost') {

        notifications.push(
          <div style={{...notificationStyle, color:'#000', textAlign:'center'}}>
            <div>
              <div style={{padding:7, backgroundColor:'#EEE', border:'solid 1px #CCC', margin:10, borderRadius:7}}>{notification.metadata.match}</div>
            </div>
            <div>pronostic perdu : <span style={{color:'#f03557'}}>-50 pts</span></div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'bet_retired') {

        notifications.push(
          <div style={notificationStyle}>
            <div>Le pronostic <span style={{color:'#000'}}>{notification.metadata.match}</span> a été annulé suite à l'abandon d'un des deux joueurs.</div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'pronostic_challenge_won') {

        let priceString = ''

        if (notification.metadata.partner_price && notification.metadata.cash_price)
          priceString = `Vous gagnez ${notification.metadata.partner_price} ainsi que ${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( notification.metadata.cash_price )} pour acheter des joueurs. Nous allons vous contacter par email pour vous envoyer votre prix.`
        else if (notification.metadata.cash_price)
          priceString = `Vous gagnez ${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( notification.metadata.cash_price )} pour acheter des joueurs.`

        notifications.push(
          <div style={notificationStyle}>
            <div>Félicitations, vous avez remporté le concours de pronostics <span style={{color:'#000'}}>{notification.metadata.challenge}</span> avec {notification.metadata.pts} points. { priceString }</div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'pronostic_challenge_price') {

        notifications.push(
          <div style={notificationStyle}>
            <div>Vous terminez {notification.metadata.position}ème du concours de pronostics <span style={{color:'#000'}}>{notification.metadata.challenge}</span> avec {notification.metadata.pts} points. Vous gagnez {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( notification.metadata.cash_price )} pour acheter des joueurs.</div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'pronostic_challenge_result') {

        notifications.push(
          <div style={notificationStyle}>
            <div>Vous terminez {notification.metadata.position}ème du concours de pronostics <span style={{color:'#000'}}>{notification.metadata.challenge}</span> avec {notification.metadata.pts} points.</div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'new_deal') {

        notifications.push(
          <div style={notificationStyle}>
            <div style={titleStyle}>deal !</div>
            <div>vous venez de recevoir un bonus de {notification.metadata.spins} spins suite à votre commande de {notification.metadata.amount} chez {notification.metadata.advertiser_name}</div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'friend_invitation') {

        notifications.push(
          <div style={notificationStyle}>
            <div style={titleStyle}>demande d'amitié</div>
            <div>{notification.metadata.from_username} souhaite vous ajouter comme ami</div>
            <div style={{marginTop:10}}>
              <Button style={{margin:2}} onClick={ () => this.props.user.replyFriendInvitation(notification.metadata.from, 'accept') }>accepter</Button>
              <Button style={{margin:2}} onClick={ () => this.props.user.replyFriendInvitation(notification.metadata.from, 'decline') }>refuser</Button>
            </div>
            <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
          </div>
        )

      } else if (notification.type === 'friend_invitation_reply') {

        if (notification.metadata.status === 'accepted') {

          notifications.push(
            <div style={notificationStyle}>
              <div style={titleStyle}>invitation acceptée</div>
              <div>{notification.metadata.username} a accepté votre demande d'amitié</div>
              <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
            </div>
          )          

        } else if (notification.metadata.status === 'declined') {

          notifications.push(
            <div style={notificationStyle}>
              <div style={titleStyle}>invitation déclinée</div>
              <div>{notification.metadata.username} a décliné votre demande d'amitié</div>
              <div style={datetimeStyle}>{localDatetime(notification.date)}</div>
            </div>
          )

        }

      }

    }

    if (notifications.length > 0)
      return notifications
    
    return (<div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:35, textTransform:'uppercase', marginBottom:10, letterSpacing:-1, lineHeight:'20px', fontSize:20}}>Aucune notification</div>)

  }

  checkFriendCodeAvailability = async () => {

    this.setState({loading: true})

    const availability = await this.props.user.checkFriendCodeAvailability(this.state.friendCode)

    this.setState({loading: false, friendCodeAvailable: availability.available})

  }

  fillFriendCodeToBeAdded = (event) => {

    if (event.target.value.length > 8)
      return

    let tag = event.target.value.toUpperCase()

    // remove all characters that are not letters or numbers

    tag = tag.replace(/[^a-zA-Z0-9]/g, '')

    this.setState({
      friendCodeToBeAdded: tag
    })

  }

  sendFriendInvitation = async () => {

    this.setState({loading: true})

    await this.props.user.sendFriendInvitation(this.state.friendCodeToBeAdded)

    this.setState({loading: false, addNewFriend: false, friendCodeToBeAdded: ''})

  }

  updateFriendCode = (event) => {

    if (event.target.value.length > 8)
      return

    let tag = event.target.value.toUpperCase()

    // remove all characters that are not letters or numbers

    tag = tag.replace(/[^a-zA-Z0-9]/g, '')

    this.setState({
      friendCodeAvailable: null,
      friendCode: tag
    })

  }

  saveFriendCode = async () => {

    this.setState({loading: true})

    await this.props.user.updateFriendCode(this.state.friendCode)

    this.setState({loading: false, editFriendCode: false})

  }

  toggleEditFriendCode = () => {

    this.setState({
      friendCodeAvailable: null,
      friendCode: this.props.user.friend_code,
      editFriendCode: !this.state.editFriendCode
    })

  }

  sendFriendInvitation = async () => {

    this.setState({loading: true})

    await this.props.user.sendFriendInvitation(this.state.friendCodeToBeAdded)

    this.setState({loading: false, addNewFriend: false, friendCodeToBeAdded: ''})

  }

  renderFriendsTab = () => {

    return (
      <div style={{marginTop:15, marginBottom:50}}>

        <div style={{fontSize:18, marginTop:35, textTransform:'uppercase', fontFamily:'Poppins-Bold', textAlign:'center'}}>
          <div style={{lineHeight:1}}>mon tag à partager</div>
          <div style={{fontSize:24, lineHeight:1}}>{this.props.user.friend_code}</div>
          <div>
            <span onClick={ this.toggleEditFriendCode } style={{ fontFamily:'Poppins-Bold', lineHeight:1, textTransform:'uppercase', fontSize:'min(3.733vw, 16.052px)', color:'#333', borderBottom:'dotted 2px #999'}}>modifier mon tag</span>
          </div>
        </div>

        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', marginTop:25}}>
          <Button onClick={ () => this.setState({addNewFriend: true}) }>ajouter un ami</Button>
          <div>
            <a href={'https://amzn.to/48rbzX2'} style={{textDecoration:'none'}} target='_blank'>
              <span style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:'min(3.733vw, 16.052px)', color:'#333', borderBottom:'dotted 2px #999'}}>pas d'amis ? achetez des Curly</span>
            </a>
          </div>
        </div>

        <SwipeableDrawer
          anchor={ 'bottom' }
          open={ this.state.addNewFriend }
          onClose={ () => { this.setState({ addNewFriend: false, friendCodeToBeAdded: '' }) } }
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        >
          <div onClick={ () => this.setState({ addNewFriend: false, friendCodeToBeAdded: '' }) } style={{padding:90}}></div>
          <div style={{display:'flex', flexDirection:'column', backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
            <TextField value={ this.state.friendCodeToBeAdded } onChange={ this.fillFriendCodeToBeAdded } id="outlined-basic" style={{marginTop:15, marginBottom:15}} label="TAG AMI" variant="outlined" fullWidth />
            <Button disabled={ this.state.loading || this.state.friendCodeToBeAdded.length <= 3 } onClick={ this.sendFriendInvitation }>envoyer une invitation</Button>
          </div>
        </SwipeableDrawer>

        <SwipeableDrawer
          anchor={ 'bottom' }
          open={ this.state.editFriendCode }
          onClose={ this.toggleEditFriendCode }
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        >
          <div onClick={ this.toggleEditFriendCode } style={{padding:90}}></div>
          <div style={{display:'flex', flexDirection:'column', backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
            <TextField inputProps={{ maxLength: 8 }} value={this.state.friendCode} onChange={ this.updateFriendCode } id="outlined-basic" style={{marginTop:15, marginBottom:15}} label="MON TAG" variant="outlined" fullWidth />
            {
              (this.state.friendCodeAvailable !== null && this.state.friendCodeAvailable === false) &&
                <div style={{color:'#f03557', textAlign:'center', marginBottom:15}}>ce tag est déjà utilisé</div>
            }
            {
              (this.state.friendCodeAvailable !== null && this.state.friendCodeAvailable === true) &&
                <div style={{color:'#00df00', textAlign:'center', marginBottom:15}}>ce tag est disponible</div>
            }
            {
              (this.state.friendCodeAvailable === null || this.state.friendCodeAvailable === false) &&
                <Button disabled={this.state.loading || this.state.friendCode.length <= 3 || this.state.friendCode === this.props.user.friend_code} onClick={ this.checkFriendCodeAvailability }>vérifier la disponibilité</Button>
            }
            {
              this.state.friendCodeAvailable === true &&
                <Button disabled={this.state.loading} onClick={ this.saveFriendCode }>enregistrer</Button>
            }
          </div>
        </SwipeableDrawer>

      </div>
    )

  }

  renderGroupsTab = () => {

    return (
      <div style={{marginTop:30}}>
        <div style={{fontSize:18, marginTop:30, fontFamily:'Poppins-Bold'}}>
          
        </div>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', marginTop:50}}>
          <Button>rejoindre un groupe</Button>
          <Button>créer un groupe</Button>
        </div>
      </div>
    )

  }

  render = () => {

    return (
      <>
        <Tabs value={this.state.selectedTab} centered scrollButtons allowScrollButtonsMobile onChange={this.selectTab}>
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Jeu'} />
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Forum'} />
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Amis'} />
        </Tabs>
        {this.state.selectedTab === 0 && this.renderNotificationsTab()}
        {this.state.selectedTab === 1 && this.renderForumNotificationsTab()}
        {this.state.selectedTab === 2 && this.renderFriendsTab()}
        {this.state.selectedTab === 3 && this.renderGroupsTab()}
      </>
    )

  }

}

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <NotificationScreen {...props} />
  </ThemeProvider>

export default App
