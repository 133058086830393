import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
})

export default theme