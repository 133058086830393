import { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Groups, CardGiftcard, Notifications, Settings as SettingsIcon, Forum } from '@mui/icons-material';
import { SwipeableDrawer, Container, CircularProgress } from '@mui/material';
import Wallet from '../screen/wallet';
import Bonus from '../screen/bonus';
import Player from '../screen/player';
import Settings from '../screen/settings';
import AppContext from '../context/app';
import NotificationScreen from '../screen/notifications';
import ForumScreen from '../screen/forum';
import Product from '../screen/product'
import Catalog from '../screen/catalog'
import Deals from '../screen/deals'
import logo from '../asset/image/logo-header.png'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

class MainLayout extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showSettings: false,
    };
  }

  componentWillMount = () => {
    document.body.style.backgroundSize = '';
    document.body.style.background = '';
  }

  renderHeader = () => {

    let newNotificationsIndicator = false

    if (this.props.context.user.last_notification_check && this.props.context.user.last_notification_received) {

      if (this.props.context.user.last_notification_check < this.props.context.user.last_notification_received)
        newNotificationsIndicator = true

    } else if (this.props.context.user.last_notification_received) {

      newNotificationsIndicator = true

    }

    return (
      <AppBar position="fixed">
        <Toolbar style={{minHeight: 50, backgroundColor: '#fff'}}>
          <Container maxWidth="sm" disableGutters={true}> 
            <div style={{display:'flex', color:'#000', justifyContent:'space-between'}}>
              <div onClick={() => { this.setState({ showSettings: true }) }} style={{width:25}}><SettingsIcon style={{marginTop:5, fontSize:32}} /></div>
              { this.props.context.user.logo_loading
                  ? <CircularProgress size={32} style={{marginTop:5, color:'#000'}} />
                  : <div onClick={this.props.logoOnClick} style={{textAlign:'center', position:'relative', height:24, top:-1, color:'#000', letterSpacing: '-1.5px', fontSize:22, fontFamily:"Poppins-Bold"}}>
                      <img width={165} src={logo} />
                    </div>
              }
              <div onClick={ this.toggleNotifications } style={{width:25}}>
                 <div style={{position:'relative'}}>
                  { newNotificationsIndicator
                    && <div style={{position:'absolute', top:'0px', right:'-10px', backgroundColor:'#f03557', width:14, fontWeight:'bold', borderRadius:'50%', height:14}}></div> }
                    <Notifications style={{marginTop:5, fontSize:32}} />
                  </div>
              </div>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    )

  }

  renderFooter = () => {

    return (
      <AppBar position="fixed" style={{top:'auto', bottom:0}}>
        <Toolbar style={{color:'#000', minHeight:50, borderTop:'solid 1px #f1f1f1', backgroundColor:'#ffffff', paddingTop:5, paddingBottom:5}}>
          <Container maxWidth="sm">
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
              
              <div onClick={() => this.props.context.wallet.toggle(true, 0)} style={{width:40, textAlign:'center'}}>
                <div style={{height:32, position:'relative'}}>
                  { this.props.context.players.market_is_open
                    ? <div style={{position:'absolute', top:'0px', right:'-10px', letterSpacing:0, letterSpacing:-0.8, fontSize:11, paddingLeft:5, paddingRight:5, color:'#FFF', backgroundColor:'#40ab40', fontWeight:'bold', borderRadius:5 }}>ouvert</div>
                    : <div style={{position:'absolute', top:'0px', right:'-10px', letterSpacing:0, letterSpacing:-0.8, fontSize:11, paddingLeft:5, paddingRight:5, color:'#FFF', backgroundColor:'#f03557', fontWeight:'bold', borderRadius:5 }}>fermé</div>
                  }
                  <Groups style={{fontSize:34}} />
                </div>
                <div style={{height:22, marginTop:-3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <div style={{fontFamily:"Poppins-Bold", letterSpacing:-1, lineHeight:'13px', fontSize:14}}>
                    joueurs
                  </div>
                </div>
              </div>
              
              <div onClick={ this.props.context.forum.toggle } style={{width:40, textAlign:'center'}}>
                <div style={{height:32, position:'relative'}}>
                <div style={{position:'absolute', top:'0px', right:'-10px', letterSpacing:-0.8, fontSize:11, paddingLeft:5, paddingRight:5, color:'#FFF', backgroundColor:'#f03557', fontWeight:'bold', borderRadius:5 }}>new</div>
                  <Forum style={{fontSize:34}} />
                </div>
                <div style={{height:22, marginTop:-3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <div style={{fontFamily:"Poppins-Bold", letterSpacing:-1, lineHeight:'13px', fontSize:14}}>
                    forum
                  </div>
                </div>
              </div>

              <div onClick={() => { this.props.context.bonus.toggle(true) }} style={{width:40, textAlign:'center'}}>
                <div style={{height:32, position:'relative'}}>
                  <div style={{position:'absolute', top:'0px', right:'-10px', letterSpacing:-0.8, fontSize:11, paddingLeft:5, paddingRight:5, color:'#FFF', backgroundColor:'#f03557', fontWeight:'bold', borderRadius:5 }}>${ Math.floor( this.props.context.user.player_value - this.props.context.user.waiting_deals_value ) }</div>
                  <CardGiftcard style={{fontSize:34}} />
                </div>
                <div style={{height:22, marginTop:-3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <div style={{fontFamily:"Poppins-Bold", letterSpacing:-1, lineHeight:'13px', fontSize:14}}>
                    cadeaux
                  </div>
                </div>
              </div>
              
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    );

  }

  toggleNotifications = () => {

    if (this.props.context.notifications.show)
      this.props.context.user.updateLastNotificationCheck()

    this.props.context.notifications.toggle()

  }

  render = () => {

    const { body } = this.props;

    const SwipeableWallet = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.wallet.show }
        onClose={ () => this.props.context.wallet.toggle(false) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ () => this.props.context.wallet.toggle(false) } style={{padding:35}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.wallet.show && <Wallet /> }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableBonus = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.bonus.show }
        onClose={ () => this.props.context.bonus.toggle(false) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={() => this.props.context.bonus.toggle(false)} style={{padding:35}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.bonus.show && <Bonus gifts={this.props.context.gifts} user={this.props.context.user} /> }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableSettings = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.state.showSettings }
        onClose={ () => this.setState({ showSettings: false }) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={() => this.setState({ showSettings: false })} style={{padding:35}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <Settings user={this.props.context.user} />
        </div>
      </SwipeableDrawer>
    )

    const SwipeablePlayer = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.player.open }
        onClose={ () => this.props.context.player.select(false) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ () => this.props.context.player.select(false) } style={{padding:90}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.player.id && <Player /> }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableProduct = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.shop.product.show }
        onClose={ () => this.props.context.shop.product.close() }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { zIndex:100, backgroundColor: "transparent" } }}
      >
        <div onClick={ () => this.props.context.shop.product.close() } style={{padding:90}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.shop.product.id && <Product product={this.props.context.shop.product} deals={this.props.context.deals.programs} /> }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableCatalog = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.shop.catalog.show }
        onClose={ () => this.props.context.shop.catalog.toggle(false) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ () => this.props.context.shop.catalog.toggle(false) } style={{padding:35}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          {
            this.props.context.shop.catalog.show
              && <Catalog loadProduct={this.props.context.shop.product.load} catalog={this.props.context.shop.catalog} deals={this.props.context.deals.programs} />
          }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableDeals = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.deals.show }
        onClose={ this.props.context.deals.toggle }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { zIndex:100, backgroundColor: "transparent" } }}
      >
        <div onClick={ this.props.context.deals.toggle } style={{padding:30}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <Deals catalog={this.props.context.shop.catalog} deals={this.props.context.deals} user={this.props.context.user} />
        </div>
      </SwipeableDrawer>
    )

    const SwipeableNotifications = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.notifications.show }
        onClose={ this.toggleNotifications }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { zIndex:100, backgroundColor: "transparent" } }}
      >
        <div onClick={ this.toggleNotifications } style={{padding:30}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.notifications.show && <NotificationScreen user={this.props.context.user} forum={this.props.context.forum} notifications={this.props.context.notifications} /> }
        </div>
      </SwipeableDrawer>
    )

    const SwipeableForum = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.context.forum.show }
        onClose={ this.props.context.forum.toggle }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { zIndex:100, backgroundColor: "transparent" } }}
      >
        <div onClick={ this.props.context.forum.toggle } style={{padding:30}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          { this.props.context.forum.show && <ForumScreen user={this.props.context.user} forum={this.props.context.forum} /> }
        </div>
      </SwipeableDrawer>
    )

    const ErrorMessage = (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={ this.props.context.global_error.show }
        autoHideDuration={6000}
        onClose={ () => { this.props.context.global_error.setTo(false) } }
      >
        <Alert
          onClose={() => { this.props.context.global_error.setTo(false) }}
          severity="error"
          variant="filled"
        >
          { this.props.context.global_error.message }
        </Alert>
      </Snackbar>
    )

    return (
      <Container component="main" maxWidth="xs" style={{paddingBottom:100}}>
        { this.renderHeader() }
          { ErrorMessage }
          { body }
          { SwipeableWallet }
          { SwipeableBonus }
          { SwipeableSettings }
          { SwipeablePlayer }
          { SwipeableDeals }
          { SwipeableCatalog }
          { SwipeableProduct }
          { SwipeableNotifications }
          { SwipeableForum }
        { this.renderFooter() }
      </Container>
    );

  }

}

const App = ( props ) => 
  <AppContext.Consumer>
    {(appContext) => (
      <MainLayout {...props} context={appContext} />
    )}
  </AppContext.Consumer>

export default App
