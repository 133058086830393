import { Component } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { SwipeableDrawer } from '@mui/material'
import { localDatetime } from '../utils/common'

class Pronostics extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showDetails: false,
      loading: false,
      matchs: {

      }
    };
  }

  placeBet = async (pronosticId, matchId, choice) => {

    this.setState({ loading: true })

    await this.props.context.addOrUpdateBet(pronosticId, matchId, choice)
    await this.props.context.refresh()

    this.setState({ loading: false })

  }

  render = () => {

    const BetButton = styled(Button)(({ theme, backgroundColor }) => ({
      backgroundColor: `${backgroundColor}!important`,
      fontSize:15,
      borderRadius:15,
      fontFamily:'Poppins-Bold',
      height:45,
      color:'#000',
      letterSpacing:-1,
      minWidth:145
    }))

    if (this.props.context.id === "")
      return (
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1.1, fontSize:20, textAlign:'center', marginTop:25, marginBottom:10 }}>
          Les concours de pronostics sont disponibles pendant les Grand Chelems et les Masters 1000
        </div>
      )

    const rank = []

    this.props.context.user_rank.map((user, index) => {

      const position = index+1
      let partnerPrice
      let positionPrice

      if ( position === 1 && this.props.context.partner_price && this.props.context.partner_price.name_in_rank ) {

        partnerPrice = (
          <div style={{backgroundColor:'#DDD', textAlign:'center', fontFamily:'Poppins-Bold', margin:10, padding:7, borderRadius:10 }}>
            { this.props.context.partner_price.name_in_rank }
          </div>
        )

      }

      if ( this.props.context.global_prices[position] ) {

        positionPrice = (
          <div style={{backgroundColor:'#DDD', textAlign:'center', fontFamily:'Poppins-Bold', margin:10, padding:7, borderRadius:10}}>
            ${this.props.context.global_prices[position]} pour acheter des joueurs
          </div>
        )

      }

      if ( user.id === localStorage.getItem('token').split('.')[0] || this.props.context.global_prices[position] ) {

        rank.push(
          <div style={{ borderRadius:10, backgroundColor:'#FFF', padding:10, marginTop:10, border: 'solid 2px #CCC' }}>
            
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{ fontFamily:'Poppins-Bold', paddingRight:10, fontSize:18, textAlign:'left', textTransform:'uppercase', textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'overflow': 'hidden' }}>{position}{ position === 1 ? 'er' : 'ème' } - { user.name }</div>
              <div style={{ lineHeight:1, textAlign:'left', fontSize:16, lineHeight:'18px', marginTop:3, marginBottom:5, whiteSpace: 'nowrap' }}>{ user.pts } pts</div>
            </div>

            { partnerPrice }
            { positionPrice }

          </div>
        )

      }

    })

    if ( Object.entries(this.props.context.global_prices).length > rank.length ) {

      Object.entries(this.props.context.global_prices).slice(rank.length).map(([position, price]) => {

        let partnerPrice = null

        if ( position == 1 && this.props.context.partner_price && this.props.context.partner_price.name_in_rank ) {
          partnerPrice = <div style={{backgroundColor:'#DDD', textAlign:'center', fontFamily:'Poppins-Bold', margin:10, padding:7, borderRadius:10}}>
            {this.props.context.partner_price.name_in_rank}
          </div>
        }

        rank.push(
          <div key={position} style={{ borderRadius:10, backgroundColor:'#FFF', padding:10, marginTop:10, border: 'solid 2px #CCC' }}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{ fontFamily:'Poppins-Bold', fontSize:18, textAlign:'left', textTransform:'uppercase' }}>{position}{ position == 1 ? 'er' : 'ème' }</div>
              <div style={{ lineHeight:1, textAlign:'left', fontSize:18, marginTop:3, marginBottom:5 }}></div>
            </div>

            { partnerPrice }

            <div style={{backgroundColor:'#DDD', textAlign:'center', fontFamily:'Poppins-Bold', margin:10, padding:7, borderRadius:10}}>
              ${price} pour acheter des joueurs
            </div>

          </div>
        )

      })

    }

    const SwipeableDetails = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.state.showDetails }
        onClose={ () => this.setState({ showDetails: false }) }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ () => this.setState({ showDetails: false }) } style={{padding:90}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', paddingBottom:3, marginBottom:30, marginTop:25, borderBottom:'solid 3px #BBB', paddingLeft:5 }}>comment jouer ?</div>
          <ul style={{ fontFamily:'Poppins-Bold', fontSize:14, lineHeight:1.2, textTransform:'uppercase', paddingInlineStart: 25 }}>
            <li key={0}>Chaque jour, des matchs sont proposés sur la compétition concernée</li>
            <li key={1} style={{marginTop:10}}>Vous devez pronostiquer le vainqueur du match en sélectionnant un des deux joueurs</li>
            <li key={2} style={{marginTop:10}}>Si votre choix est juste vous gagnez les points associés</li>
            <li key={3} style={{marginTop:10}}>Si votre choix est incorrect vous perdez 50 points</li>
            <li key={4} style={{marginTop:10}}>Le pronostic est annulé si un des deux joueurs abandonne</li>
            <li key={5} style={{marginTop:10}}>Vos points déterminent votre position au classement</li>
            <li key={6} style={{marginTop:10}}>A la fin de la compétition, vous remportez la récompense associée à votre classement</li>
          </ul>
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', paddingBottom:3, marginBottom:30, marginTop:25, borderBottom:'solid 3px #BBB', paddingLeft:5 }}>classement</div>
          
          { rank }

        </div>
      </SwipeableDrawer>
    )

    const matches = []

    const userId = localStorage.getItem('token').split('.')[0]

    let userPosition = null
    let userPoints = null

    const userRank = this.props.context.user_rank.find((user) => { return user.id === userId })

    if ( userRank ) {
      userPosition = this.props.context.user_rank.findIndex((user) => { return user.id === userId }) + 1
      userPoints = this.props.context.user_rank.find((user) => { return user.id === userId }).pts
    }

    this.props.context.matches.map((match) => {

      let selectedChoice = 0

      if (match.userPronostic && match.userPronostic.choice)
        selectedChoice = match.userPronostic.choice

      matches.push(
        <div style={{marginTop:10}}>
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:13, color:'#666', marginBottom:3, textAlign:'center' }}>{localDatetime(match.start)}</div>
          <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly',  }}>
            <div><BetButton disabled={this.state.loading} onClick={() => this.placeBet(this.props.context.id, match.id, 1)} backgroundColor={ selectedChoice === 1 ? '#f0bc3d' : '#eee' } variant="contained"><div style={{lineHeight:1}}><div>{ match.player_1 }</div><div style={{fontSize:12}}>{ match.points_1 } pts</div></div></BetButton></div>
            <div><BetButton disabled={this.state.loading} onClick={() => this.placeBet(this.props.context.id, match.id, 2)} backgroundColor={ selectedChoice === 2 ? '#f0bc3d' : '#eee' } variant="contained"><div style={{lineHeight:1}}><div>{ match.player_2 }</div><div style={{fontSize:12}}>{ match.points_2 } pts</div></div></BetButton></div>
          </div>          
        </div>
      )

    })

    return (
      <>
        <div style={{ backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', marginTop:35, padding:10, paddingTop:15, paddingBottom:25, borderRadius:10 }}>
          <div style={{ textAlign:'center', lineHeight:1 }}>
            <div style={{ fontFamily:'Poppins-Bold', fontSize:'min(6.400vw, 27.52px)', marginTop:3, letterSpacing:-1.5, textTransform:'uppercase' }}>{ this.props.context.name }</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:24, color:'#333', marginTop:15 }}>A gagner</div>
          
          { this.props.context.partner_price.nameAAA
          
            ? <div style={{ display:'flex', flexDirection:'column', textAlign:'center', lineHeight:1, borderRadius:10, padding:15, paddingTop:20, paddingBottom:20, marginTop:'-5px', marginBottom:5, backgroundColor:'#333' }}>
              <div style={{display:'flex'}}>
                <div style={{marginRight:10}}>
                  <img style={{ height:100 }} src={ this.props.context.partner_price.img } />
                </div>
                <div>
                  <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:16, color:'#DDD', paddingTop:5 }}>{ this.props.context.partner_price.name }</div>
                  <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:12, color:'#DDD', paddingTop:10, lineHeight:1.25 }}>{ this.props.context.partner_price.description }</div>
                </div>
              </div>
              <div>
                <a href={ this.props.context.partner_price.url } rel="noreferrer" style={{textDecoration: 'none'}} target="_blank">
                  <div style={{fontFamily:'Poppins-Bold', textTransform:'uppercase', backgroundColor:"#ff9900", color:'#fff', fontSize:14, textAlign:'center', marginTop:20, padding:7, borderRadius:10}}>{ this.props.context.partner_price.url_label }</div>
                </a>
              </div>
            </div>

            : <div style={{ display:'flex', flexDirection:'column', textAlign:'center', lineHeight:1, borderRadius:10, padding:15, paddingTop:20, paddingBottom:20, marginTop:'-5px', marginBottom:5, backgroundColor:'#333' }}>
            <div style={{paddingLeft:10, paddingRight:10, fontFamily:'Poppins-Bold', color:'#999', fontSize:22, display:'flex', justifyContent:'space-between'}}>
              <div style={{ textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden' }}><span style={{paddingRight:10, color:'#FFF'}}>1er</span>..................................</div>
              <div style={{paddingLeft:10, color:'#FFF'}}>+${this.props.context.global_prices[1]}</div>
            </div>
            <div style={{paddingLeft:10, paddingRight:10, marginTop:10, marginBottom:10, fontFamily:'Poppins-Bold', color:'#999', fontSize:22, display:'flex', justifyContent:'space-between'}}>
              <div style={{ textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden' }}><span style={{paddingRight:10, color:'#FFF'}}>2ème</span>...................................</div>
              <div style={{paddingLeft:10, color:'#FFF'}}>+${this.props.context.global_prices[2]}</div>
            </div>
            <div style={{paddingLeft:10, paddingRight:10, fontFamily:'Poppins-Bold', color:'#999', fontSize:22, display:'flex', justifyContent:'space-between'}}>
              <div style={{ textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden' }}><span style={{paddingRight:10, color:'#FFF'}}>3ème</span>..................................</div>
              <div style={{paddingLeft:10, color:'#FFF'}}>+${this.props.context.global_prices[3]}</div>
            </div>
            <div></div>
            <div></div>
          </div>
          
          }

          <div onClick={() => { this.setState({showDetails: true}) }} style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:13, textTransform:'uppercase', textAlign:'center', lineHeight:1, marginBottom:20, marginTop:5 }}>
            <span style={{ borderBottom:'dotted 2px #999'}}>voir le classement</span>
          </div>

          { matches }

          { userPosition && <div onClick={() => { this.setState({showDetails: true}) }}>
            <div style={{ fontFamily:'Poppins-Bold', color:'#666', fontSize:22, textTransform:'uppercase', textAlign:'center', lineHeight:1, marginTop:25 }}>
              {userPosition}{ userPosition === 1 ? 'er' : 'ème' }
            </div>
            <div style={{ fontFamily:'Poppins-Bold', color:'#888', fontSize:18, textTransform:'uppercase', textAlign:'center', lineHeight:1 }}>
              { userPoints } points
            </div>
          </div> }
        </div>
        <div onClick={() => { this.setState({showDetails: true}) }} style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:14, textTransform:'uppercase', textAlign:'center', lineHeight:1, marginTop:20 }}>
          <span style={{ borderBottom:'dotted 2px #999'}}>comment jouer ?</span>
        </div>
        { SwipeableDetails }
      </>
    )

  }

}

export default Pronostics;
