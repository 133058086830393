import { Component } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { SwipeableDrawer } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../config/theme'
import { fullTextDate } from '../utils/common'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

const ColorButton = styled(Button)(({ theme }) => ({
  fontFamily:'Poppins-Bold',
  backgroundColor:`#f0bc3d!important`,
  fontSize:16,
  borderRadius:23,
  height:35,
  color:'#333',
  letterSpacing:-0.5,
  paddingRight:15,
  paddingLeft:15
}))

class Bonus extends Component {

  constructor (props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedItem: null,
      items: [],
      saveInProgress: false,
      saveStatus: []
    }
  }

  componentDidMount = () => {

    this.refreshGifts()

  }

  refreshGifts = () => {

    const gifts = this.props.gifts.list
    const orders = this.props.gifts.orders
    const items = []

    for (const gift of gifts) {
      const order = orders.find( order => order.gift_id === gift.id )
      if (order !== undefined)
        gift.order = {...order}
      else
        gift.order = null
      items.push({ ...gift })
    }

    this.setState({ items })

  }

  buyGift = async (giftId) => {

    await this.props.gifts.buy( giftId )
    this.refreshGifts()

  }

  updateOrderInfos = (orderId, field, event) => {

    const gifts = this.state.items
    const selectedOrder = gifts.find( gift => gift.order.id === orderId )
    selectedOrder.order[field] = event.target.value

    this.setState({ items: gifts })

  }

  saveOrder = async (giftId) => {

    this.setState({ saveInProgress: true, saveStatus: [] })

    const gifts = this.state.items
    const selectedGift = gifts.find( gift => gift.id === giftId )
    const order = selectedGift.order

    await this.props.gifts.saveOrder(order)

    this.setState({ saveInProgress: false, saveStatus: [{order: order.id}] })

  }

  selectItem = (item) => {
    this.setState({ selectedItem: item })
  }

  closeSelectedItem = () => {
    this.setState({ selectedItem: null })
  }

  selectTab = (event, newValue) => {
    this.setState({ selectedTab: newValue })
  }

  renderOrder = (gift) => {

    let status

    const order = gift.order

    if (order.status === 'pending' && gift.is_code)
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>code en cours de création</div>
    else if (order.status === 'pending' && gift.address_required)
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>En attente des infos de livraison</div>
    else if (order.status === 'in_progress')
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>Traitement en cours</div>
    else if (order.status === 'shipped')
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#40ab40', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>Expédié</div>
    else if (order.status === 'completed')
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#40ab40', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>Code disponible</div>
    else if (order.status === 'canceled')
      status = <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#f03557', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:14, padding:4, color:'#FFF'}}>Annulé</div>

    return (
      <div style={{ backgroundColor:'#FFF', borderRadius:10, padding:15, paddingBottom:10, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', marginBottom:20 }}>
        <div style={{ display:'flex', flexDirection: 'column' }}>
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:22, textAlign:'center', lineHeight:'20px', color:'#000', marginBottom:10, marginTop:10, letterSpacing:-1.3 }}>{ gift.name }</div>
          <div style={{ display:'flex', justifyContent: 'center' }}>
            
                <div style={{ width:135, position:'relative' }}>
                  { order.shipping_size !== '' &&
                      <div style={{position:'absolute', right:0, top:0, fontFamily:'Poppins-Bold', backgroundColor:'#666', color:'#FFF', borderRadius:'50%', width:25, height:25, textAlign:'center'}}>{ order.shipping_size }</div>
                  }
                  <img style={{ width:135 }} src={ gift.image } />
                </div>

          </div>
        </div>
        <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:14, color:'#999', marginTop:10 }}>
          { status }
        </div>
        { gift.is_code && gift.order.code !== '' &&
            <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:14, color:'#999', marginTop:10 }}>
              <div style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:16, padding:3, color:'#FFF'}}>{ gift.order.code }</div>
              <div style={{whiteSpace:'nowrap', color:'#555', fontFamily:'Poppins-Bold', margin:3, textTransform:'uppercase', fontSize:12 }}>{ gift.code_instruction }</div>
            </div>
        }
        { gift.address_required && order.status === 'pending' &&
            <div style={{ fontFamily:'Poppins-Bold', textAlign:'left', textTransform:'uppercase', fontSize:14, color:'#999', marginTop:10 }}>
              <TextField value={order.shipping_name} onChange={(event) => { this.updateOrderInfos(order.id, 'shipping_name', event) }} id="outlined-basic" style={{marginTop:15}} label="Nom" variant="outlined" fullWidth />
              <TextField value={order.shipping_address} onChange={(event) => { this.updateOrderInfos(order.id, 'shipping_address', event) }} id="outlined-basic" style={{marginTop:15}} label="Adresse" variant="outlined" fullWidth />
              <TextField value={order.shipping_city} onChange={(event) => { this.updateOrderInfos(order.id, 'shipping_city', event) }} id="outlined-basic" style={{marginTop:15}} label="Ville" variant="outlined" fullWidth />
              <TextField value={order.shipping_postal_code} onChange={(event) => { this.updateOrderInfos(order.id, 'shipping_postal_code', event) }} id="outlined-basic" style={{marginTop:15}} label="Code postal" variant="outlined" fullWidth />
              <Box style={{marginTop:15}} sx={{ minWidth: 120 }} >
                <FormControl fullWidth>
                  <InputLabel id="size-label">Taille</InputLabel>
                  <Select
                    labelId="size-label"
                    id="size"
                    value={order.shipping_size}
                    label="Taille"
                    onChange={(event) => {  this.updateOrderInfos(order.id, 'shipping_size', event) }}
                  >
                    <MenuItem value={"S"}>S</MenuItem>
                    <MenuItem value={"M"}>M</MenuItem>
                    <MenuItem value={"L"}>L</MenuItem>
                    <MenuItem value={"XL"}>XL</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10, textAlign:'center' }}>
                <ColorButton onClick={ () => this.saveOrder(gift.id) } disabled={this.state.saveInProgress} variant="contained">enregistrer</ColorButton>
                {
                  this.state.saveStatus.filter(status => status.order === gift.order.id).length > 0
                    && <span style={{ padding:7, color:'#40ab40' }}>enregistré</span>
                }
              </div>
            </div>
        }

        { !gift.is_code && (order.status === 'in_progress' || order.status === 'shipped') &&
            <div style={{display:'flex', flexDirection:'column', margin:10, padding:10, border:'solid 2px #CCC', borderRadius:20}}>
              <div>{order.shipping_name}</div>
              <div>{order.shipping_address}</div>
              <div>{order.shipping_postal_code} - {order.shipping_city}</div>
            </div>
        }
        <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:16, color:'#999', marginTop:10 }}>
          prix: ${ gift.cost }
        </div>
        <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:16, color:'#999' }}>
          acheté le { fullTextDate(order.date) }
        </div>
      </div>
    )

  }

  renderPrice = (price, player_value) => {

    let percent = Math.floor((player_value/price.cost_unlock)*100)
    const alreadyBought = !(price.order === null)

    if (alreadyBought || price.type !== 'buy')
      return null

    if (percent >= 100)
      percent = 100

    return (
      <div onClick={ () => { this.selectItem(price.id) } } style={{ marginBottom:20 }}>

        { !alreadyBought &&

          <div style={{ display:'flex', justifyContent:'space-between' }}>

            {
              percent == 100
                ? <div style={{ fontFamily:'Poppins-Bold', color:'#40ab40', fontSize:20, paddingLeft:10 }}>${ Math.floor(player_value) } / { `$${price.cost_unlock}` }</div>
                : <div style={{ fontFamily:'Poppins-Bold', color:'#f03557', fontSize:20, paddingLeft:10 }}>${ Math.floor(player_value) } / { `$${price.cost_unlock}` }</div>
            }

            <div style={{marginRight:10}}>
              <div style={{display:'flex', fontFamily:'Poppins-Bold', fontSize:12, lineHeight:'20px', borderRadius:3, backgroundColor:'#BBB', marginTop:3, height:20, width:70}}>
                <div style={{backgroundColor:'#f0bc3d', borderRadius:3, height:20, width:`${ percent }%`}}>
                  <div>{ percent >= 50 && <span style={{paddingLeft:5}}>{percent}%</span> }</div>
                </div>
                <div style={{ height:20 }}>
                  { percent < 50 && <span style={{paddingLeft:5}}>{percent}%</span> }
                </div>
              </div>
            </div>

          </div>

        }

        <div style={{ backgroundColor:'#FFF', borderRadius:10, padding:15, paddingBottom:10, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)' }}>
          <div style={{ display:'flex', flexDirection: 'column' }}>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:22, textAlign:'center', lineHeight:'20px', color:'#000', marginBottom:10, marginTop:10, letterSpacing:-1.3 }}>{ price.name }</div>
            <div style={{ display:'flex', justifyContent: 'center' }}>
              <img style={{ width:135 }} src={ price.image } />
            </div>
          </div>
          <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:10, textAlign:'center' }}>
            <ColorButton variant="contained">en savoir plus</ColorButton>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:14, color:'#999', marginTop:10 }}>
            prix: ${ price.cost }
          </div>
          <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:14, color:'#999' }}>
            quantité restante: { price.quantity }
          </div>
          <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:14, color:'#999' }}>
            fin de l'offre le { fullTextDate(price.end) }
          </div>
        </div>

      </div>
    )

  }

  renderSelectedPrice = () => {

    const selectedGift = this.state.selectedItem !== null ? this.state.items.find( gift => gift.id === this.state.selectedItem ) : null

    if (selectedGift === null)
      return null

    const alreadyBought = selectedGift.order !== null
    
    const player_value = this.props.user.player_value - this.props.user.waiting_deals_value
    const missing_value = Math.round((selectedGift.cost_unlock - player_value)*100)/100
    const missing_cash = Math.round((selectedGift.cost - this.props.user.cash)*100)/100

    return (
      <>
        <div onClick={ this.closeSelectedItem } style={{padding:90}}></div>
        <div style={{backgroundColor:'#fff', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <div style={{marginBottom:20}}>
              <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:24, lineHeight:1, marginBottom:10, letterSpacing:-1.1, marginTop:15 }}>{ selectedGift.name }</div>
              <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', fontSize:20, lineHeight:1.2, letterSpacing:-1.1, color:'#666', marginTop:25, marginBottom:20 }}>{ selectedGift.description }</div>
              <div style={{ textAlign:'center', marginRight:10}}><img style={{width:300}} src={selectedGift.image} /></div>
            </div>
            <a href={selectedGift.link.replace('__userid__', `user_${localStorage.getItem('token').split('.')[0]}`)} style={{textDecoration: 'none'}} target="_blank">
              <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:15, textAlign:'center' }}>
                <ColorButton variant="contained">voir sur le site partenaire</ColorButton>
              </div>
            </a>

            { !alreadyBought && selectedGift.quantity > 0 &&
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:15, textAlign:'center' }}>
                  <ColorButton onClick={() => { this.buyGift( selectedGift.id ) }} disabled={ missing_value > 0 || missing_cash > 0 || this.props.gifts.buyingSpinner } variant="contained">acheter ${ selectedGift.cost }</ColorButton>
                </div>
            }
            
            { !alreadyBought && missing_value > 0 &&
                <>
                  <div style={{ fontFamily:'Poppins-Bold', color:'#f65c5c', lineHeight:1.2, textAlign:'center', textTransform:'uppercase', fontSize:16, marginTop:20 }}>
                    cette récompense est accéssible aux joueurs ayant un capital qui dépasse ${ selectedGift.cost_unlock }
                  </div>
                  <div style={{ fontFamily:'Poppins-Bold', color:'#f65c5c', lineHeight:1.2, textAlign:'center', textTransform:'uppercase', fontSize:16, marginTop:10 }}>
                    il vous manque ${missing_value} pour débloquer cette récompense
                  </div>
                </>
            }

            { !alreadyBought && missing_value < 0 && missing_cash > 0 &&
                <div style={{ fontFamily:'Poppins-Bold', color:'#f65c5c', lineHeight:1.2, textAlign:'center', textTransform:'uppercase', fontSize:16, marginTop:20 }}>
                  il vous manque ${missing_cash} en cash pour pouvoir acheter cet article, vendez des joueurs pour obtenir plus de cash
                </div>
            }

            { alreadyBought &&
                <div style={{ fontFamily:'Poppins-Bold', color:'#40ab40', lineHeight:1.2, textAlign:'center', textTransform:'uppercase', fontSize:16, marginTop:20 }}>
                  vous avez acheté cet article, rendez-vous dans la section "mes achats" pour plus d'informations
                </div>
            }

            { !alreadyBought &&
              <div style={{ fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:16, color:'#000', marginTop:20 }}>
                quantité restante: { selectedGift.quantity }
              </div>
            }
          </div>
        </div>
      </>
    )

  }

  renderShop = () => {

    const player_value = this.props.user.player_value - this.props.user.waiting_deals_value
    const gifts = []

    for (const gift of this.state.items)
      if (gift.type === 'buy' && gift.order === null)
        gifts.push( this.renderPrice(gift, player_value) )

    return (
      <>
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:22, letterSpacing:-1.5, marginTop:25, lineHeight:1.1, textAlign:'center' }}>
          dépensez votre cash dans la boutique Tennis Empire
        </div>
        <div style={{ fontFamily:'Poppins-Bold', color:'#555', textTransform:'uppercase', fontSize:18, letterSpacing:-1.5, marginTop:25, lineHeight:1.1, textAlign:'center' }}>
          La valeur de votre capital (votre collection + votre cash) débloque les récompenses
        </div>
        <div style={{ fontFamily:'Poppins-Bold', color:'#555', textTransform:'uppercase', fontSize:18, letterSpacing:-1.5, marginTop:10, lineHeight:1.1, textAlign:'center' }}>
          Dépensez votre cash pour acheter les récompenses débloquées
        </div>
        <div style={{marginTop:35, width:'100%'}}>
          
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:22, lineHeight:'22px', borderRadius:10, backgroundColor:'#333', padding:15, paddingTop:5, paddingBottom:5, color:'#EEE', marginTop:10}}>
            <div style={{textTransform:'uppercase', fontSize:14}}>votre capital</div>
            <div>
              ${ Math.floor(player_value) }
            </div>
          </div>

          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:22, lineHeight:'22px', borderRadius:10, backgroundColor:'#333', padding:15, paddingTop:5, paddingBottom:5, color:'#EEE', marginTop:10}}>
            <div style={{textTransform:'uppercase', fontSize:14}}>dont cash disponible</div>
            <div>
              ${ Math.floor(this.props.user.cash) }
            </div>
          </div>

          { this.props.user.waiting_deals_value > 0
            &&  <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:20, lineHeight:'20px', borderRadius:10, backgroundColor:'#BBB', padding:5, color:'#333', marginTop:10}}>
                  <div style={{textTransform:'uppercase', fontSize:12, lineHeight:'14px'}}>deals en attente</div>
                  <div>
                    { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.user.waiting_deals_value) }
                  </div>
                </div>
          }

          <div style={{marginTop:50}}>

            { gifts.length > 0 ?
                gifts :
                <div style={{textAlign:'center', marginTop:50, fontFamily:'Poppins-Bold', fontSize:20, color:'#666', paddingBottom:50}}>aucun stock disponible</div>
            }

          </div>

        </div>
      </>
    )

  }

  renderOrders = () => {

    const output = []
    const items = this.state.items.filter( item => item.order !== null )

    for (const item of items)
      output.push( this.renderOrder(item) )

    if (output.length === 0)
      return (
        <div style={{textAlign:'center', marginTop:50, fontFamily:'Poppins-Bold', fontSize:20, color:'#666', paddingBottom:50}}>aucun achat effectué</div>
      )

    return (
      <div style={{marginTop:50}}>
        { output }
      </div>
    )

  }

  render = () => {

    return (
      <div>
        <Tabs value={this.state.selectedTab} centered scrollButtons allowScrollButtonsMobile onChange={this.selectTab}>
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Boutique'} />
          <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Mes achats'} />
        </Tabs>
        
        { this.state.selectedTab === 0 && this.renderShop() }
        { this.state.selectedTab === 1 && this.renderOrders() }

        <SwipeableDrawer
          anchor={ 'bottom' }
          open={ this.state.selectedItem }
          onClose={ this.closeSelectedItem }
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        >
          { this.renderSelectedPrice() }
        </SwipeableDrawer>

      </div>
    )

  }

}

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <Bonus {...props} />
  </ThemeProvider>

export default App
