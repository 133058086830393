import { Component } from 'react'
import Login from '../layout/login'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

class PrivacyPolicy extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      paddingRight:15,
      paddingLeft:15
    }));

    const body = (
      <>
        <div style={{ display:'flex', paddingTop:50,  textAlign:'center', flexDirection:'column' }}>

          <ColorButton onClick={() => { window.location.replace('/') }} style={{marginTop:20, marginBottom:35, width:200, marginLeft:'auto', marginRight:'auto'}}>Retour</ColorButton>
          
          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 1 - Principes
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de l'utilisation de l'application Tennis Empire et de ses services.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Les conditions générales d'utilisation doivent être acceptées par tout utilisateur et son accès au site vaut acceptation de ces coonditions.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 2 - Evolution et durée des CGU
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Les présentes conditions génréales d'utilisation sont conclues pour une durée indéterminée. Le contrat produit ses effet à l'égard de l'Utilisateur à compter du début de l'utilisation du service.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            L'application Tennis Empire se réservice le droit de modifier les clauses de ces conditions générales d'utilisation à tout moment et sans justification.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 3 - Accès à l'application
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            L'accès à l'application est exclusivement réservé aux personnes agées de plus de 16 ans.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Le site et ses différents services peuvent être interrompus ou suspendus par l'éditeur, notamment à l'occasion d'une maintenance, sans obligation de préavis ou de justification.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 4 - Responsabilités
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            La responsabilité de l'éditeur ne peut pas être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 5 - Protection des données personnelles
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            profil: lors de la connexion de l'utilisateur au site web, celui-ci enregistre son prénom, nom, adresse email.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            cookies: Les cookies tiers ne sont pas utilisés.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            L'intégralité des données personnelles peuvent être supprimées à tout moment par l'utilisateur en allant dans les paramètres du compte puis en cliquant sur "supprimer mon compte". L'utilisateur a 7 jours pour annuler la demande.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 6 - Utilisation des données personnelles
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Les données personnelles collectés auprès des utilisateurs ont pour objectif la mise à disposition des services du site web, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            - accès et utilisation de l'application par l'utilisateur.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            - Les utilisateurs n'auront pas accès aux emails des autres utilisateurs.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            - Les emails ne seront pas divulgés ou revendus à des acteurs tiers.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            - Les emails permettront à Tennis Empire de contacter et d'informer les utilisateurs (notification ou recommandation).
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 7 - Sécurité et confidentialité
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            L'application met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toute, il est à signaler qu'Internet n'est pas un environnement complètement sécurisé et l'application ne peut pas garantir la sécurité de la transmission ou du stockage des informations.
          </div>

          <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
            Article 8 - Loi applicable
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Les présentes conditions générales d'utilisation sont soumises à l'application du droit français.
          </div>
          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence des tribunaux français.
          </div>

          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Le service Tennis Empire est hebergé par Google Cloud, société SARL unipersonnelle au capital de 1000000€ dont le siège social est situé à 8 rue de Londres 75009 Paris France, immatriculée au registre du commerce et des sociétés de Paris, sous le numéro 881 621 583.
          </div>

          <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
            Le service Tennis Empire est géré par Paul Danilo AE - SIRET 51904292300028 
          </div>

          <ColorButton onClick={() => { window.location.replace('/') }} style={{marginTop:20, marginBottom:35, width:200, marginLeft:'auto', marginRight:'auto'}}>Retour</ColorButton>

        </div>
      </>
    )

    return (
      <Login body={body} />
    )

  }

}

export default PrivacyPolicy
