import React from "react"
// import { getToken } from "firebase/messaging"
import firebase from '../firebase'
import { fetchGetURL, fetchPostURL } from '../utils/fetch'

const AppContext = React.createContext({
  global_error: {
    show: false,
    message: `Une erreur s'est produite :'(`,
    set: () => {}
  },
  user: {
    loaded: false,
    wallet_value: 0,
    wallet_evolution: 0,
    available_spin: false,
    available_spins: 0,
    cash: 0,
    ranks: [],
    tags: [],
    spinnedPlayer: null,
    notifications: [],
    forum_notifications: [],
    transactions: [],
    bonus: 0,
    player_value: 0,
    last_notification_received: null,
    last_notification_check: null,
    accept_mail: true,
    username: '',
    email: '',
    waiting_deals_value: 0,
    friend_code: '',
    friends: [],
    datetime_deletion: null,
    avatar: '',
    notification_status: localStorage.getItem('notification_asked') ? localStorage.getItem('notification_asked') : 'waiting',
    refresh: () => {},
    spin: () => {},
    sellSpin: () => {},
    updateLastNotificationCheck: () => {},
    updateAcceptMail: () => {},
    updateUsername: () => {},
    toggleLogoLoading: () => {},
    checkFriendCodeAvailability: () => {},
    updateFriendCode: () => {},
    sendFriendInvitation: () => {},
    replyFriendInvitation: () => {},
    deleteAccount: () => {},
    requestNotificationPermission: () => {},
    declineNotificationPermission: () => {}
  },
  wallet: {
    show: false,
    selectedTab: 0,
    toggle: () => {}
  },
  bonus: {
    show: false,
    toggle: () => {}
  },
  player: {
    id: false,
    history: [],
    loading: false,
    open: false,
    buy_mode: false,
    sell_mode: false,
    orderInProgress: false,
    switch_mode: () => {},
    select: () => {},
    buy: () => {},
    sell: () => {}
  },
  players: {
    list: [],
    last_refresh: null,
    market_is_open: false,
    refresh: () => {}
  },
  pronostics: {
    id: "",
    name: "",
    user_rank: [],
    partner_price: {
      name_in_rank: "",
      name: "",
      url: "",
      url_label: "",
      description: "",
      img: ""
    },
    global_prices: {},
    matches: [],
    refresh: () => {},
    addOrUpdateBet: () => {},
    loading: false
  },
  shop: {
    home: {
      list: [],
      load: () => {}
    },
    catalog: {
      show: false,
      loading: false,
      tags: [],
      selectedTab: 0,
      list: [],
      refreshTag: () => {},
      toggle: () => {}
    },
    product: {
      show: false,
      loading: false,
      load: () => {},
      close: () => {},
      id: null,
      name: '',
      pictures: [],
      tags: [],
      deals: [],
      source: '',
      program_ref: ''
    }
  },
  deals: {
    show: false,
    programs: [],
    toggle: () => {}
  },
  gifts: {
    list: [],
    orders: [],
    buyingSpinner: false,
    refresh: () => {},
    buy: () => {},
    buyInProgress: false,
    save: () => {}
  },
  notifications: {
    show: false,
    tab: 0,
    toggle: () => {}
  },
  forum: {
    loadConfig: () => {},
    spinnerThreadsList: false,
    spinnerThread: false,
    spinnerNextPageThreads: false,
    show: false,
    showNewTopic: false,
    toggle: () => {},
    toggleNewTopic: () => {},
    rootTags: [],
    availableTags: [],
    selectedTags: [],
    hiddenTags: [],
    selectTags: () => {},
    selectTag: () => {},
    hideTag: () => {},
    unhideTag: () => {},
    removeTag: () => {},
    topics: [],
    topics_bookmark: null,
    selectedThread: {},
    selectedMessageDiscussionMode: null,
    selectMessageDiscussionMode: () => {},
    selectMessage: () => {},
    closeCurrentMessage: () => {},
    cleanAllTreads: () => {},
    postNewTopic: () => {},
    postNewMessage: () => {},
    loadNextThreadsPage: () => {},
    addLike: () => {},
  }
});

export default AppContext;

class Provider extends React.Component {

  constructor(props) {

    super(props)

    const setStateAsync = (state) => {
      return new Promise((resolve) => {
        this.setState(state, resolve)
      });
    }

    const loadForumConfig = async () => {

      const forumConfig = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/forum/config`)

      if (!forumConfig.data)
        return null

      const availableTags = forumConfig.data.availableTags
      const rootTags = forumConfig.data.rootTags

      this.setState({
        forum: {
          ...this.state.forum,
          availableTags,
          rootTags
        }
      })

    }

    const toggleForum = async () => {

      const currentStatus = this.state.forum.show

      await setStateAsync({
        forum: {
          ...this.state.forum,
          show: !this.state.forum.show
        }
      })

      if (currentStatus)
        return null

      const tags = []

      for (const category of this.state.forum.rootTags) {
        // get last tag of each category
        tags.push(category.tags[category.tags.length - 1])
      }

      const rootTags = [...this.state.forum.rootTags]

      const forumConfig = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/forum/tags`, { tags: tags.join('+') })

      if (!forumConfig.data)
        return null

      for (const category of rootTags) {
        const lastTag = category.tags[category.tags.length - 1]
        const stats = forumConfig.data.find( item => item.tag === lastTag )

        if (stats) {
          category.last_updated = stats.last_updated
          category.topics_count = stats.topics_count
          category.messages_count = stats.messages_count
          category.last_author = stats.last_author
        }
      }

      this.setState({
        forum: {
          ...this.state.forum,
          rootTags
        }
      })

    }

    const toggleNewTopic = () => {

      this.setState({
        forum: {
          ...this.state.forum,
          showNewTopic: !this.state.forum.showNewTopic
        }
      })

    }

    const unhideForumTag = async (tag) => {

      let hiddenTags = [...this.state.forum.hiddenTags]

      if (!hiddenTags.includes(tag))
        return null

      hiddenTags = hiddenTags.filter( item => item !== tag )

      await setStateAsync({
        forum: {
          ...this.state.forum,
          hiddenTags
        }
      })

      await selectForumTags(this.state.forum.selectedTags)

    }

    const hideForumTag = async (tag) => {

      const hiddenTags = [...this.state.forum.hiddenTags]

      if (hiddenTags.includes(tag))
        return null

      hiddenTags.push(tag)

      await setStateAsync({
        forum: {
          ...this.state.forum,
          hiddenTags
        }
      })

      await selectForumTags(this.state.forum.selectedTags)

    }

    const selectForumTag = async (tag) => {

      const availableTags = this.state.forum.availableTags
      const tagObject = availableTags.find(t => t.tag === tag)
      const tagsToBeSelected = [...this.state.forum.selectedTags]

      if (!tagObject)
        return null

      if (this.state.forum.selectedTags.includes(tag))
        return null

      let endLoop = true
      let parent = tagObject['parent']

      if (tagObject.parent) {

        while (endLoop) {

          let loopTag = availableTags.find( item => item.tag === parent )

          if (loopTag.parent === null)
            endLoop = false
          else
            parent = loopTag['parent']

          // check if tag is already selected

          if (!tagsToBeSelected.includes(loopTag.tag)) {
            tagsToBeSelected.push(loopTag.tag)
          }

        }

      }

      tagsToBeSelected.push(tagObject.tag)

      await selectForumTags(tagsToBeSelected)

    }

    const removeForumTag = async (tag) => {

      // remove tag and all children tags

      const availableTags = this.state.forum.availableTags
      const tagObject = availableTags.find(t => t.tag === tag)
      let selectedTags = [...this.state.forum.selectedTags]

      if (!tagObject)
        return null

      if (!tagObject.parent)
        return null

      let loopTags = availableTags.filter( item => item.parent === tagObject.tag )

      while (loopTags.length > 0) {

        if (loopTags[0] && loopTags[0].parent)
          for (const loopTag of loopTags) {
            selectedTags = selectedTags.filter( item => item !== loopTag.tag )
          }
        
        loopTags = availableTags.find( item => item.tag === loopTags[0].parent )

      }

      selectedTags = selectedTags.filter( item => item !== tag )

      await selectForumTags(selectedTags)

    }

    const addForumLike = async (messageId) => {

      let topics = [...this.state.forum.topics]
      let selectedThread = { ...this.state.forum.selectedThread }
      let like_status = false

      if (topics.find( item => item._id === messageId )) {
        like_status = topics.find( item => item._id === messageId ).liked_by_user
      }

      if ( selectedThread.message && selectedThread.message._id === messageId ) {
        like_status = selectedThread.message.liked_by_user
      } else if ( selectedThread.message && selectedThread.responses.find( item => item._id === messageId ) ) {
        like_status = selectedThread.responses.find( item => item._id === messageId ).liked_by_user
      }

      if (!like_status) {

        if (topics.find( item => item._id === messageId )) {

          topics = topics.map( item => {
            if (item._id === messageId) {
              item.likes_count += 1
              item.liked_by_user = true
            }
            return item
          })

        }
        
        if (selectedThread.message && selectedThread.message._id === messageId) {

          selectedThread.message.likes_count += 1
          selectedThread.message.liked_by_user = true
          
        } else if (selectedThread.message && selectedThread.responses.length > 0) {
          
          selectedThread.responses = selectedThread.responses.map( item => {
            if (item._id === messageId) {
              item.likes_count += 1
              item.liked_by_user = true
            }
            return item
          })

        }

      } else {

        if (topics.find( item => item._id === messageId )) {

          topics = topics.map( item => {
            if (item._id === messageId) {
              item.likes_count -= 1
              item.liked_by_user = false
            }
            return item
          })

        }
        
        if (selectedThread.message && selectedThread.message._id === messageId) {

          selectedThread.message.likes_count -= 1
          selectedThread.message.liked_by_user = false
          
        } else if (selectedThread.message && selectedThread.responses.length > 0) {
          
          selectedThread.responses = selectedThread.responses.map( item => {
            if (item._id === messageId) {
              item.likes_count -= 1
              item.liked_by_user = false
            }
            return item
          })

        }

      }

      this.setState({
        forum: {
          ...this.state.forum,
          topics,
          selectedThread
        }
      })

      const query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/forum/message/${messageId}/like`)

      if (query.status !== 200) {
        setGlobalError(true)
        return false
      }

    }

    const loadNextThreadsPage = async () => {

      await setStateAsync({
        forum: {
          ...this.state.forum,
          spinnerNextPageThreads: true
        }
      })

      const query_params = {}
      
      if (this.state.forum.topics_bookmark)
        query_params.bookmark = this.state.forum.topics_bookmark

      if (this.state.forum.hiddenTags.length > 0)
        query_params.hidden = this.state.forum.hiddenTags.join('+')

      const tags = this.state.forum.selectedTags

      const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/forum/topics/${tags.filter(t => !this.state.forum.hiddenTags.includes(t)).join('+')}`, query_params )

      if (!query.data.threads)
        return null

      if (query.data.threads.length > 0) {

        const bookmark = query.data.bookmark
        const topics = [...this.state.forum.topics, ...query.data.threads]

        await setStateAsync({
          forum: {
            ...this.state.forum,
            spinnerNextPageThreads: false,
            topics,
            topics_bookmark: bookmark
          }
        })

      } else {

        await setStateAsync({
          forum: {
            ...this.state.forum,
            spinnerNextPageThreads: false,
            topics_bookmark: 'end'
          }
        })

      }

    }

    const selectForumTags = async (tags) => {

      if (tags.length === 0) {

        this.setState({
          forum: {
            ...this.state.forum,
            selectedTags: [],
            hiddenTags: [],
            topics: [],
            topics_bookmark: null
          }
        })

        return null

      }

      await setStateAsync({
        forum: {
          ...this.state.forum,
          selectedTags: tags,
          spinnerThreadsList: true
        }
      })

      let topics = []
      let bookmark

      const query_params = {}

      if (this.state.forum.hiddenTags.length > 0)
        query_params.hidden = this.state.forum.hiddenTags.join('+')

      topics = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/forum/topics/${tags.filter(t => !this.state.forum.hiddenTags.includes(t)).join('+')}`, query_params )

      if (!topics.data.threads)
        return null

      bookmark = topics.data.bookmark
      topics = topics.data.threads

      await setStateAsync({
        forum: {
          ...this.state.forum,
          spinnerThreadsList: false,
          topics,
          topics_bookmark: bookmark
        }
      })

    }

    const selectMessageDiscussionMode = async (messageId) => {

      await setStateAsync({
        forum: {
          ...this.state.forum,
          selectedMessageDiscussionMode: messageId
        }
      })

    }

    const selectMessage = async (messageId) => {

      let thread = {}

      await setStateAsync({
        forum: {
          ...this.state.forum,
          spinnerThread: true
        }
      })

      try {

        thread = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/forum/message/${messageId}`)

        if (!thread.data) {
          setGlobalError(true)
          return null
        }

        thread = thread.data

        await setStateAsync({
          forum: {
            ...this.state.forum,
            selectedThread: thread,
            spinnerThread: false
          }
        })

      } catch (e) {
          
        console.error(e)

        await setStateAsync({
          forum: {
            ...this.state.forum,
            spinnerThread: false
          }
        })

        setGlobalError(true)

      }

    }

    const closeCurrentMessage = () => {

      this.setState({
        forum: {
          ...this.state.forum,
          selectedThread: {}
        }
      })

    }

    const cleanAllTreads = () => {

      this.setState({
        forum: {
          ...this.state.forum,
          selectedThread: {}
        }
      })

    }

    const postNewTopic = async (content) => {

      try {

        let tags = []
        const hiddenTags = this.state.forum.hiddenTags
        const availableTags = this.state.forum.availableTags

        // remove hidden tags from selected tags
        if (hiddenTags.length > 0)
          tags = this.state.forum.selectedTags.filter( item => !hiddenTags.includes(item) )
        else
          tags = this.state.forum.selectedTags
        
        // remove bot tags

        tags = tags.filter( item => !availableTags.find( tag => tag.tag === item ).is_bot )

        const queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/forum/topic`, { tags: tags.join('+'), content })

        if (queryResponse.status === 200) {
           await selectForumTags(tags)
           toggleNewTopic()
           return true
        } else {
          setGlobalError(true)
          return false
        }

      } catch (e) {

        console.error(e)
        setGlobalError(true)
        return false

      }

    }

    const postNewMessage = async (content, messageId) => {

      if (content.length === 0)
        return false

      if ( !this.state.forum.selectedThread.message._id && !this.state.forum.selectedThread.responses.find( item => item._id === messageId ) )
        return false

      try {

        const queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/forum/${messageId}/response`, { content })
       
        if (queryResponse.status === 200) {
          await selectForumTags(this.state.forum.selectedTags)
          await selectMessage(this.state.forum.selectedThread.message._id)
          return true
        } else {
          setGlobalError(true)
          return false
        }

      } catch (e) {

        console.error(e)
        setGlobalError(true)
        return false

      }

    }

    const toggleNotifications = (tabIndex) => {

      this.setState({
        notifications: {
          ...this.state.notifications,
          show: !this.state.notifications.show,
          tab: tabIndex || 0
        }
      })

    }

    const refreshUser = async () => {

      try {

        const queryResponse = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/user`, false, false, true)

        await setStateAsync({
          user: {
            ...this.state.user,
            loaded: true,
            wallet_value: queryResponse.data.wallet_value,
            wallet_evolution: queryResponse.data.wallet_evolution,
            cash: queryResponse.data.cash,
            notifications: queryResponse.data.notifications || [],
            forum_notifications: queryResponse.data.forum_notifications || [],
            transactions: queryResponse.data.transactions || [],
            bonus: queryResponse.data.bonus || 0,
            player_value: queryResponse.data.player_value || 0,
            available_spin: queryResponse.data.available_spin,
            available_spins: queryResponse.data.available_spins,
            ranks: queryResponse.data.ranks,
            last_notification_received: queryResponse.data.last_notification_received || null,
            last_notification_check: queryResponse.data.last_notification_check || null,
            accept_mail: queryResponse.data.accept_mail || false,
            username: queryResponse.data.username || '',
            email: queryResponse.data.email || '',
            waiting_deals_value: queryResponse.data.waiting_deals_value || 0,
            friend_code: queryResponse.data.friend_code || '',
            friends: queryResponse.data.friends || [],
            datetime_deletion: queryResponse.data.datetime_deletion || null,
            tags: queryResponse.data.tags || [],
            avatar: queryResponse.data.avatar || ''
          },
          deals: {
            ...this.state.deals,
            programs: queryResponse.data.deals || []
          }
        })

        return queryResponse

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const checkFriendCodeAvailability = async (friendCode) => {

      try {

        const queryResponse = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/check-friendcode-availability`, { friend_code: friendCode }, false, true)
        return queryResponse.data

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const updateFriendCode = async (friendCode) => {

      try {

        const queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/update-friendcode`, { friend_code: friendCode })
        
        if (queryResponse.status === 200)
          await refreshUser()
        else
          setGlobalError(true)

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const sendFriendInvitation = async (tag) => {

      try {

        const queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/send-friend-invitation`, { tag })
        
        if (queryResponse.status === 200)
          await refreshUser()
        else
          setGlobalError(true)

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const replyFriendInvitation = async (friendId, reply) => {

      try {

        const queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/reply-friend-invitation`, { friend_id: friendId, reply })
        
        if (queryResponse.status === 200)
          await refreshUser()
        else
          setGlobalError(true)

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const declineNotificationPermission = async () => {

      const notificationToken = localStorage.getItem('notification_token')

      if (notificationToken) {

        try {

          const query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/user/remove-notification-token`, { token: notificationToken })

          if (query.status !== 200) {
            setGlobalError(true)
            return
          }


        } catch (e) {

          console.error(e)
          setGlobalError(true)
          return

        } 

      }

      localStorage.setItem('notification_asked', 'declined')

      this.setState({
        user: {
          ...this.state.user,
          notification_status: 'declined' 
        }
      })

    }

    const requestNotificationPermission = async () => {

      let token
      const permission = await Notification.requestPermission()

      if (!("Notification" in window)) {
        setGlobalError(true, `Les notifications ne sont pas supportées sur votre appareil (1)`)
        return
      }

      if (!await firebase.isSupported()) {
        setGlobalError(true, `Les notifications ne sont pas supportées sur votre appareil (2)`)
        return
      }

      if (permission === 'granted') {

        try {

          const swRegistration = await navigator.serviceWorker.register('./service-worker.js')
          token = await firebase.getToken(firebase.messaging, { serviceWorkerRegistration: swRegistration, vapidKey: 'BJ7Y4fZDLRaMhNIAauM_J026Y0x5ey8pmdpCprIigvBX4dd5qvmL3GicnbS3AqXU8rY2nEMzHnAv_EsJzG8p1ls' })

        } catch (e) {

          console.error(e)
          setGlobalError(true, `Impossible d'activer les notifications sur votre appareil`)
          return

        }

        try {

          let query

          if (token)
            query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/user/add-notification-token`, { token })

          if (query.status !== 200)
            setGlobalError(true)
          else {

            localStorage.setItem('notification_asked', 'accepted')
            localStorage.setItem('notification_token', token)

            this.setState({
              user: {
                ...this.state.user,
                notification_status: 'accepted' 
              }
            })

          }

        } catch (e) {

          console.error(e)
          setGlobalError(true)

        }

      } else if (permission === 'denied') {

        setGlobalError(true, 'Accès aux notifications refusé par votre navigateur')

      }

    }

    const deleteAccount = async () => {

      try {

        let queryResponse

        if (this.state.user.datetime_deletion)
            queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/delete-account`, { cancel: true })
        else
            queryResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/delete-account`)
        
        if (queryResponse.status === 200)
          await refreshUser()
        else
          setGlobalError(true)

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

    }

    const spinPlayer = async () => {

      try {

        const spinResult = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/spin`, false, false, true)

        this.setState({
          user: {
            ...this.state.user,
            spinnedPlayer: spinResult.data.id
          },
        })

        return spinResult.data

      } catch (e) {

        console.error(e)
        setGlobalError(true)
        return false

      }

    }

    const refreshPlayersList = async () => {

      const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/players`)

      this.setState({
        players: {
          ...this.state.players,
          list: query.data.players,
          last_refresh: query.data.ts,
          market_is_open: query.data.market_is_open
        },
      })

    }

    const updateLastNotificationCheck = async () => {

      if (!this.state.user.last_notification_received)
        return null

      if (this.state.user.last_notification_check && this.state.user.last_notification_check >= this.state.user.last_notification_received)
        return null

      const query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/notification-check`)

      if (query.status === 200 && query.data && query.data.last_notification_check)
        this.setState({
          user: {
            ...this.state.user,
            last_notification_check: query.data.last_notification_check,
          }
        })

    }

    const toggleWallet = async (bool, tab) => {

      if (!tab)
        tab = 0

      this.setState({
        wallet: {
          ...this.state.wallet,
          show: bool,
          selectedTab: tab
        },
      });

    };

    const switchWalletTab = (event, newValue) => {
      this.setState({
        wallet: {
          ...this.state.wallet,
          selectedTab: newValue
        },
      });
    }

    const toggleBonus = (bool) => {

      this.setState({
        bonus: {
          ...this.state.bonus,
          show: bool
        },
      });

    }

    const loadPlayerHistory = async () => {

      const playerHistory = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/player/${this.state.player.id}/history`)
      
      let history = []

      if (playerHistory.status === 200 && playerHistory.data)
        history = playerHistory.data

      await setStateAsync({
        player: {
          ...this.state.player,
          history
        },
      })

    }

    const selectPlayer = async (playerId) => {

      if (!playerId) {

        this.setState({
          player: {
            ...this.state.player,
            id: null,
            open: false,
            history: [],
            buy_mode: false,
            sell_mode: false
          }
        })

        return null

      }

      // FETCH USER DATA ABOUT THIS PLAYER
      // ---

      await setStateAsync({
        player: {
          ...this.state.player,
          id: playerId,
          loading: true,
          open: true
        }
      })

      console.log(`${playerId.replace(':', '-')}.png`)

      await loadPlayerHistory()

      this.setState({
        player: {
          ...this.state.player,
          loading: false
        },
      });

    }

    const buyPlayer = async (playerId, quantity) => {

      this.setState({
        player: {
          ...this.state.player,
          orderInProgress: true
        }
      })

      const buyPlayerResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/player/:playerId/buy`, { quantity }, { playerId })
      const refreshUserResponse = await refreshUser()
      
      if ( buyPlayerResponse.status === 200 && refreshUserResponse.status === 200 ) {

        await refreshPlayersList(true)
        await loadPlayerHistory()

        this.setState({
          player: {
            ...this.state.player,
            orderInProgress: false,
            buy_mode: false,
          }
        })

        return true

      }

      return false

    }
    
    const sellPlayer = async (playerId, quantity, token) => {

      this.setState({
        player: {
          ...this.state.player,
          orderInProgress: true
        }
      })

      let buyPlayerResponse = null

      try {

        if (!token)
          buyPlayerResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/player/:playerId/sell`, { quantity }, { playerId })
        else
          buyPlayerResponse = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/player/:playerId/sell`, { quantity, token }, { playerId })
        
        const refreshUserResponse = await refreshUser()
        
        if ( buyPlayerResponse.status === 200 && refreshUserResponse.status === 200 ) {

          await refreshPlayersList(true)

          if (!token)
            await loadPlayerHistory()

          this.setState({
            player: {
              ...this.state.player,
              orderInProgress: false,
              sell_mode: false,
            }
          })

          return true

        }

      } catch (e) {

        console.error(e)
        setGlobalError(true)

      }

      return false

    }

    const toggleOrderMode = (mode) => {

      if (mode === 'buy') {

        this.setState({
          player: {
            ...this.state.player,
            buy_mode: true,
            sell_mode: false
          }
        })

      } else if (mode === 'sell') {

        this.setState({
          player: {
            ...this.state.player,
            buy_mode: false,
            sell_mode: true
          }
        })

      } else {

        this.setState({
          player: {
            ...this.state.player,
            buy_mode: false,
            sell_mode: false
          }
        })

      }

    }

    const refreshPronostics = async () => {

      const currentPronosticChallenges = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/pronostics`)

      if (currentPronosticChallenges.status !== 200 || !currentPronosticChallenges.data[0])
        return null

      const currentPronosticChallenge = currentPronosticChallenges.data[0]

      this.setState({
        pronostics: {
          ...this.state.pronostics,
          id: currentPronosticChallenge.id,
          name: currentPronosticChallenge.name,
          user_rank: currentPronosticChallenge.user_rank,
          partner_price: currentPronosticChallenge.partner_price,
          global_prices: currentPronosticChallenge.global_prices,
          matches: currentPronosticChallenge.matches
        }
      })

    }

    const addOrUpdateBet = async (pronosticId, match, choice) => {

      this.setState({
        pronostics: {
          ...this.state.pronostics,
          loading: true
        }
      })

      await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/pronostics/:pronosticId/add-bet`, { match, choice }, { pronosticId })

      this.setState({
        pronostics: {
          ...this.state.pronostics,
          loading: false
        }
      })

    }

    const updateAcceptMail = async (bool) => {
      await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/user`, { accept_mail: bool })
    }

    const updateUsername = async (username) => {
      await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/user`, { username })
    }

    const loadShopHome = async () => {

      if (this.state.shop.home.loading)
        return null

      await setStateAsync({
        shop: {
          ...this.state.shop,
          home: {
            ...this.state.shop.home,
            loading: true
          }
        }
      })

      const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/shop/home-products`)

      if (!query.data)
        return null

      const query_first_tab = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/shop/products/ball+tennis`)

      if (!query_first_tab.data)
        return null

      await setStateAsync({
        shop: {
          ...this.state.shop,
          home: {
            ...this.state.shop.home,
            list: query.data.slice(0, 2),
            loading: false
          },
          catalog: {
            ...this.state.shop.catalog,
            list: query_first_tab.data
          }
        }
      })

    }

    const loadShopProduct = async (productId) => {

      if (this.state.shop.product.loading)
        return null

      await setStateAsync({
        shop: {
          ...this.state.shop,
          product: {
            ...this.state.shop.product,
            id: productId,
            loading: true,
            show: true
          }
        }
      })

      const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/shop/product/${productId}`)

      await setStateAsync({
        shop: {
          ...this.state.shop,
          product: {
            ...this.state.shop.product,
            id: query.data._id,
            name: query.data.name,
            pictures: query.data.pictures,
            tags: query.data.tags,
            source: query.data.source,
            program_ref: query.data.program_ref,
            deals: [
              {
                program: query.data.program,
                logo: query.data.program_img,
                sizes: query.data.sizes,
                price: query.data.price,
                link: query.data.link
              }
            ],
            loading: false
          }
        }
      })

    }

    const closeProduct = () => {

      this.setState({
        shop: {
          ...this.state.shop,
          product: {
            ...this.state.shop.product,
            show: false,
            id: null,
            name: '',
            pictures: [],
            tags: [],
            deals: []
          }
        }
      })

    }

    const refreshShopCatalog = async (tags) => {

      if (this.state.shop.catalog.loading)
        return null

      await setStateAsync({
        shop: {
          ...this.state.shop,
          catalog: {
            ...this.state.shop.catalog,
            loading: true 
          }
        }
      })

      const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/shop/products/${tags}`)

      if (!query.data)
        return null

      await setStateAsync({
        shop: {
          ...this.state.shop,
          catalog: {
            ...this.state.shop.catalog,
            tags,
            list: query.data,
            loading: false
          }
        }
      })

    }

    const toggleShopCatalog = () => {

      this.setState({
        shop: {
          ...this.state.shop,
          catalog: {
            ...this.state.shop.catalog,
            show: !this.state.shop.catalog.show
          }
        }
      })

    }

    const switchCatalogTab = async (tab_index) => {

      await setStateAsync({
        shop: {
          ...this.state.shop,
          catalog: {
            ...this.state.shop.catalog,
            selectedTab: tab_index
          }
        }
      })

    }

    const toggleDeals = () => {

      this.setState({
        deals: {
          ...this.state.deals,
          show: !this.state.deals.show
        }
      })

    }

    const toggleLogoLoading = async () => {

      await setStateAsync({
        user: {
          ...this.state.user,
          logo_loading: !this.state.user.logo_loading
        }
      })

    }

    const refreshGifts = async () => {

      try {

        const query = await fetchGetURL(`${process.env.REACT_APP_API_BASE_URL}/gifts`)

        if (!query.data)
          return null

        await setStateAsync({
          gifts: {
            ...this.state.gifts,
            list: query.data.gifts,
            orders: query.data.orders
          }
        })

      } catch (e) {
        console.error(e)
        setGlobalError(true)
      }

    }

    const setGlobalError = (bool, message = `Une erreur s'est produite :'(`) => {

      this.setState({
        global_error: {
          ...this.state.global_error,
          show: bool,
          message
        }
      })

    }

    const buyGift = async (giftId) => {

      try {

        await setStateAsync({
          gifts: {
            ...this.state.gifts,
            buyingSpinner: true
          }
        })

        const query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/gift/${giftId}/buy`, { giftId })

        if (query.status === 200) {

          await refreshUser()
          await refreshGifts()

          this.setState({
            gifts: {
              ...this.state.gifts,
              buyingSpinner: false
            }
          })

          return true

        } else {

          setGlobalError(true)

          await setStateAsync({
            gifts: {
              ...this.state.gifts,
              buyingSpinner: false
            }
          })

          return false

        }

      } catch (e) {

        console.error(e)

        setGlobalError(true)

        await setStateAsync({
          gifts: {
            ...this.state.gifts,
            buyingSpinner: true
          }
        })

        return false

      }

    }

    const saveOrder = async (order) => {

      try {

        const payload = {
          shipping_name: order.shipping_name || '',
          shipping_address: order.shipping_address || '',
          shipping_city: order.shipping_city || '',
          shipping_postal_code: order.shipping_postal_code || '',
          shipping_size: order.shipping_size || '',
        }

        const query = await fetchPostURL(`${process.env.REACT_APP_API_BASE_URL}/orders/${order.id}/save-infos`, payload)

        if (query.status === 200)
          await refreshGifts()
        else
          setGlobalError(true)

      } catch (e) {

        setGlobalError(true)

      }

    }

    this.state = {
      global_error: {
        show: false,
        setTo: setGlobalError
      },
      user: {
        loaded: false,
        logo_loading: false,
        wallet_value: 0,
        wallet_evolution: 0,
        cash: 0,
        ranks: [],
        available_spin: false,
        available_spins: 0,
        spinnedPlayer: null,
        notifications: [],
        forum_notifications: [],
        transactions: [],
        bonus: 0,
        player_value: 0,
        last_notification_received: null,
        last_notification_check: null,
        username: '',
        email: '',
        tags: [],
        waiting_deals_value: 0,
        friend_code: '',
        friends: [],
        datetime_deletion: null,
        avatar: '',
        notification_status: localStorage.getItem('notification_asked') ? localStorage.getItem('notification_asked') : 'waiting',
        refresh: refreshUser,
        spin: spinPlayer,
        sellSpin: sellPlayer,
        updateLastNotificationCheck: updateLastNotificationCheck,
        updateAcceptMail,
        updateUsername,
        toggleLogoLoading,
        checkFriendCodeAvailability,
        updateFriendCode,
        sendFriendInvitation,
        replyFriendInvitation,
        deleteAccount,
        requestNotificationPermission,
        declineNotificationPermission
      },
      wallet: {
        show: false,
        selectedTab: 0,
        toggle: toggleWallet,
        switchTab: switchWalletTab
      },
      bonus: {
        show: false,
        toggle: toggleBonus
      },
      player: {
        id: '',
        history: [],
        loading: false,
        open: false,
        buy_mode: false,
        sell_mode: false,
        switch_mode: toggleOrderMode,
        orderInProgress: false,
        select: selectPlayer,
        buy: buyPlayer,
        sell: sellPlayer
      },
      shop: {
        home: {
          loading: false,
          list: [],
          load: loadShopHome
        },
        catalog: {
          show: false,
          loading: false,
          tags: [],
          list: [],
          selectedTab: 0,
          switchTab: switchCatalogTab,
          refreshTag: refreshShopCatalog,
          toggle: toggleShopCatalog
        },
        product: {
          show: false,
          loading: false,
          load: loadShopProduct,
          close: closeProduct,
          id: null,
          name: '',
          pictures: [],
          tags: [],
          deals: [],
          source: '',
          program_ref: ''
        }
      },
      players: {
        list: [],
        last_refresh: null,
        market_is_open: false,
        refresh: refreshPlayersList
      },
      pronostics: {
        id: "",
        name: "",
        user_rank: [],
        partner_price: {
          name_in_rank: "",
          name: "",
          url: "",
          url_label: "",
          description: "",
          img: ""
        },
        global_prices: {},
        matches: [],
        refresh: refreshPronostics,
        addOrUpdateBet: addOrUpdateBet,
        loading: false
      },
      deals: {
        show: false,
        programs: [],
        toggle: toggleDeals
      },
      gifts: {
        list: [],
        orders: [],
        buyingSpinner: false,
        refresh: refreshGifts,
        buy: buyGift,
        buyInProgress: false,
        saveOrder: saveOrder
      },
      notifications: {
        show: false,
        tab: 0,
        toggle: toggleNotifications
      },
      forum: {
        loadConfig: loadForumConfig,
        show: false,
        showNewTopic: false,
        toggle: toggleForum,
        toggleNewTopic: toggleNewTopic,
        rootTags: [],
        availableTags: [],
        selectedTags: [],
        hiddenTags: [],
        selectTags: selectForumTags,
        selectTag: selectForumTag,
        hideTag: hideForumTag,
        unhideTag: unhideForumTag,
        removeTag: removeForumTag,
        topics: [],
        selectedThread: [],
        selectedMessageDiscussionMode: null,
        selectMessageDiscussionMode: selectMessageDiscussionMode,
        selectMessage: selectMessage,
        closeCurrentMessage: closeCurrentMessage,
        cleanAllTreads: cleanAllTreads,
        postNewTopic: postNewTopic,
        postNewMessage: postNewMessage,
        loadNextThreadsPage: loadNextThreadsPage,
        addLike: addForumLike
      }
    };

  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }

}

export { Provider as AppContextProvider };
