import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../config/theme'

const ColorButton = styled(Button)(({ theme }) => ({
  fontFamily:'Poppins-Bold',
  backgroundColor:`#f0bc3d!important`,
  fontSize:15,
  borderRadius:23,
  height:35,
  color:'#333',
  letterSpacing:-0.5,
  paddingRight:25,
  paddingLeft:25,
  marginTop:3,
  marginBottom:3
}))

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <ColorButton {...props} >
      { props.children }
    </ColorButton>
  </ThemeProvider>

export default App