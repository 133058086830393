import { Component } from 'react'
import { SwipeableDrawer } from '@mui/material'
import { IosShare as AddIcon } from '@mui/icons-material'
import ios from '../asset/image/ios.png'

class IosInstall extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render = () => {

    const InstallDetails = (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.props.show }
        onClose={ this.props.close }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={ this.props.close } style={{padding:90}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', textTransform:'uppercase', fontWeight:'bold', letterSpacing:-1}}>
            <div style={{fontSize:'24px', fontFamily:'Poppins-Bold'}}>Installer l'application</div>
            <div style={{fontSize:'16px', lineHeight:'21px', marginTop:15, letterSpacing:-0.5}}>Installez cette application sur votre écran d'accueil ios pour y accéder plus rapidement</div>
            <div style={{fontSize:'14px', lineHeight:'21px', marginTop:35, letterSpacing:0}}>Ouvrez https://fr.tennisempire.app avec Safari</div>
            <div style={{fontSize:'14px', lineHeight:'21px', marginTop:15, letterSpacing:0}}>Appuyez sur <AddIcon /> en bas de votre écran puis "Sur l'écran d'accueil"</div>
            <div style={{textAlign:'center', marginTop:35}}><img width={'80%'} src={ios} /></div>
          </div>
        </div>
      </SwipeableDrawer>
    )

    return InstallDetails

  }

}

export default IosInstall
