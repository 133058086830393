import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: 'AIzaSyCOqmP4WIw0McY77jtfWynXN44dhdreLp0',
  authDomain: 'tennis-empire.firebaseapp.com',
  projectId: 'tennis-empire',
  storageBucket: 'tennis-empire.appspot.com',
  messagingSenderId: '449648414498',
  appId: '1:449648414498:web:6107ceecb16690b618df7b',
}

const output = { messaging: null, getToken: null }

try {

  const app = initializeApp(firebaseConfig)
  output.isSupported = isSupported
  output.messaging = getMessaging(app)
  output.getToken = getToken

} catch (e) {}

export default output
