import { Component } from 'react'
import { Container } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'


class Product extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  translateTag = (tag) => {

    const transalations = {
      'ball': 'Balles',
      'racket': 'Raquette',
      'shoes': 'Chaussure',
      'clothing': 'Textile',
      'bag': 'Sacs',
      'cap': 'Casquette',
      'antivibrator': 'Antivibrateur',
      'string': 'Cordage',
      'men': 'Homme',
      'women': 'Femme',
      'girls': 'Fille',
      'boys': 'Garçon',
      'accessory': 'Accessoire',
      'wrist': 'Poignet',
      'headband': 'Bandeau',
      'tank_top': 'Débardeur',
      'socks': 'Chaussettes',
      'long_sleeve_top': 'Haut manches longues',
      'bra': 'Soutien-gorge',
      'overgrip': 'Surgrip',
      'skirt': 'Jupe',
      'tracksuit': 'Survêtement',
      'pants': 'Pantalon',
      'cropped_pants': 'Pantacourt',
      'jacket': 'Veste',
      'vest': 'Gilet',
      'underwear': 'Sous-vêtement',
      'visor': 'Visière',
      'towel': 'Serviette',
      'gourd': 'Gourde',
      'ankle_brace': 'Chevillère'
    }

    if (transalations[tag])
      return transalations[tag]

    return tag

  }

  spinNumber = (product, programs, price) => {

    if (!programs)
      return null

    const program = programs.find(program => program.source === product.source && program.ref === product.program_ref)

    if (!program)
      return null

    let output = Math.floor(price / program.deal_value_ttc)
    
    if (output < 1)
      output = 1

    if (output == 1)
      return `+${output} spin`

    return `+${output} spins`

  }

  render = () => {

    if (this.props.product.loading)
      return (
        <Container component="main" maxWidth="xs" style={{padding:0, textAlign:'center'}}>
          <CircularProgress style={{marginTop:100, marginBottom:100, color:'#666'}} size={64} />
        </Container>
      )

    const tags = []

    this.props.product.tags.map((tag, index) => {
      tags.push(
        <span style={{backgroundColor:'#444', borderRadius:4, margin:2, textTransform:'uppercase', fontSize:13, padding:5, paddingLeft:7, paddingRight:7, color:'#FFF', whiteSpace:'nowrap'}}>{ this.translateTag(tag) }</span>
      )
    })

    const spinNumber = this.spinNumber(this.props.product, this.props.deals, this.props.product.deals[0].price)

    const serializedName = this.props.product.name.replace('-', '').replace(/\s{1,}/g, '-').toLowerCase()

    return (
      <Container component="main" maxWidth="xs" style={{padding:0}}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

          <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:22, lineHeight:1, marginBottom:10, letterSpacing:-1.1, marginTop:15 }}>{ this.props.product.name }</div>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginBottom:25}}>
            { tags }
          </div>

          <div style={{ textAlign:'center' }}>
            { this.props.product.pictures.length > 0 && <div style={{width:'calc(100% - 40px)', padding:20, backgroundColor:'#FFF', borderRadius:15, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}><img style={{width:'100%'}} src={this.props.product.pictures[0]} /></div>}
          </div>
          
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'20px', color:'#999', marginTop:50, paddingBottom:3, marginBottom:10, borderBottom:'solid 3px #DDD', textAlign:'center' }}>deals disponibles</div>

          <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:16, color:'#555', lineHeight:1, marginBottom:15, marginTop:5 }}>récupérez des spins bonus avec les deals Tennis Empire</div>

          { this.props.product.deals.length > 0 && <div onClick={() => window.open(`${this.props.product.deals[0].link}&pref1=user_${localStorage.getItem('token').split('.')[0]}&pref2=${serializedName}`)} style={{ borderRadius:7, marginBottom:15, padding:15, display:'flex', backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
            <div style={{marginRight:10, display:'flex', alignItems: 'center'}}>
              <img style={{height:65, borderRadius:'50%'}} src={ this.props.product.deals[0].logo } />
            </div>
            <div style={{width:'100%'}}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:24 }}>{ this.props.product.deals[0].price.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) }</div>
                <ArrowForwardIos />
              </div>

              <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#555', lineHeight:1, fontSize:14, marginTop:5 }}>{ this.props.product.deals[0].program }</div>
            
              <div style={{fontFamily:'Poppins-Bold', marginTop:5, display:'flex', justifyContent:'space-between'}}>
                <div>
                  {/* <span style={{backgroundColor:'#666', borderRadius:4, marginRight:3, textTransform:'uppercase', fontSize:14, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>M</span>
                  <span style={{backgroundColor:'#666', borderRadius:4, marginRight:3, textTransform:'uppercase', fontSize:14, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>L</span>
                  <span style={{backgroundColor:'#666', borderRadius:4, marginRight:3, textTransform:'uppercase', fontSize:14, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>XL</span> */}
                </div>
                <div>
                  { spinNumber
                      && <span style={{backgroundColor:'#333', borderRadius:4, marginRight:3, textTransform:'uppercase', fontSize:14, padding:3, paddingRight:7, paddingLeft:7, color:'#f0bc3d'}}>{ spinNumber }</span>
                  }
                </div>
              </div>
            
            </div>
          </div>}
        </div>
      </Container>
    )

  }

}

export default Product;
