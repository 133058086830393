import { Component } from 'react'
import { SwipeableDrawer } from '@mui/material'
import { localDatetime } from '../utils/common'
import { ChevronRight, Favorite, ChatBubble, ArrowForwardIos, Settings as SettingsIcon, ArrowDownward, Delete, Add, FormatQuote, Reply, Visibility, VisibilityOff } from '@mui/icons-material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Button from '../component/button'
import TextField from '@mui/material/TextField'
import { awaitSetState } from '../utils/common'
import CircularProgress from '@mui/material/CircularProgress'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
})

class ForumScreen extends Component {

  constructor (props) {

    super(props)

    this.state = {
      showNewMessage: false,
      showTagsSelection: false,
      newTopicContent: '',
      searchTag: '',
      messageToReply: null,
      postingNewMessage: false,
    }
   
  }

  setTopicContent = (event) => {

    let content = event.target.value

    // remove new lines
    content = content.replace(/(\r\n|\n|\r)/gm, " ")

    // remove links
    content = content.replace(/(https?:\/\/[^\s]+)/g, '')

    if (content.length > 220)
      content = content.substring(0, 220)

    this.setState({ newTopicContent: content })

  }

  postNewTopic = async () => {

    if (this.state.newTopicContent.length === 0)
      return

    await awaitSetState({ postingNewMessage: true }, this)

    const isSuccess = await this.props.forum.postNewTopic(this.state.newTopicContent)

    if (isSuccess)
      this.setState({ newTopicContent: '', postingNewMessage: false })
    else
      this.setState({ postingNewMessage: false })

  }

  postNewMessage = async () => {

    if (this.state.newTopicContent.length === 0)
      return

    await awaitSetState({ postingNewMessage: true }, this)

    const isSuccess = await this.props.forum.postNewMessage(this.state.newTopicContent, this.state.messageToReply)

    if (isSuccess) {
      this.toggleNewMessage()
      this.setState({ newTopicContent: '', postingNewMessage: false })      
    } else {
      this.setState({ postingNewMessage: false })
    }

  }

  toggleDiscussionMode = async (messageId) => {

    if (!messageId)
      await this.props.forum.selectMessageDiscussionMode(null)
    else
      await this.props.forum.selectMessageDiscussionMode(messageId)

    this.getDiscussionMessages()

  }

  getDiscussionMessages = () => {

    const messageId = this.props.forum.selectedMessageDiscussionMode
    const messageIds = []

    if (!messageId)
      return null

    if (!this.props.forum.selectedThread) {
      this.props.forum.selectMessageDiscussionMode(null)
      return null
    }

    messageIds.push(messageId)

    const threadMessages = this.props.forum.selectedThread.responses

    if (!threadMessages)
      return null

    const message = threadMessages.find( item => item._id === messageId )

    // Get all parent messages

    let messageCursor = message

    while (messageCursor && messageCursor.quote && messageCursor.quote._id) {

      const parentMessage = threadMessages.find( item => item._id === messageCursor.quote._id )

      if (parentMessage) {
        messageIds.push(parentMessage._id)
      }

      messageCursor = parentMessage

    }

    // Get all children messages

    messageCursor = message
    let messagesToCheck = [ message ]

    while (messagesToCheck.length > 0) {

      for (const messageCursor of messagesToCheck) {

        // Get all messages where the current message is quoted
        const childrenMessages = threadMessages.filter( item => item.quote && item.quote._id === messageCursor._id )

        // Remove the current message from messagesToCheck
        messagesToCheck = messagesToCheck.filter( item => item._id !== messageCursor._id )
        
        for (const childMessage of childrenMessages) {
          messageIds.push(childMessage._id)
        }

        if (childrenMessages.length > 0)
          messagesToCheck = [...messagesToCheck, ...childrenMessages]

      }

    }

    return messageIds

  }

  closeThread = async () => {

    await this.props.forum.selectMessageDiscussionMode(null)
    this.props.forum.cleanAllTreads()

  }

  renderRootCategories = () => {

    const rootTags = this.props.forum.rootTags
    const output = []
    const categoriesList = []
    let categoryContent

    for (const rootTag of rootTags)
      if (!categoriesList.includes(rootTag.category))
        categoriesList.push(rootTag.category)

    for (const category of categoriesList) {

      categoryContent = rootTags.filter( rootTag => rootTag.category === category )

      output.push(
        <>
          <div style={{ fontFamily:'Poppins-Bold', letterSpacing:1, textAlign:'center', fontSize:24, marginTop:20, color:'#999', marginBottom:5, textTransform:'uppercase' }}>{ category }</div>
        </>
      )

      for (const content of categoryContent) {

        const topicTags = []

        for (const tag of content.tags)
          topicTags.push(
            <div style={{ fontFamily:'Poppins-Bold', marginRight:5, fontSize:14, padding:2, paddingLeft:7, paddingRight:7, borderRadius:5, backgroundColor:'#CCC' }}>{ tag }</div>
          )

        output.push(
          <div onClick={ async () => { this.props.forum.selectTags( content.tags ) } } style={{ display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'#FFF', borderRadius:10, padding:15, paddingLeft:15, paddingRight:0, marginBottom:10, paddingTop:20, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)' }}>
            <div style={{ display:'flex', flexDirection:'column', overflow: 'hidden' }}>
              <div style={{ fontSize:22, color:'#000', lineHeight:1, fontFamily:'Poppins-Bold' }}>{ content.label }</div>
              <div style={{ fontSize:17, color:'#666', lineHeight:1.1, fontFamily:'Poppins-Bold', marginTop:7 }}>{ content.description }</div>
              <div style={{ display:'flex', marginTop:10, marginBottom:5 }}>{ topicTags }</div>
              {
                content.last_author && <div style={{ fontSize:14, color:'#666', marginTop:3, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{content.messages_count ? content.messages_count : 0} messages - { content.last_updated && localDatetime(content.last_updated) } - {content.last_author && content.last_author}</div>
              }
            </div>
            <div style={{ display:'flex', alignItems:'center' }}>
              <ChevronRight style={{fontSize:42}} />
            </div>
          </div>
        )

      }

    }

    return (
      <div style={{display:'flex', flexDirection:'column'}}>
        {output}
      </div>
    )

  }

  renderTagsBar = (hideHidden) => {

    const filters = []

    for (const tag of this.props.forum.selectedTags) {

      const isHidden = this.props.forum.hiddenTags.includes(tag)

      if (isHidden && hideHidden)
        continue

      if ( hideHidden && this.props.forum.availableTags.find(t => t.tag === tag).is_bot )
        continue

      filters.push(
        <div style={{ fontFamily:'Poppins-Bold', textDecoration:isHidden ? 'line-through' : 'none', margin:3, fontSize:15, padding:2, paddingLeft:7, paddingRight:7, borderRadius:5, backgroundColor:'#CCC', whiteSpace:'nowrap' }}>{ tag }</div>
      )

    }

    return (<div onClick={ () => { this.setState({showTagsSelection: true, searchTag: ''}) } } style={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row', backgroundColor:'#FFF', border: 'solid 1px #DDD', borderRadius:10, padding:10, marginBottom:15 }}>
      <div style={{ display:'flex', flexDirection:'row', flexWrap: 'wrap' }}>
        { filters }
      </div>
      <div style={{ display:'flex', alignItems:'center' }}>
        <SettingsIcon style={{fontSize:28}} />
      </div>
    </div>)

  }

  renderTopicsList = () => {

    const topicsList = this.props.forum.topics
    const topics = []

    for (const topic of topicsList)
      topics.push( this.renderMessage(topic) )

    return <SwipeableDrawer
      anchor={ 'bottom' }
      open={ this.props.forum.selectedTags.length > 0 || this.props.forum.spinnerThreadsList }
      onClose={ () => { this.props.forum.selectTags([]) } }
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div onClick={ () => { this.props.forum.selectTags([]) } } style={{padding:60}}></div>
      <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
        { this.renderTagsBar() }
        { this.props.forum.spinnerThreadsList
          ? <div style={{textAlign:'center', marginTop:100, marginBottom:100}}>
              <CircularProgress size={52} style={{marginTop:5, color:'#666'}} />
            </div>
          : <>
              { (this.props.user.tags.includes('admin') || this.props.forum.selectedTags.filter(t => this.props.forum.availableTags.find(at => at.tag === t).admin_only) === 0)
                && <div style={{marginTop:20, marginBottom:20, display:'flex', justifyContent:'center'}}>
                  <Button onClick={ this.props.forum.toggleNewTopic }>nouveau fil</Button>
                </div> }
              { topics.length > 0 ? topics : <div style={{textAlign:'center', fontSize:22, color:'#999', fontFamily:'Poppins-Bold', textTransform:'uppercase', marginTop:25, marginBottom:25}}>Aucun fil trouvé</div> }
              { this.props.forum.topics_bookmark !== 'end' && topics.length >= 20 && <>{ !this.props.forum.spinnerNextPageThreads 
                ? <div style={{marginTop:20, marginBottom:20, display:'flex', justifyContent:'center'}}>
                    <Button onClick={ this.props.forum.loadNextThreadsPage }>charger la suite</Button>
                  </div>
                : <div style={{textAlign:'center', marginTop:25, marginBottom:25}}>
                    <CircularProgress size={42} style={{marginTop:5, color:'#666'}} />
                  </div>
              }</> }
            </>
        }
      </div>
    </SwipeableDrawer>

  }

  renderUserTag = (tag) => {

    const colors = {
      'admin': '#fe9dae',
      'vip': '#fbdb8b',
      'vip+': '#fbdb8b',
      'bot': '#ccc'
    }

    if (!colors[tag])
      return null

    const color = colors[tag]

    return (
      <div style={{ fontFamily:'Poppins-Bold', marginRight:5, fontSize:12, padding:2, paddingLeft:7, paddingRight:7, borderRadius:5, backgroundColor:color }}>{ tag }</div>
    )

  }

  renderMessage = (message, disableButtons) => {

    const topicTags = []
    const userTags = []

    let meta = []

    if (message.tags)
      for (const tag of message.tags) {
        let bkcolor = "#DDD"
        topicTags.push(
          <div onClick={() => { this.props.forum.selectTag( tag ) }} style={{ fontFamily:'Poppins-Bold',  whiteSpace:'nowrap', marginRight:5, marginTop:5, fontSize:13, padding:2, paddingLeft:7, paddingRight:7, borderRadius:5, backgroundColor:bkcolor, color:'#666' }}>{ tag }</div>
        )
      }

    if (message.author.tags)
      for (const tag of message.author.tags)
        userTags.push(
          this.renderUserTag(tag)
        )

    meta = []

    if (message.meta.length > 0) {

      const link = message.meta.find( item => item.type === 'link' )

      if (link && link.image)
        meta.push(
          <a href={link.url} style={{textDecoration:'none'}} target='_blank'>
            <div style={{ display:'flex', marginTop:20 }}>
              <div>
                <div style={{height:54, width:54, borderTopLeftRadius:7, borderBottomLeftRadius:7, backgroundImage:`url(${link.image})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}></div>
              </div>
              <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', overflow: 'hidden', padding:7, backgroundColor:'#DDD', borderTopRightRadius:7, borderBottomRightRadius:7 }}>
                <div style={{ overflow: 'hidden' }}>
                  <div style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:15, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{ link.title }</div>
                  <div style={{ fontFamily:'Poppins-Bold', color:'#999', fontSize:13, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginTop:-3 }}>{ link.url }</div>
                </div>
                <div style={{ display:'flex', alignItems:'center' }}>
                  <ArrowForwardIos style={{fontSize:28, color:'#000'}} />
                </div>
              </div>
            </div>
          </a>
        )
      else if (link)
        meta.push(
          <a href={link.url} style={{textDecoration:'none'}} target='_blank'>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, padding:7, backgroundColor:'#DDD', borderRadius:7 }}>
              <div style={{ overflow: 'hidden' }}>
                <div style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:15, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{ link.title }</div>
                <div style={{ fontFamily:'Poppins-Bold', color:'#999', fontSize:13, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginTop:-3 }}>{ link.url }</div>
              </div>
              <div style={{ display:'flex', alignItems:'center' }}>
                <ArrowForwardIos style={{fontSize:28, color:'#000' }} />
              </div>
            </div>
          </a>
      )
      
    }

    if (topicTags.length > 0) {

      return (
        <div style={{ backgroundColor:'#FFF', border: 'solid 1px #DDD', borderRadius:10, padding:15, paddingBottom:10, marginBottom:15 }}>
          <div style={{ display:'flex', alignItems:'center', marginBottom:10 }}>
            <div style={{ marginRight:7 }}>
              {
                message.author.avatar
                ? <div style={{ height:35, width:35, borderRadius:'50%', backgroundImage:`url(${message.author.avatar})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat' }}></div>
                : <div style={{height:35, width:35, borderRadius:'50%', backgroundColor:'#DDD'}}></div>
              }
            </div>
            <div style={{ marginRight:10 }}>
              <span style={{ fontSize:18, fontFamily:'Poppins-Bold' }}>{ message.author.username }</span>
            </div>
            <div style={{ display:'flex' }}>
              { userTags }
            </div>
          </div>
          <div onClick={ () => { if (!disableButtons) { this.props.forum.selectMessage( message._id ) } } } style={{ fontFamily:'Chirp', color:'#000', fontSize:20, lineHeight:1.2, paddingTop:5 }}>
            { message.content }
          </div>
          { meta }
          <div style={{ display:'flex', flexWrap:'wrap', marginTop:15 }}>
            { topicTags }
          </div>
          <div style={{ display:'flex', justifyContent:'space-between', marginTop:15 }}>
            <div onClick={ () => { if (!disableButtons) { this.props.forum.selectMessage( message._id ) } } } style={{ color:'#999' }}>
              <div style={{ fontSize:14, fontFamily:'Poppins-Bold' }}>{ localDatetime(message.last_updated) }</div>
              <div style={{ fontSize:14, marginTop:-5 }}>dernière activité du fil</div>
            </div>
            <div style={{ fontFamily:'Poppins-Bold', display:'flex', alignItems:'center' }}>
              <div onClick={ () => { this.props.forum.addLike(message._id) } } style={{ marginRight:12, display:'flex', alignItems:'center', color:( message.liked_by_user ? '#f91880' : '#CCC' ) }}>
                <Favorite style={{ fontSize:28 }} />
                { message.likes_count }
              </div>
              <div onClick={ () => { if (!disableButtons) { this.props.forum.selectMessage( message._id ) } } } style={{ display:'flex', alignItems:'center', color:( message.reponses_count > 0 ? '#1d9bf0' : '#CCC') }}>
                <ChatBubble style={{ fontSize:28, marginRight:3 }} />
                { message.reponses_count }
              </div>
            </div>
          </div>
        </div>
      )

    }

    const responses_length = this.props.forum.selectedThread.responses.filter(m => m.quote && m.quote._id === message._id).length

    return (
      <div style={{ backgroundColor:'#FFF', border: 'solid 1px #DDD', borderRadius:10, padding:15, paddingBottom:10, marginBottom:15 }}>
        <div style={{ display:'flex', alignItems:'center', marginBottom:10 }}>
          <div style={{ marginRight:7 }}>
            {
              message.author.avatar
              ? <div style={{ height:35, width:35, borderRadius:'50%', backgroundImage:`url(${message.author.avatar})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat' }}></div>
              : <div style={{height:35, width:35, borderRadius:'50%', backgroundColor:'#DDD'}}></div>
            }
          </div>
          <div style={{ marginRight:10 }}>
            <span style={{ fontWeight:'bold', fontSize:18, fontFamily:'Poppins-Bold' }}>{ message.author.username }</span>
          </div>
          <div style={{ display:'flex' }}>
            { userTags }
          </div>
        </div>
        <div>
          { message.quote &&
              <div onClick={ () => { this.toggleDiscussionMode(message._id) } } style={{ position:'relative', fontFamily:'Chirp', backgroundColor:'#f0f0f0', fontSize:18, lineHeight:1, border: 'solid 1px #DDD', borderRadius:7, padding:8, marginBottom:10, color:'#555' }}>
                <div style={{ position:'absolute', right:0, top:-20 }}> <FormatQuote style={{ fontSize:38 }} /> </div>
                <div>{ message.quote.content }</div>
                <div style={{ textAlign:'right', fontSize:14, marginTop:5, color:'#999', fontFamily:'Poppins-Bold' }}>{ message.quote.author }</div>
              </div>
          }
        </div>
        <div onClick={ () => { if (!disableButtons) { this.toggleNewMessage(message._id) } } } style={{ fontFamily:'Chirp', color:'#000', fontSize:20, lineHeight:1.2, paddingTop:5 }}>
          { message.content }
        </div>
        { meta }
        <div style={{ display:'flex', justifyContent:'space-between', marginTop:15 }}>
          { responses_length > 0
            ? <div onClick={ () => { this.toggleDiscussionMode(message._id) } } style={{ color:'#999' }}>
              <div style={{ fontSize:14, fontFamily:'Poppins-Bold' }}>{ localDatetime(message.last_updated) }</div>
              <div style={{ fontSize:14, marginTop:-5 }}>{responses_length} réponse{ responses_length > 1 && 's' } - Voir les réponses</div>
            </div>
            : <div style={{ display:'flex', color:'#999', alignItems:'flex-end' }}>
              <div style={{ fontSize:14, fontFamily:'Poppins-Bold' }}>{ localDatetime(message.created) }</div>
            </div>
          }
          <div style={{ fontFamily:'Poppins-Bold', display:'flex', alignItems:'center' }}>
            <div onClick={ () => { this.props.forum.addLike(message._id) } } style={{ marginRight:12, display:'flex', alignItems:'center', color:( message.liked_by_user ? '#f91880' : '#CCC' ) }}>
              <Favorite style={{ fontSize:28 }} />
              { message.likes_count }
            </div>
            <div onClick={ () => { if (!disableButtons) { this.toggleNewMessage(message._id) } } } style={{ display:'flex', alignItems:'center', color:( message.reponses_count > 0 ? '#1d9bf0' : '#CCC') }}>
              <Reply style={{ transform: 'scaleX(-1)', fontSize:32 }} />
            </div>
          </div>
        </div>
      </div>
    )

  }

  renderResponse = (message, responses_length, position) => {

    let meta = []

    if (message.meta.length > 0) {

      const link = message.meta.find( item => item.type === 'link' )

      if (link)
        meta.push(
          <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:10, padding:7, backgroundColor:'#DDD', borderRadius:7 }}>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:15, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{ link.title }</div>
              <div style={{ fontFamily:'Poppins-Bold', color:'#999', fontSize:13, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginTop:-3 }}>{ link.url }</div>
            </div>
            <div style={{ display:'flex', alignItems:'center' }}>
              <ArrowForwardIos style={{fontSize:28}} />
            </div>
          </div>
        )
      
    }

    const indicators_count = responses_length - position + 1

    const indicators = []

    for (let i = 0; i < indicators_count; i++) {
      const marginLeft = ( position - 1 )  * 10
      indicators.push(
        <div style={{ width:3, height:17, backgroundColor:'#666', left:(25+marginLeft+i*10), top:-17, position:'absolute', zIndex:1 }}></div>
      )      
    }

    return (
      <div onClick={() => { this.props.forum.selectMessage( message._id ) }} style={{ position:'relative', backgroundColor:'#FFF', border: 'solid 1px #DDD', borderRadius:10, padding:15, paddingBottom:10, marginBottom:15, marginLeft:25 }}>
        { indicators }
        <div style={{ display:'flex', alignItems:'center', marginBottom:10 }}>
          <div style={{ marginRight:5 }}>
            <div style={{height:25, width:25, borderRadius:'50%', backgroundColor:'#CCC'}}></div>
          </div>
          <div style={{ marginRight:10 }}>
            <span style={{ fontWeight:'bold', fontSize:15, fontFamily:'Poppins-Bold' }}>{ message.author.username }</span>
          </div>
          <div>
            <span style={{ fontFamily:'Poppins-Bold', fontSize:11, padding:2, paddingLeft:5, paddingRight:5, borderRadius:5, backgroundColor:'#CCC' }}>admin</span>
          </div>
        </div>
        <div style={{ fontFamily:'Poppins-Regular', fontWeight:'bold', color:'#333', fontSize:16, lineHeight:1.3 }}>
          { message.content }
        </div>
        { meta }
        <div style={{ display:'flex', justifyContent:'space-between', marginTop:15 }}>
          <div style={{ color:'#999' }}>
            <div style={{ fontSize:14, fontFamily:'Poppins-Bold' }}>{ localDatetime(message.last_updated) }</div>
            <div style={{ fontSize:13, marginTop:-5 }}>dernière activité du fil</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', display:'flex', alignItems:'center' }}>
            <div onClick={ () => { this.props.forum.addLike(message._id) } } style={{marginRight:12, display:'flex', alignItems:'center', fontSize:14, color:( message.liked_by_user ? '#f91880' : '#CCC' ) }}>
              <Favorite style={{ fontSize:24 }} />
              { message.likes_count }
            </div>
            <div style={{display:'flex', alignItems:'center', fontSize:14, color:( message.reponses_count > 0 ? '#1d9bf0' : '#CCC') }}>
              <FormatQuote style={{ fontSize:32 }} />
              { message.reponses_count }
            </div>
          </div>
        </div>
      </div>
    )

  }

  renderThread = () => {

    const thread = this.props.forum.selectedThread
    let threadResponses = thread.responses ? thread.responses : []
    const responses = []

    if (this.props.forum.selectedMessageDiscussionMode) {

      const messagesToShow = this.getDiscussionMessages()
      threadResponses = threadResponses.filter( item => messagesToShow.includes(item._id) )

    }

    if ( threadResponses.length > 0 ) {
      for (const response of threadResponses) {
        responses.push(
          this.renderMessage(response)
        )
      }
    }

    return <SwipeableDrawer
      anchor={ 'bottom' }
      open={ this.props.forum.selectedThread.message || this.props.forum.spinnerThread }
      onClose={ this.closeThread }
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div onClick={ this.closeThread } style={{padding:90}}></div>
      <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
        { !this.props.forum.spinnerThread
          ? <div>
              { this.props.forum.selectedThread.message && this.renderMessage(this.props.forum.selectedThread.message, true) }
              <div style={{marginTop:15, marginBottom:15, display:'flex', justifyContent:'center'}}>
                <Button onClick={ () => { this.toggleNewMessage(thread.message._id) } }>répondre à ce fil</Button>
              </div>
              <div style={{ display:'flex', color:'#888', fontSize:18, fontFamily:'Poppins-Bold', justifyContent:'center', marginBottom:15 }}>
                { responses.length === 0 && "aucune réponse dans ce fil" }
              </div>
              <div>
                { this.props.forum.selectedMessageDiscussionMode && <div style={{ textAlign:'center', marginBottom:25, textTransform:'uppercase', fontSize:16, lineHeight:1.2, fontFamily:'Poppins-Bold' }} onClick={() => { this.toggleDiscussionMode(false) }}>Vous avez séléctionné une discussion dans ce fil - <span style={{ borderBottom:'dotted 2px #555' }}>revenir à tous les messages</span></div> }
              </div>
              { responses }
            </div>
          : <div style={{textAlign:'center', marginTop:100, marginBottom:100}}>
              <CircularProgress size={52} style={{marginTop:5, color:'#666'}} />
            </div>
        }
      </div>
    </SwipeableDrawer>

  }

  renderNewTopic = () => {

    return <SwipeableDrawer
      anchor={ 'bottom' }
      open={ this.props.forum.showNewTopic }
      onClose={ this.props.forum.toggleNewTopic }
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div onClick={ this.props.forum.toggleNewTopic } style={{padding:90}}></div>
      <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
        <div style={{ display:'flex', color:'#000', fontSize:22, fontFamily:'Poppins-Bold', justifyContent:'center', marginBottom:15 }}>
          nouveau fil
        </div>
        { this.renderTagsBar(true) }
        <div style={{ display:'flex', flexDirection:'column' }}>
          <TextField
            style={{ marginBottom:15, backgroundColor:'#FFF' }}
            fullWidth
            multiline
            rows={4}
            placeholder="Contenu de votre fil"
            value={this.state.newTopicContent}
            onChange={ this.setTopicContent }
          />
          <div style={{ marginTop:-10, textAlign:'right' }}>{ this.state.newTopicContent.length }/220</div>
          <div style={{ display:'flex', justifyContent:'center' }}>
            { this.state.postingNewMessage
              ? <div style={{textAlign:'center'}}>
                  <CircularProgress size={32} style={{marginTop:5, color:'#666'}} />
                </div>
              : <Button disabled={ this.state.newTopicContent.length === 0 } onClick={ this.postNewTopic }>Envoyer</Button>
            }
          </div>
        </div>
      </div>
    </SwipeableDrawer>

  }

  toggleNewMessage = (messageId) => {

    if (this.state.messageToReply)
      this.setState({messageToReply: null})
    else
      this.setState({messageToReply: messageId})

  }

  renderNewMessage = () => {

    let messageToReply

    if (this.props.forum.selectedThread.message) {
      if (this.props.forum.selectedThread.message._id === this.state.messageToReply) {
        messageToReply = this.props.forum.selectedThread.message
      } else {
        messageToReply = this.props.forum.selectedThread.responses.find( item => item._id === this.state.messageToReply )
      }
    }

    return <SwipeableDrawer
      anchor={ 'bottom' }
      open={ this.state.messageToReply }
      onClose={ this.toggleNewMessage }
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div onClick={ this.toggleNewMessage } style={{padding:90}}></div>
      { messageToReply &&
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
          <div style={{ display:'flex', color:'#000', fontSize:22, fontFamily:'Poppins-Bold', justifyContent:'center', marginBottom:15 }}>
            répondre à un message
          </div>
          <div>
            { this.renderMessage(messageToReply, true) }
          </div>
          <div style={{textAlign:'center', marginTop:-5 }}>
            <ArrowDownward style={{ fontSize:32 }} />
          </div>
          <div style={{ display:'flex', flexDirection:'column' }}>
            <TextField
              style={{ marginBottom:15, backgroundColor:'#FFF' }}
              fullWidth
              multiline
              rows={4}
              placeholder="votre réponse"
              value={this.state.newTopicContent}
              onChange={ this.setTopicContent }
            />
            <div style={{ marginTop:-10, textAlign:'right' }}>{ this.state.newTopicContent.length }/220</div>
            <div style={{ display:'flex', justifyContent:'center' }}>
              { this.state.postingNewMessage
                ? <div style={{textAlign:'center'}}>
                    <CircularProgress size={32} style={{marginTop:5, color:'#666'}} />
                  </div>
                : <Button disabled={ this.state.newTopicContent.length === 0 } onClick={ this.postNewMessage }>Envoyer</Button>
              }
            </div>
          </div>
        </div>
      }
    </SwipeableDrawer>

  }

  addTag = (tag) => {

    this.props.forum.selectTag(tag)

  }

  applyTags = () => {

    this.setState({showTagsSelection: false})

  }

  renderTagsSelection = () => {

    if (this.props.forum.selectedTags.length === 0)
      return

    const selectedTags = []
    const matchingTags = []
    const availableTags = [...this.props.forum.availableTags]
    const currentTagsSelection = []

    for (let tag of availableTags) {

      tag['path'] = ''
      let endLoop = true
      let parent = tag['parent']
      let level = 0

      if (parent) {

        while (endLoop) {

          level++

          let loopTag = availableTags.find( item => item.tag === parent )

          if (loopTag['parent'] === null)
            endLoop = false
          else
            parent = loopTag['parent']

        }

      }

      tag['level'] = level

    }

    for (let tag of availableTags) {

      tag['path'] = ''
      let endLoop = true
      let parent = tag['parent']
      let path

      if (tag.level > 1 || tag.propagate)
        path = ''
      else
        path = tag['tag']

      if (parent) {

        while (endLoop) {

          let loopTag = availableTags.find( item => item.tag === parent )

          if (path !== '')
            path = `${loopTag['tag']}/${path}`
          else
            path = loopTag['tag']

          if (loopTag['parent'] === null)
            endLoop = false
          else
            parent = loopTag['parent']

        }

      }

      tag['path'] = `/${path}`

    }

    for (const tag of this.props.forum.selectedTags) {
      const tagObj = availableTags.find( item => item.tag === tag )
      currentTagsSelection.push(tagObj)
    }

    // sort currentTagsSelection by level property
    currentTagsSelection.sort((a, b) => a.level - b.level)

    // get the last tag of the currentTagsSelection
    const lastTag = currentTagsSelection[currentTagsSelection.length - 1]

    // sort availableTags by tag property
    availableTags.sort((a,b) => (a.tag > b.tag) ? 1 : ((b.tag > a.tag) ? -1 : 0))

    for (const tag of availableTags) {
      if ( tag['tag'].includes(this.state.searchTag) && ( tag['path'].includes(lastTag['path']) || ( tag['propagate'] && lastTag['path'].includes(tag['path']) ) ) && !this.props.forum.selectedTags.includes(tag['tag']))
        matchingTags.push(
          <div onClick={() => { this.addTag( tag['tag'] ) }} style={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row', backgroundColor:'#FFF', borderRadius:10, padding:10, marginBottom:5 }}>
            <div style={{ display:'flex', flexDirection:'row', fontSize:18, fontFamily:'Poppins-Bold' }}>
              { tag['tag'] }
            </div>
            <div style={{ display:'flex', alignItems:'center' }}>
              <Add style={{fontSize:28}} />
            </div>       
          </div>
        )
    }

    let selectedTagsPosition = 0

    for (const tag of this.props.forum.selectedTags) {
      selectedTagsPosition++

      const tagDetails = availableTags.find( item => item.tag === tag )
      const isHidden = this.props.forum.hiddenTags.find( item => item === tag )

      selectedTags.push(
        <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row', backgroundColor:'#CCC', borderRadius:10, padding:10, marginBottom:5 }}>
          <div style={{ display:'flex', flexDirection:'row', fontSize:18, fontFamily:'Poppins-Bold' }}>
            { tag }
          </div>
          { ( selectedTagsPosition > 1 && !tagDetails.avoid_removing )
            &&  <div style={{ display:'flex', alignItems:'center' }}>
                  { isHidden
                    ? <VisibilityOff onClick={() => { this.props.forum.unhideTag(tag) }} style={{fontSize:28, marginRight:10}} />
                    : <Visibility onClick={() => { this.props.forum.hideTag(tag) }} style={{fontSize:28, marginRight:10}} />
                  }
                  <Delete onClick={() => { this.props.forum.removeTag(tag) }} style={{fontSize:28}} />
                </div>
          }
        </div>
      )
    }

    return <SwipeableDrawer
      anchor={ 'bottom' }
      open={ this.state.showTagsSelection }
      onClose={ () => { this.setState({showTagsSelection: false}) } }
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <div onClick={ () => { this.setState({showTagsSelection: false}) } } style={{padding:90}}></div>
      <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>
        { selectedTags }
        <div style={{marginTop:10, marginBottom:20, display:'flex', justifyContent:'center'}}>
          <Button onClick={ this.applyTags }>terminer</Button>
        </div>
          { (matchingTags.length > 0 || this.state.searchTag.length > 0) &&
            <div style={{ marginTop:25, marginBottom:20 }}>
              <TextField onChange={(event) => {this.setState({searchTag: event.target.value})}} label="rechercher un tag" variant="outlined" fullWidth />
            </div>
          }
          { matchingTags.length > 0 ? matchingTags : <div style={{ textAlign:'center', fontFamily:'Poppins-Bold', textTransform:'uppercase' }}>aucun autre tag trouvé</div> }
      </div>
    </SwipeableDrawer>

  }

  render = () => {

    return (
      <>
        { this.renderRootCategories() }
        { this.renderTopicsList() }
        { this.renderThread() }
        { this.renderNewTopic() }
        { this.renderNewMessage() }
        { this.renderTagsSelection() }
      </>
    )

  }

}

const App = ( props ) => 
  <ThemeProvider theme={theme}>
    <ForumScreen {...props} />
  </ThemeProvider>

export default App
