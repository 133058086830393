import { Component } from 'react';
import {Button, TextField, Switch} from '@mui/material'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import { fullTextDate } from '../utils/common'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d',
    },
    default: {
      main: '#f0bc3d',
      primary: '#f0bc3d',
      secondary: '#f0bc3d'
    },
  },
})

class Settings extends Component {

  constructor (props) {
    super(props);
    this.state = {
      pseudo: props.user.username,
      accept_mail: props.user.accept_mail,
      loading: false
    };
  }

  logout = () => {

    localStorage.removeItem('token')
    window.location.reload(false)

  }

  deleteAccount = async () => {

    this.setState({loading: true})

    await this.props.user.deleteAccount()

    this.setState({loading: false})

  }

  togglePushNotification = async () => {

    this.setState({
      loading: true
    })

    if ( this.props.user.notification_status === 'accepted' ) {

      await this.props.user.declineNotificationPermission()

    } else {

      await this.props.user.requestNotificationPermission()

    }

    this.setState({
      loading: false
    })

  }

  render = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      paddingRight:15,
      paddingLeft:15
    }))

    return (
      <ThemeProvider theme={theme}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <div style={{paddingLeft:7}}>recevoir les notifications par mail</div>
          <div><Switch onChange={() => { this.props.user.updateAcceptMail(!this.state.accept_mail); this.setState({accept_mail: !this.state.accept_mail})}} color="primary" checked={this.state.accept_mail} /></div>
        </div>
        <div style={{display:'flex', marginBottom:20, justifyContent:'space-between', alignItems:'center'}}>
          <div style={{paddingLeft:7}}>recevoir les notifications push</div>
          <div><Switch onChange={ this.togglePushNotification } color="primary" disabled={ this.state.loading } checked={ this.props.user.notification_status === 'accepted' } /></div>
        </div>
        <div style={{ display:'flex', color:'#333', flexDirection:'column', backgroundColor:'#FFF', borderRadius:10, padding:15, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', marginBottom:35 }}>
          <div style={{ textAlign:'center', textTransform:'uppercase', marginBottom:15, fontSize:20, fontFamily:'Poppins-Bold' }}>configurer mon gravatar</div>
          <div style={{ display:'flex' }}>
            <div style={{ marginRight:15 }}> <img style={{ borderRadius:'50%' }} src={this.props.user.avatar} /> </div>
            <div style={{ lineHeight:1.2, fontFamily:'Poppins-Bold', fontSize:16, color:'#777' }}>Gravatar est un service gratuit qui vous permet d'associer un avatar à votre email.</div>
          </div>
          <a href={'https://gravatar.com'} target={'_blank'}>
            <ColorButton style={{width:'100%', marginTop:10}}>aller sur gravatar.com</ColorButton>
          </a>
        </div>
        <TextField onBlur={() => { this.props.user.updateUsername(this.state.pseudo) }} color="default" style={{width:'100%', padding:2, marginBottom:15}} label="pseudo" onChange={(e) => { this.setState({pseudo: e.target.value}) }} value={this.state.pseudo} variant="outlined" />
        <TextField style={{width:'100%', marginBottom:15}} label="email" value={this.props.user.email} disabled variant="outlined" />
        <ColorButton style={{width:'100%', marginTop:25}} onClick={this.logout}>me déconnecter</ColorButton>
        { this.props.user.datetime_deletion
            ? <div style={{textAlign:'center', marginTop:25}}>
                <div style={{ fontFamily:'Poppins-Bold', color:'#f65c5c', lineHeight:1.2, textTransform:'uppercase', fontSize:14 }}>attention, votre compte sera supprimé le { fullTextDate(this.props.user.datetime_deletion) }, toutes vos informations seront effacées et votre compte ne sera plus accéssible</div>
                <span onClick={this.deleteAccount} style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:'min(3.733vw, 16.052px)', color:'#333', borderBottom:'dotted 2px #999'}}>annuler la suppression</span>
              </div>
            : <ColorButton disabled={this.state.loading} style={{width:'100%', marginTop:10}} onClick={this.deleteAccount}>supprimer mon compte</ColorButton>
        }
      </ThemeProvider>
    )

  }

}

export default Settings;
