import { Component } from 'react'
import Player from '../component/player.js'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

class WalletPreview extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      paddingRight:15,
      paddingLeft:15
    }))

    const players = []

    const rotate = [
      1, -1, 1.5, -0.3
    ]

    this.props.players.map((player, index) => {
      players.push(
        <Player
          onClick={ () => { this.props.selectPlayer(player.id) } }
          width={'calc(50% - 24px)'}
          rotate={rotate[index]}
          player={ player }
        />
      )
    })

    return (
      <>
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', marginTop:50, paddingBottom:3, marginBottom:10, borderBottom:'solid 3px #BBB', textAlign:'center' }}>ma collection</div>
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1.1, fontSize:16, textAlign:'center', marginBottom:10 }}>
          Achetez les joueurs qui vont le plus progresser et revendez-les au meilleur moment (1 point ATP = $0.01).
        </div>
        { this.props.players.length > 0 
            ? <div>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
                  { players }
                </div>
                <div style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:14, textTransform:'uppercase', textAlign:'center', lineHeight:1, marginBottom:20, marginTop:10 }}>
                  <span onClick={() => { this.props.toggleWallet(true, 1) }} style={{ borderBottom:'dotted 2px #999'}}>voir tous mes joueurs</span>
                </div>
              </div>
            : <div style={{ textAlign:'center', marginBottom:20, marginTop:10 }}>
                <ColorButton onClick={() => { this.props.toggleWallet(true) }} variant="contained">acheter des joueurs</ColorButton>
              </div>
        }
      </>
    )

  }

}

export default WalletPreview;
