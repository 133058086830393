import { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import TennisPointBanner from '../asset/image/tennispointloading.png'

class Loading extends Component {

  constructor (props) {
    super(props);
    this.state = {}
  }

  adsTimeout = () => {

    setTimeout(() => {
      this.props.setAdAsFinished()
    }, 5000)

  }

  render = () => {

    return (
      <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:50 }}>
        <CircularProgress style={{marginTop:30, color: '#fff'}} size={64} />
      </div>
    )

  }

}

export default Loading
