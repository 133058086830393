import axios from 'axios';

export const fetchGetURLRaw = async (url, params) => {

  try {

    if (params) {
      const urlEncodedParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&')
      url = `${url}?${urlEncodedParams}`
    }

    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    const json = await rawResponse.json()

    return {status: rawResponse.status, data: json}

  } catch(error) {

    return { status: 500, message: 'query_can_not_be_sent' }

  }

}

export const getBlob = async (url) => {

  const rawResponse = await fetch(url, {
    method: 'GET',
    headers: {}
  })

  let imageBlob
  let imageObjectURL

  if (rawResponse.status === 200) {

    imageBlob = await rawResponse.blob()
    imageObjectURL = URL.createObjectURL(imageBlob)
    return imageObjectURL

  }

  return false

}

export const fetchGetURL = async (url, params, path, redirectToLogin) => {

  try {

    if (path) {
      for (const [key, value] of Object.entries(path)) {
        url = url.replace(`:${key}`, value)
      }
    }

    const token = localStorage.getItem('token')
    let bearer = ''

    if (token)
      bearer = token

    if (params) {
      const urlEncodedParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }).join('&')
      url = `${url}?${urlEncodedParams}`
    }

    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearer}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

    const json = await rawResponse.json()

    if (redirectToLogin && json.error === 'authorization_error') {
      localStorage.removeItem('token')
      document.location.href = "/"
    }

    return { status: rawResponse.status, data: json }

  } catch(error) {
    return { status: 500, message: 'query_can_not_be_sent' }
  }

};

export const fetchPostFile = async (url, file) => {

  try {

    const token = localStorage.getItem('AuthToken');
    let bearer = '';

    if (token)
      bearer = `Bearer ${token}`;

    let data = new FormData();
    data.append("image", file);

    const rawResponse = await axios
			.post(url, data, {
				headers: {
          Authorization: bearer,
					'content-type': 'multipart/form-data'
				}
      });
      
  } catch (error) {

    console.error(error);
    return {success: false, message: 'query_can_not_be_sent'};

  }

}

export const fetchPostURL = async (url, params, path, redirectToLogin) => {

  try {

    const token = localStorage.getItem('token');
    let bearer = '';

    if (token)
      bearer = `Bearer ${token}`;

    if (path) {
      for (const [key, value] of Object.entries(path)) {
        url = url.replace(`:${key}`, value);
      };
    }

    let urlEncodedParams = ''
    
    if (params) {
      urlEncodedParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }).join('&');
    }

    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': bearer
      },
      body: urlEncodedParams
    });

    const content = await rawResponse.json();

    if (redirectToLogin && content.error === 'authorization_error') {
      localStorage.removeItem('AuthToken');
      localStorage.removeItem('UserId');
      document.location.href = "/";
    }

    return { status: rawResponse.status, data: content }

  } catch (error) {
    console.error(error);
    return {success: false, message: 'query_can_not_be_sent'};
  }

};

export const fetchPutURL = async (url, params) => {

  try {

    const token = localStorage.getItem('AuthToken');
    let bearer = '';

    if (token)
      bearer = `Bearer ${token}`;
    
    const urlEncodedParams = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    const rawResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': bearer
      },
      body: urlEncodedParams
    });
    const content = await rawResponse.json();
    return content;
  } catch (error) {
    console.error(error);
    return {success: false, message: 'query_can_not_be_sent'};
  }

};

export const fetchDeleteURL = async (url, params) => {

  try {

    const token = localStorage.getItem('AuthToken');
    let bearer = '';

    if (token)
      bearer = `Bearer ${token}`;
    
    const urlEncodedParams = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    const rawResponse = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': bearer
      },
      body: urlEncodedParams
    });
    const content = await rawResponse.json();
    return content;
  } catch (error) {
    console.error(error);
    return {success: false, message: 'query_can_not_be_sent'};
  }

};
