import React, { Component } from 'react';
import Main from '../layout/main';
import Login from '../layout/login';
import AppContext from '../context/app';
import SpinnerModal from '../component/spinnerModal';
import Pronostics from '../component/pronostics';
import WalletPreview from '../component/walletPreview';
import Ads from '../component/ads'
import LoginBody from '../screen/login'
import HomeHeader from '../component/homeHeader'
import Ranks from '../component/ranks'
import Loading from '../screen/loading'
import { isIos, isAndroid, appIsInstalled } from '../utils/common'
import IosInstall from '../component/iosInstall'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../config/theme'
import Rankings from '../component/rankings'
import Button from '../component/button'

let installPrompt

class Home extends Component {

  constructor (props) {
    super(props);
    this.state = {
      selectedTab: 'trading',
      toggleWallet: props.context.wallet.toggle,
      loteryModal: false,
      showIosInstallInstructions: false,
      showInstallSuggestion: localStorage.getItem('decline_install') !== 'yes',
      loadingAdFinished: true
    }
  }

  componentDidMount = async () => {

    document.title = "Tennis Empire"

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault()
      installPrompt = event
    })

    if (localStorage.getItem('token'))
      await this.loadInitialData()

    if ( this.appIsReady() && !appIsInstalled && localStorage.getItem('decline_install') !== 'yes' && !isIos() )
      this.loadAndroidPrompt()

  }

  loadAndroidPrompt = async () => {

    if (!this.state.showInstallSuggestion)
      return null

    if ( appIsInstalled )
      return null

    const androidInstallButton = document.querySelector("#android-install-button")

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault()
      installPrompt = event
    })

    androidInstallButton.addEventListener("click", async () => {

      if (!installPrompt)
        return

      await installPrompt.prompt()

    })

  }

  appIsReady = () => {

    if (this.props.context.user.loaded && this.props.context.players.list.length > 0 && this.props.context.shop.home.list.length > 0)
      return true

    return false

  }

  loadInitialData = async () => {

    if (!this.props.context.user.loaded)
      await this.props.context.user.refresh()

    await this.props.context.players.refresh()
    await this.props.context.pronostics.refresh()

    if (this.props.context.shop.home.list.length === 0 && !this.props.context.shop.home.loading)
      await this.props.context.shop.home.load()

    await this.props.context.gifts.refresh()
    await this.props.context.forum.loadConfig()
    
  }

  refresh = async () => {

    await this.props.context.user.toggleLogoLoading()
    await this.props.context.user.refresh()
    await this.props.context.players.refresh()
    await this.props.context.pronostics.refresh()
    await this.props.context.shop.home.load()
    await this.props.context.user.toggleLogoLoading()
    await this.props.context.forum.loadConfig()

  }

  setLoadingAdAsFinished = () => {
    this.setState({ loadingAdFinished: true })
  }

  renderNotificationRequesting = () => {

    if (!appIsInstalled)
      return null

    if (this.props.context.user.notification_status === 'accepted' || this.props.context.user.notification_status === 'declined')
      return null

    return (
      <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:25, backgroundColor:"#DDD", color:'#000', fontSize:18, lineHeight:1, marginBottom:20, padding:20, borderRadius:10}}>
        <div>Souhaitez-vous activer les notifications sur cet appareil ?</div>
        <div style={{marginTop:10}}>
          <Button style={{margin:5}} onClick={ this.props.context.user.requestNotificationPermission }>oui</Button>
          <Button style={{margin:5}} onClick={ this.props.context.user.declineNotificationPermission }>non</Button>
        </div>
      </div>
    )

  }

  declineInstallSuggestion = () => {

    localStorage.setItem('decline_install', 'yes')
    this.setState({showInstallSuggestion: false})

  }

  renderInstallPrompt = () => {

    if (!this.state.showInstallSuggestion)
      return null

    if (appIsInstalled)
      return null

    if (isIos()) {

      return (
        <>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:25, backgroundColor:"#DDD", color:'#000', fontSize:18, lineHeight:1, marginBottom:20, padding:20, borderRadius:10}}>
            <div>Souhaitez-vous installer Tennis Empire sur cet iPhone ?</div>
            <div style={{marginTop:10}}>
              <Button style={{margin:5}} onClick={ () => { this.setState({ showIosInstallInstructions: true }) } }>oui</Button>
              <Button style={{margin:5}} onClick={ this.declineInstallSuggestion }>non</Button>
            </div>
          </div>
          <IosInstall show={this.state.showIosInstallInstructions} close={() => { this.setState({showIosInstallInstructions: false}) }} />
        </>
      )

    } else {

      return (
        <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:25, backgroundColor:"#DDD", color:'#000', fontSize:18, lineHeight:1, marginBottom:20, padding:20, borderRadius:10}}>
          <div>Souhaitez-vous installer Tennis Empire sur cet appareil ?</div>
          <div style={{marginTop:10}}>
            <Button id={'android-install-button'} style={{margin:5}}>oui</Button>
            <Button style={{margin:5}} onClick={ this.declineInstallSuggestion }>non</Button>
          </div>
        </div>
      )

    }

  }

  render = () => {

    // Check if a token is available
    // ---

    if (!localStorage.getItem('token'))
      return (
        <Login body={<LoginBody players={this.props.context.players.list} />} />
      )    

    if (this.props.context.players.list.length === 0) {

      return (
        <Login hideHeader={true} body={<Loading />} />
      )

    }

    // First loading, refresh his user data
    // ---

    if (!this.appIsReady() && !this.state.loadingAdFinished) {

      return (
        <Login hideHeader={true} body={<Loading setAdAsFinished={this.setLoadingAdAsFinished} showAds={true} />} />
      )

    }   

    const tradingTab = (
      <>
        <HomeHeader
          user={this.props.context.user}
          players={this.props.context.players}
          showLoteryWindow={ () => { this.setState( { loteryModal: true } ) }}
        />
        <WalletPreview
          selectPlayer={ this.props.context.player.select }
          toggleWallet={ this.state.toggleWallet }
          players={ this.props.context.players.list.filter( player => player.quantity > 0 ).slice(0, 4) }
          avatars={ this.props.context.players.avatars }
        />
        <Rankings rank={'trading'} user={this.props.context.user} notifications={this.props.context.notifications} />
      </>
    )

    const pronosticsTab = (
      <>
        <Pronostics showBonus={this.props.context.bonus.toggle} user={this.props.context.user} context={this.props.context.pronostics} />
        <Rankings rank={'pronostics'} user={this.props.context.user} notifications={this.props.context.notifications} />
      </>
    )

    const selectedTabStyle = { flex:'1 1 0', flexGrow:1, padding:7, backgroundColor:'#f0bc3d', color:'#333', borderRight:'none' }
    const unselectedTabStyle = { flex:'1 1 0', flexGrow:1, padding:7, backgroundColor:'#FFF', borderRight:'none' }
    const firstTabStyle = { borderTopLeftRadius:10, borderBottomLeftRadius:10 }
    const lastTabStyle = { borderBottomRightRadius:10, borderTopRightRadius:10 }

    const body = (
      <>

        <div style={{ marginTop:70, fontSize:15, color:'#333', display:'flex', textAlign:'center', fontFamily:'Poppins-Bold', textTransform:'uppercase' }}>
          <div onClick={() => { this.setState({selectedTab: 'trading'}) }} style={ this.state.selectedTab === 'trading' ? {...firstTabStyle, ...selectedTabStyle} : {...firstTabStyle, ...unselectedTabStyle} }>collection</div>
          <div onClick={() => { this.setState({selectedTab: 'pronostics'}) }} style={ this.state.selectedTab === 'pronostics' ? {...selectedTabStyle, ...lastTabStyle} : {...unselectedTabStyle, ...lastTabStyle} }>pronostics</div>
        </div>

        { this.renderNotificationRequesting() }
        { this.renderInstallPrompt() }

        { this.state.selectedTab === 'trading' && tradingTab }
        { this.state.selectedTab === 'pronostics' && pronosticsTab }       

        <div id={"ads"} style={{height:50}}></div>

        <Ads />
        
        <Ranks ranks={this.props.context.user.ranks} />

        { this.state.loteryModal 
            && <SpinnerModal
                user={ this.props.context.user }
                players={ this.props.context.players }
                show={ this.state.loteryModal }
                onClose={ () => { this.setState( { loteryModal: false } ) }}
              />
        }
      
      </>
    )

    return <Main logoOnClick={this.refresh} body={body} />

  }

}

const App = ( props ) => 
  <AppContext.Consumer>
    {(appContext) => (
      <ThemeProvider theme={theme}>
        <Home {...props} context={appContext} />
      </ThemeProvider>
    )}
  </AppContext.Consumer>

export default App;
