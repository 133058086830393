import { Component } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

class HomeHeader extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  scrollToAds = () => {
    const section = document.querySelector( '#ads' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  render = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:'min(3.733vw, 16.052px)',
      borderRadius:'min(6.133vw, 26.372px)',
      height:'min(9.333vw, 40.132px)',
      color:'#333',
      letterSpacing:'max(-0.133vw, -0.572px)',
      paddingRight:'min(4vw, 17.2px)',
      paddingLeft:'min(4vw, 17.2px)'
    }))

    let spinButtonTxt = `piocher mon joueur du jour`

    if (!this.props.user.available_spin && this.props.user.available_spins > 0)
      spinButtonTxt = `piocher un joueur bonus`
 
    return (
      <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', marginTop:20 }}>

        <div style={{ backgroundColor:'#fff', padding:15, borderRadius:10, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)' }}>
          
          <div style={{ fontFamily:'Poppins-Bold', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center', lineHeight:1, fontSize:'min(10.133vw, 43.572px)', letterSpacing:'max(-0.533vw, -2.292px)' }}>{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.user.wallet_value) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:'min(4.800vw, 20.64px)', color:'#333', marginTop:-3 }}>valeur de ma collection</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:'min(7.467vw, 32.108px)', marginTop:10, letterSpacing:'max(-0.533vw, -2.292px)' }}>{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.user.cash) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:'min(4.800vw, 20.64px)', marginTop:-3, color:'#333' }}>cash disponible</div>

        </div>
        <div style={{marginTop:30}}>
          <ColorButton disabled={!this.props.user.available_spin && this.props.user.available_spins == 0 } onClick={() => { this.props.showLoteryWindow() }} variant="contained">
            { spinButtonTxt }
          </ColorButton>
        </div>
        <div style={{marginTop:3}}>
          { this.props.user.available_spins > 0
            ? <span style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:'13px', color:'#999'}}>spins disponibles : { this.props.user.available_spins }</span>
            : <span onClick={ this.scrollToAds } style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:'min(3.733vw, 16.052px)', color:'#333', borderBottom:'dotted 2px #999'}}>obtenir plus de spins</span>
          }
        </div>
      </div>
    )

  }

}

export default HomeHeader
