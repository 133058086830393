import { Component } from 'react'

class ShopCard extends Component {

  constructor (props) {
    super(props);
    this.state = {}
  }

  spinNumber = (product, programs) => {

    if (!programs)
      return null

    const program = programs.find(program => program.source === product.source && program.ref === product.program_ref)

    if (!program)
      return null

    let output = Math.floor(product.price / program.deal_value_ttc)
    
    if (output < 1)
      output = 1

    if (output == 1)
      return `+${output} spin`

    return `+${output} spins`

  }

  render = () => {

    return (
      <div onClick={() => this.props.load(this.props.product.id)} style={{ width:'47%', margin:'1.5%', borderRadius:10, backgroundColor:'#EEE', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
        <div style={{ position:'relative', aspectRatio:'1/1' }}>
          <div style={{ width:'calc(100% - 30px)', height:'calc(100% - 30px)', padding:15, backgroundColor:'#FFF', borderTopLeftRadius:10, borderTopRightRadius:10 }}>
            <img style={{ width:'100%', height:'100%', objectFit:'cover', display:'block' }} src={this.props.product.image} />
          </div>
          <div style={{position:'absolute', textAlign:'center', top:0, right:0, color:'#f0bc3d', margin:5, padding:6, backgroundColor:'#333', fontFamily:'Poppins-Bold', borderRadius:5, fontSize:15, lineHeight:'15px'}}>
            <div>{ this.props.product.price.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) }</div>
            <div style={{fontSize:11, marginTop:2, letterSpacing:-0.3, lineHeight:'11px'}}>{this.spinNumber(this.props.product, this.props.deals)}</div>
          </div>
        </div>
        <div style={{ padding:10, paddingBottom:10, textTransform:'uppercase', textAlign:'center', fontSize:13, lineHeight:'13px', fontFamily:'Poppins-Bold' }}>
          { this.props.product.name }
        </div>
      </div>
    )

  }

}

export default ShopCard;
