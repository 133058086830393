import { Component } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '../component/button'

class Rankings extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  renderTradingRank = () => {

    // TRADING RANKING

    let rank = []

    this.props.user.friends.map((friend) => {
      rank.push({name: friend.username, value: (friend.ranks && friend.ranks.evolution) ? friend.ranks.evolution : 0, unit: '%'})
    })

    rank.sort((a, b) => b.value - a.value)

    const items = []

    rank = rank.map((user, index) => {

      const position = index + 1

      let valueColor = '#000'

      if (user.value != 0)
        valueColor = user.value > 0 ? '#00df00' : '#f03557'

      let value = user.value

      value = `${value > 0 ? '+' : ''}${user.value.toFixed(2)}`

      items.push(
        <div style={{ position:'relative', padding:10, marginTop:10, display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom: 'solid 2px #CCC' }}>
          <div style={{display:'flex', alignItems:'center'}}>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'lowercase', fontSize:18, lineHeight:1 }}>{ position }{ position === 1 ? 'er' : 'ème' } - { user.name }</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', fontSize:18, lineHeight:1, color: valueColor }}>{value}{user.unit}</div>
        </div>
      )

    })

    return (
      <>
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', marginTop:50, paddingBottom:3, marginBottom:10, borderBottom:'solid 3px #BBB', textAlign:'center' }}>classements</div>
        <div style={{ backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', marginTop:25, padding:10, paddingTop:15, paddingBottom:25, borderRadius:10 }}>
          
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:40}}>
            <Tabs value={0} centered scrollButtons allowScrollButtonsMobile>
              <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Amis'} />
            </Tabs>
          </div>
          
          { items }

          <div style={{marginTop:25, display:'flex', justifyContent:'center'}}>
            <Button onClick={() => { this.props.notifications.toggle(2) }}>Ajouter des amis</Button>
          </div>

        </div>
      </>
    )

  }

  renderPronosticsRank = () => {

    let rank = []

    this.props.user.friends.map((friend) => {
      rank.push({name: friend.username, value: friend.ranks.pronostics.pts, unit: ' pts'})
    })

    rank.sort((a, b) => b.value - a.value)

    const items = []

    rank = rank.map((user, index) => {

      const position = index + 1

      let valueColor = '#000'
      let value = user.value

      items.push(
        <div style={{ position:'relative', padding:10, marginTop:10, display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom: 'solid 2px #CCC' }}>
          <div style={{display:'flex', alignItems:'center'}}>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'lowercase', fontSize:18, lineHeight:1 }}>{ position }{ position === 1 ? 'er' : 'ème' } - { user.name }</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', fontSize:18, lineHeight:1, color: valueColor }}>{value}{user.unit}</div>
        </div>
      )

    })

    return (
      <>
        <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', marginTop:50, paddingBottom:3, marginBottom:10, borderBottom:'solid 3px #BBB', textAlign:'center' }}>classements</div>
        <div style={{ backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', marginTop:25, padding:10, paddingTop:15, paddingBottom:25, borderRadius:10 }}>
          
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:40}}>
            <Tabs value={0} centered scrollButtons allowScrollButtonsMobile>
              <Tab style={{ fontFamily:'Poppins-Bold', letterSpacing:-0.6, fontSize:16 }} label={'Amis'} />
            </Tabs>
          </div>
          
          { items }

          <div style={{marginTop:25, display:'flex', justifyContent:'center'}}>
            <Button onClick={() => { this.props.notifications.toggle(2) }}>Ajouter des amis</Button>
          </div>

        </div>
      </>
    )

  }

  render = () => {

    if (this.props.rank === 'trading')
      return this.renderTradingRank()

    return this.renderPronosticsRank()

  }

}

export default Rankings
