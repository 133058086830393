import React, { Component } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import AppContext from '../context/app'
import ShopCard from '../component/shopCard'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f0bc3d'
    },
    secondary: {
      main: '#f0bc3d'
    },
  },
})

class Ads extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showDeals: false
    }
  }

  showShopTab = async (tab_index) => {
    await this.props.context.shop.catalog.switchTab(tab_index)
    this.props.context.shop.catalog.toggle()
  }

  render = () => {

    const ColorButton = styled(Button)(({ theme }) => ({
      fontFamily:'Poppins-Bold',
      backgroundColor:`#f0bc3d!important`,
      fontSize:14,
      borderRadius:23,
      height:35,
      color:'#333',
      letterSpacing:-0.5,
      paddingRight:15,
      paddingLeft:15
    }))

    const products_list = []

    for (const product of this.props.context.shop.home.list)
      products_list.push( <ShopCard load={this.props.context.shop.product.load} deals={this.props.context.deals.programs} product={product} /> )

    return (
      <>
        <div style={{ backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', padding:10, borderRadius:10 }}>
          <div style={{fontFamily:'Poppins-Bold', paddingTop:10, color:'#000', fontSize:22, textTransform:'uppercase', textAlign:'center', letterSpacing:-1.3, lineHeight:1}}>
            5€ d'équipement tennis acheté = 1 spin
          </div>
          <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:15, textAlign:'center', marginBottom:25 }}>
            <ColorButton onClick={this.props.context.deals.toggle} variant="contained">suivre l'état de mes commandes</ColorButton>
          </div>
          <div style={{ display:'flex', flexWrap:'wrap', justifyContent:'flex-start', alignContent:'flex-start' }}>
            { products_list }
          </div>
          <div style={{fontFamily:'Poppins-Bold', paddingTop:20, color:'#000', fontSize:14, textTransform:'uppercase', textAlign:'center', letterSpacing:-1.1, lineHeight:1}}>
            découvrir plus offres par catégorie:
          </div>
          <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap', padding:7 }}>
            <div onClick={ () => { this.showShopTab(0) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>balles</div>
            <div onClick={ () => { this.showShopTab(1) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>cordages</div>
            <div onClick={ () => { this.showShopTab(2) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>grip</div>
            <div onClick={ () => { this.showShopTab(3) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>textile</div>
            <div onClick={ () => { this.showShopTab(4) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>chaussures</div>
            <div onClick={ () => { this.showShopTab(5) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>raquettes</div>
            <div onClick={ () => { this.showShopTab(6) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>sacs</div>
            <div onClick={ () => { this.showShopTab(7) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>accessoires</div>
            <div onClick={ () => { this.showShopTab(8) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>padel</div>
            <div onClick={ () => { this.showShopTab(9) }} style={{whiteSpace:'nowrap', fontFamily:'Poppins-Bold', backgroundColor:'#666', borderRadius:4, margin:3, textTransform:'uppercase', fontSize:15, padding:3, paddingRight:7, paddingLeft:7, color:'#FFF'}}>nutrition</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', color:'#333', fontSize:14, textTransform:'uppercase', textAlign:'center', lineHeight:1.2, marginBottom:10, marginTop:15 }}>
            <span style={{borderBottom:'dotted 2px #999'}} onClick={() => { this.showShopTab(0) }}>voir les produits</span>
          </div>
        </div>
        <a href="https://www.facebook.com/tennisempireapp/" target="_blank" style={{textDecoration:'none'}}>
          <div style={{fontFamily:'Poppins-Bold', backgroundColor:"#3b5998", color:'#fff', fontSize:16, marginBottom:10, textAlign:'center', marginTop:50, padding:5, borderRadius:10}}>nous rejoindre sur Facebook</div>
        </a>
      </>
    )

  }

}

const App = ( props ) => 
  <AppContext.Consumer>
    {(appContext) => (
      <ThemeProvider theme={theme}>
        <Ads {...props} context={appContext} />
      </ThemeProvider>
    )}
  </AppContext.Consumer>

export default App
