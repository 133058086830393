import { Component } from 'react'
import defaultAvatar from '../asset/image/default.jpeg'

class Player extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render = () => {

    let style

    if (this.props.style)
      style = this.props.style;
    else if (this.props.width) 
      style = { width:this.props.width, boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', borderRadius:10, backgroundColor:'#FFF', padding:5, margin:5, paddingBottom:20, paddingTop:20 }
    else
      style = { width:'150px', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)', borderRadius:10, backgroundColor:'#FFF', padding:5, margin:5, paddingBottom:20, paddingTop:20 }

    if (this.props.rotate)
      style = { ...style, transform: `rotate(${this.props.rotate}deg)` }

    let onClick = () => {}

    if (this.props.onClick)
      onClick = this.props.onClick

    const usd = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.player.value)

    return (
      <div onClick={onClick} style={style}>
        <div style={{ fontFamily:'Poppins-Bold', fontSize:18, textTransform:'uppercase', lineHeight:1, textAlign:'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'overflow': 'hidden' }}>{ this.props.player.name }</div>
        <div style={{ padding:'5%' }}><img style={{width:'100%'}} src={ this.props.player.avatar ? this.props.player.avatar : defaultAvatar } /></div>
        <div style={{ display:'flex', justifyContent:'space-evenly', fontFamily:'Poppins-Bold', fontSize:16, textTransform:'uppercase', lineHeight:1 }}>
          <div style={{ color:'#333' }}>{usd}</div>
          { this.props.player.evolution > 0 && <div style={{ color:'#00df00' }}>+{ this.props.player.evolution }%</div> }
          { this.props.player.evolution < 0 && <div style={{ color:'#f03557' }}>{ this.props.player.evolution }%</div> }
        </div>
      </div>
    )

  }

}

export default Player;
