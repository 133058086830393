import { Component } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { ArrowForwardIos } from '@mui/icons-material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { fullTextDate } from '../utils/common'

const ColorButton = styled(Button)(({ theme }) => ({
  fontFamily:'Poppins-Bold',
  backgroundColor:`#f0bc3d!important`,
  fontSize:14,
  borderRadius:23,
  height:35,
  color:'#333',
  letterSpacing:-0.5,
  paddingRight:15,
  paddingLeft:15
}))

class Deals extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showProgram: false,
      selectedProgram: null
    }
  }

  eshopDeals = () => {

    const deals = []

    for (const deal of this.props.deals.programs) {

      const approved_transactions = this.props.user.transactions.filter(transaction => `${transaction.source}::${transaction.advertiser_id}` === deal.id && transaction.commission_status === 'approved')
      const pending_transactions = this.props.user.transactions.filter(transaction => `${transaction.source}::${transaction.advertiser_id}` === deal.id && transaction.commission_status === 'pending')
      const declined_transactions = this.props.user.transactions.filter(transaction => `${transaction.source}::${transaction.advertiser_id}` === deal.id && ( transaction.commission_status === 'declined' || transaction.commission_status === 'deleted' ))

      deals.push(
        <div onClick={() => { this.setState({selectedProgram: deal.id}) }} style={{ borderRadius:7, marginBottom:15, padding:15, paddingRight:20, paddingLeft:20, display:'flex', backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
          <div style={{marginRight:10, display:'flex', alignItems: 'center'}}>
            <img style={{height:65, borderRadius:'50%'}} src={ deal.logo } />
          </div>
          <div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:16 }}>{ deal.deal_value_ttc }€ d'achat sur <span style={{ whiteSpace:'nowrap' }}>{ deal.name }</span></div>
              <ArrowForwardIos />
            </div>
            <div style={{ display:'flex', flexWrap:'wrap', fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1, fontSize:12, marginTop:7 }}>
              <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#b2dfb2', color:'#333', whiteSpace:'nowrap' }}>{ approved_transactions.length } commande validée</div>
              <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#CCC', color:'#333', whiteSpace:'nowrap' }}>{ pending_transactions.length } commande en attente</div>
              <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#f3c6c6', color:'#333', whiteSpace:'nowrap' }}>{ declined_transactions.length } commande retournée/annulée</div>
            </div>
          </div>
        </div>
      )
    }

    return deals

  }

  renderTransaction = (transaction) => {

    if (transaction.commission_status === 'approved') {

      return (
        <div key={transaction.id} style={{ display:'flex', flexDirection:'column', borderRadius:7, marginBottom:15, padding:15, paddingRight:25, paddingLeft:25, backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:18, textAlign:'center' }}>{ fullTextDate(transaction.transaction_date) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:28, marginTop:10, textAlign:'center' }}>{ (transaction.sale_amount_ht*( 1 + transaction.tva_rate )).toLocaleString('fr-FR', { style: 'currency', currency: transaction.commission_currency }) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:16, marginBottom:10, textAlign:'center', marginTop:3 }}>+{ transaction.spins.length } spins</div>
          <div style={{ display:'flex', justifyContent:'center', flexWrap:'wrap', fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1, fontSize:14 }}>
            <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#b2dfb2', color:'#333', whiteSpace:'nowrap' }}>commande validée</div>
          </div>
        </div>
      )

    } else if (transaction.commission_status === 'pending') {

      return (
        <div key={transaction.id} style={{ display:'flex', flexDirection:'column', borderRadius:7, marginBottom:15, padding:15, paddingRight:25, paddingLeft:25, backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:18, textAlign:'center' }}>{ fullTextDate(transaction.transaction_date) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:28, marginTop:10, textAlign:'center' }}>{ (transaction.sale_amount_ht*( 1 + transaction.tva_rate )).toLocaleString('fr-FR', { style: 'currency', currency: transaction.commission_currency }) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:16, marginBottom:10, textAlign:'center', marginTop:3 }}>+{ transaction.spins.length } spins</div>
          <div style={{ display:'flex', justifyContent:'center', flexWrap:'wrap', fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1, fontSize:14 }}>
            <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#CCC', color:'#333', whiteSpace:'nowrap' }}>commande en attente</div>
          </div>
          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1.1, fontSize:12, marginTop:7, textAlign:'center' }}>
            votre commande sera validée par le marchant une fois que votre droit de rétractation sera passé. La valeur des spins récupérés avec cette commande pourront débloquer des cadeaux une fois celle-çi validée.
          </div>
        </div>
      )

    } else if (transaction.commission_status === 'declined' || transaction.commission_status === 'deleted') {

      return (
        <div key={transaction.id} style={{ display:'flex', flexDirection:'column', borderRadius:7, marginBottom:15, padding:15, paddingRight:25, paddingLeft:25, backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:18, textAlign:'center' }}>{ fullTextDate(transaction.transaction_date) }</div>
          <div style={{ fontFamily:'Poppins-Bold', lineHeight:1, fontSize:28, marginTop:10, textAlign:'center' }}>{ (transaction.sale_amount_ht*( 1 + transaction.tva_rate )).toLocaleString('fr-FR', { style: 'currency', currency: transaction.commission_currency }) }</div>
          <div style={{ display:'flex', justifyContent:'center', flexWrap:'wrap', fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1, fontSize:14 }}>
            <div style={{ marginRight:3, marginBottom:3, padding:4, paddingLeft:10, paddingRight:10, borderRadius:4, backgroundColor:'#CCC', color:'#333', whiteSpace:'nowrap' }}>commande annulée/retournée</div>
          </div>
        </div>
      )

    }

  }

  renderTransactions = () => {

    const transactions_from_context = this.props.user.transactions.filter(transaction => `${transaction.source}::${transaction.advertiser_id}` === this.state.selectedProgram)
    const transactions = []

    for (const transaction of transactions_from_context)
      transactions.push( this.renderTransaction(transaction) )

    return (
      <SwipeableDrawer
        anchor={ 'bottom' }
        open={ this.state.selectedProgram }
        onClose={ () => this.setState({ selectedProgram: null }) }
        onOpen={ () => {} }
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
      >
        <div onClick={() => this.setState({ selectedProgram: null })} style={{padding:35}}></div>
        <div style={{backgroundColor:'#f0f0f0', borderTopLeftRadius:20, borderTopRightRadius:20, padding:20}}>

          <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:'22px', marginTop:25, paddingBottom:3, marginBottom:25, borderBottom:'solid 3px #BBB', textAlign:'center' }}>commandes</div>
          
          { transactions.length > 0
            ? transactions
            : <div style={{fontFamily:"Poppins-Bold", textAlign:'center', color:'#999', marginTop:35, textTransform:'uppercase', marginBottom:10, letterSpacing:-1, lineHeight:'20px', fontSize:20}}>Aucune commande</div>
          }

        </div>
      </SwipeableDrawer>      
    )

  }

  render = () => {

    return (
      <>
        <div style={{fontFamily:'Poppins-Bold', paddingBottom:15, color:'#333', fontSize:20, textTransform:'uppercase', textAlign:'center', letterSpacing:-1.2, lineHeight:1, marginTop:15}}>
          Ajoutez des joueurs à votre collection en vous équipant via les sites partenaires
        </div>
        <div style={{fontFamily:'Poppins-Bold', paddingBottom:15, color:'#666', fontSize:18, textTransform:'uppercase', textAlign:'center', letterSpacing:-1, lineHeight:1, marginTop:10}}>
          Vous gagnez 1 spin pour
        </div>

        { this.eshopDeals() }

        <div style={{fontFamily:'Poppins-Bold', paddingTop:10, color:'#666', fontSize:14, textTransform:'uppercase', textAlign:'center', letterSpacing:-1, lineHeight:1}}>
          Pensez à utiliser les liens fourni par Tennis Empire pour que vos achats soient comptabilisés
        </div>

        <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:15, marginBottom:10, textAlign:'center' }}>
          <ColorButton onClick={() => { this.props.catalog.toggle(true) }} variant="contained">parcourir les produits</ColorButton>
        </div>

        {/* <div style={{fontFamily:'Poppins-Bold', paddingBottom:15, color:'#666', fontSize:18, textTransform:'uppercase', textAlign:'center', letterSpacing:-1, lineHeight:1, marginTop:50}}>
          Récupérez des codes
        </div>

        <div style={{ borderRadius:7, marginBottom:15, padding:15, paddingRight:20, paddingLeft:20, display:'flex', backgroundColor:'#FFF', boxShadow:'0px 0px 10px -3px rgba(0,0,0,0.60)'}}>
          <div style={{marginRight:10, display:'flex', alignItems: 'center'}}>
            <img style={{height:65, borderRadius:'50%'}} src={"https://firebasestorage.googleapis.com/v0/b/tennis-empire.appspot.com/o/shop%2Ffft.png?alt=media&token=38f27112-4d5f-462d-962c-395184ba5f0d"} />
          </div>
          <div>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1, fontSize:16 }}>Club de tennis</div>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', color:'#777', lineHeight:1.1, fontSize:13, marginTop:7 }}>
              Votre club peut vous offrir des spins en entrant les codes qu'ils vous distriburont pendant les animations ou les entrainements
            </div>
            <div style={{ fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:1.1, fontSize:13, marginTop:7 }}>
              <span style={{ borderBottom:'dotted 2px #999' }}>Entrer un code</span>
            </div>
          </div>
        </div> */}

        { this.renderTransactions() }

      </>
    )

  }

}

const App = ( props ) => <Deals {...props} />;

export default App;
